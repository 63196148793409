import { RecentlyViewedActionTypes, RecentlyViewedState } from '@activebrands/core-web/state/recentlyViewed/types';

const initialState: RecentlyViewedState = {
    items: [],
};

export default (state = initialState, action: RecentlyViewedActionTypes) => {
    if (action.type === 'ADD_RECENTLY_VIEWED_PRODUCT') {
        return {
            ...state,
            items: action.payload.items,
        };
    }

    return state;
};
