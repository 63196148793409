import { useEffect } from 'react';
import { clearSelectedSiteCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { useLocation } from '@reach/router';

const CLEAR_LOCATION_PARAM = process.env.REACT_APP_CLEAR_LOCATION_PARAM;

const ClearSelectedSiteHandler = () => {
    const location = useLocation();

    // Clear selected site cookie if url contains 'clearLocation'
    useEffect(() => {
        const queryParams = extractQueryParams(location.search);

        if (CLEAR_LOCATION_PARAM in queryParams) {
            clearSelectedSiteCookie();
        }
    }, [location?.search]);

    return null;
};

export default ClearSelectedSiteHandler;
