import { graphql, useStaticQuery } from 'gatsby';

const useFeatureFlagQuery = () => {
    const { application } = useStaticQuery(graphql`
        {
            application {
                data {
                    config {
                        featureFlagConfig {
                            fgtm
                            fdepict
                            fformloader
                        }
                    }
                }
            }
        }
    `);

    const { config } = application.data;
    
    return config.featureFlagConfig || {};
};

export default useFeatureFlagQuery;
