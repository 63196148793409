import { AuthActionTypes, AuthState, User } from '@activebrands/core-web/types/auth';

const initialState: AuthState = {
    error: undefined,
    isAuthenticated: undefined,
    isLoading: false,
    user: undefined,
};

const initialUser: User = {
    address: '',
    address2: '',
    birthDate: undefined,
    bonusBasedLevel: '',
    bonusBasedLevelLeftForUpgrade: 0,
    bonusChecks: [],
    bonusPoints: 0,
    city: '',
    country: '',
    createdAt: '',
    email: '',
    firstName: '',
    gender: '',
    id: '',
    lastName: '',
    memberLevelsBonusPoints: 0,
    orders: { count: 0, offset: 0, items: [], totalCount: 0 },
    phoneNumber: '',
    preferences: { acceptsEmail: false, acceptsPostal: false, acceptsSms: false },
    state: '',
    updatedAt: '',
    voyadoID: '',
    zipCode: '',
};

export default (state = initialState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case 'AUTH_AUTHENTICATE':
        case 'AUTH_GET_ORDERS':
        case 'AUTH_RESET_PASSWORD':
        case 'AUTH_CHANGE_PASSWORD':
        case 'AUTH_SIGN_IN':
        case 'AUTH_SIGN_IN_WITH_PROVIDER':
        case 'AUTH_SIGN_OUT':
        case 'AUTH_SIGN_UP':
        case 'AUTH_UPDATE_USER':
        case 'AUTH_CHECK_EMAIL':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'AUTH_AUTHENTICATE_ERROR':
        case 'AUTH_RESET_PASSWORD_ERROR':
        case 'AUTH_CHANGE_PASSWORD_ERROR':
        case 'AUTH_SIGN_IN_ERROR':
        case 'AUTH_SIGN_OUT_ERROR':
        case 'AUTH_SIGN_UP_ERROR':
        case 'AUTH_UPDATE_USER_ERROR':
        case 'AUTH_SEND_RESET_PASSWORD_LINK_ERROR':
        case 'AUTH_SIGN_IN_WITH_PROVIDER_ERROR':
        case 'AUTH_CHECK_EMAIL_ERROR':
            return {
                ...state,
                isAuthenticated: !!state.user,
                error: action.payload.error,
                isLoading: false,
            };
        case 'AUTH_AUTHENTICATE_SUCCESS':
        case 'AUTH_SIGN_IN_SUCCESS':
        case 'AUTH_SIGN_IN_WITH_PROVIDER_SUCCESS':
            return {
                ...state,
                error: undefined,
                isAuthenticated: true,
                isLoading: false,
                user: {
                    ...initialUser,
                    ...action.payload.user,
                    ...state,
                },
            };
        case 'AUTH_AUTHENTICATE_AWAIT_CONSENT':
            return {
                ...state,
                error: undefined,
                isAuthenticated: false,
                isLoading: false,
                user: {
                    ...initialUser,
                    ...action.payload.user,
                    ...state,
                },
            };
        case 'AUTH_RESET_PASSWORD_SUCCESS':
        case 'AUTH_CHANGE_PASSWORD_SUCCESS':
        case 'AUTH_SIGN_OUT_SUCCESS':
        case 'AUTH_SIGN_UP_SUCCESS':
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                user: undefined,
            };
        case 'AUTH_VALIDATE_TEAM_STORE_ACCOUNT_SUCCESS':
        case 'AUTH_UPDATE_USER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: {
                    ...initialUser,
                    ...state.user!,
                    ...action.payload,
                },
            };
        case 'AUTH_VALIDATE_TEAM_STORE_ACCOUNT_ERROR':
            return {
                ...state,
                teamValidationError: action.payload.error,
            };
        case 'AUTH_CHECK_EMAIL_SUCCESS':
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};
