import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '@activebrands/core-web/libs/grebcommerce/fetch';
import { resetCampaignSite, setCampaignSite } from '@activebrands/core-web/state/campaignSite/actions';
import { resolveUser } from '@activebrands/core-web/state/user/actions';
import { addLocalePrefix } from '@activebrands/core-web/utils/add-locale-prefix';
import getCookiePath from '@activebrands/core-web/utils/get-cookie-path';
import { resolveCampaignSite } from '@activebrands/core-web/utils/grebcommerce';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import Cookies from 'js-cookie';

const CAMPAIGN_MARKET_COOKIE = process.env.REACT_APP_CAMPAIGN_MARKET_COOKIE;

const cache = {};

const CampaignSiteHandler = () => {
    const dispatch = useDispatch();
    const path = getCookiePath();
    const { marketId, pricelistId } = useSelector(state => state.application.shopConfig);

    const resetCampagin = () => {
        Cookies.remove(CAMPAIGN_MARKET_COOKIE, { path });
        dispatch(resetCampaignSite(marketId));
    };

    const handleCampaign = async (priceUrl, campaignName, campaignMarketId) => {
        try {
            if (!cache[priceUrl]) {
                cache[priceUrl] = await get(priceUrl, {}, window.location.origin);
            }

            // Make sure that there is a pricelist for the campaign
            if (
                (cache[priceUrl].status === 200 || cache[priceUrl].status === 201) &&
                cache[priceUrl].data &&
                Object.keys(cache[priceUrl].data).length > 0
            ) {
                // Set cookie with campaign market id
                Cookies.set(CAMPAIGN_MARKET_COOKIE, `${campaignName}|${campaignMarketId}`, {
                    expires: 28,
                    path,
                });

                dispatch(
                    setCampaignSite({
                        campaignMarketId,
                        campaignName,
                        originalMarketId: marketId,
                        pricelist: cache[priceUrl].data,
                    })
                );
            } else {
                resetCampagin();
            }
        } catch (error) {
            console.error(error);
            resetCampagin();
        }
    };

    useEffect(async () => {
        // Get campaign market id from query params
        const queryparams = extractQueryParams(window.location.search);
        const campaignNameParam = queryparams['campaign-site'] ? decodeURI(queryparams['campaign-site']) : null;

        // Get campaign market id from previously saved cookie
        const campaignSiteCookie = Cookies.get(CAMPAIGN_MARKET_COOKIE);
        const campaignNameCookie = campaignSiteCookie?.split('|')[0];

        // Use one of the campaign market ids
        const campaignName = campaignNameParam || campaignNameCookie;

        if (campaignName) {
            if (!cache[campaignName]) {
                cache[campaignName] = await resolveCampaignSite(campaignName);
            }

            // Status 200/201 means that the campaign exists and is active
            if (cache[campaignName]?.status === 200 || cache[campaignName]?.status === 201) {
                const campaignMarketId = cache[campaignName].data.market_id;

                // Check if campaignMarketId differs from current marketId
                if (campaignMarketId !== marketId && pricelistId) {
                    const priceUrl = addLocalePrefix(`/gc-json/price_${campaignMarketId}_${pricelistId}.json`, false);

                    handleCampaign(priceUrl, campaignName, campaignMarketId);
                }

                // If status isn't 200, and campaignName === campaignNameCookie
                // it means that a previous active campaign is now inactive
            } else if (campaignName === campaignNameCookie) {
                resetCampagin();
            }

            // Update user and ecommerce with campaign data
            dispatch(resolveUser(campaignName));
        }
    });

    return null;
};

export default CampaignSiteHandler;
