import { EcommerceActionTypes, EcommerceState } from '@activebrands/core-web/state/ecommerce/types';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

const initialState: EcommerceState = {
    countries: {},
    customer: [],
    market: {
        selected: '',
        available: {},
    },
    pricelist: {
        selected: '',
        available: {},
    },
    shipping: {
        selected: '',
        available: [],
        availableInMarket: [],
    },
};

export default (state = initialState, action: EcommerceActionTypes) => {
    switch (action.type) {
        case 'RESOLVE_ECOMMERCE_SUCCESS':
            const { countries, pricelist, market, ...payload } = action.payload;

            const transformedCountries = Object.keys(countries).reduce((newCountries, currentCountry) => {
                newCountries[currentCountry] = objectKeysToCamelCase(countries[currentCountry], {
                    modifyInputObject: false,
                });
                newCountries[currentCountry].states = countries[currentCountry].states;
                return newCountries;
            }, {});

            const transformedMarket = {
                selected: market.selected,
                available: Object.keys(market.available).reduce((available, currentKey) => {
                    available[currentKey] = objectKeysToCamelCase(market.available[currentKey] || {}, {
                        modifyInputObject: false,
                    });
                    return available;
                }, {}),
            };

            const transformedPricelist = {
                selected: pricelist.selected,
                available: Object.keys(pricelist.available).reduce((available, currentKey) => {
                    available[currentKey] = objectKeysToCamelCase(pricelist.available[currentKey] || {}, {
                        modifyInputObject: false,
                    });
                    return available;
                }, {}),
            };

            return {
                ...initialState,
                ...objectKeysToCamelCase(payload, { modifyInputObject: false }),

                countries: transformedCountries,
                market: transformedMarket,
                pricelist: transformedPricelist,
            };
        case 'RESOLVE_ECOMMERCE_ERROR':
            return { ...initialState };
        default:
            return state;
    }
};
