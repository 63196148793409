import { DependencyList, useEffect, useRef } from 'react';
import useEventListener, { Options } from '@activebrands/core-web/hooks/useEventListener';
import { useDidUpdate, useWillUnmount } from './lifecycle';

interface ScrollData {
    direction: {
        x: 'LEFT' | 'RIGHT' | null;
        y: 'UP' | 'DOWN' | null;
    };
    position: {
        x: number;
        y: number;
    };
}

interface Subscriber {
    callback: (data: ScrollData) => void;
    shouldTrigger: (next: ScrollData, prev: ScrollData) => boolean;
    data: ScrollData;
    handler: number | undefined;
}

/**
 * @typedef {Object} Options
 * @property {boolean} [subscribe=true] - If true, subscribe to event.
 * @property {boolean} [passive=true] - If true, indicates that listener will never call preventDefault().
 * @property {boolean} [once=false] - If true, the listener would be automatically removed when invoked.
 */

/**
 * @param {func} callback - Callback function.
 * @param {func} [shouldTrigger] - Function that returns if callback should be triggered or not.
 * @param {[]} [deps=[]] - If present, callback and shouldTrigger will reevaluate if the values in the list change.
 * @param {Options} [options] - Hook and event listner options.
 */

const useScrollPosition = (
    callback: Subscriber['callback'],
    shouldTrigger: Subscriber['shouldTrigger'] = () => true,
    deps: DependencyList = [],
    options: Partial<Options> = {}
) => {
    options = {
        target: 'window',
        ...options,
    };

    const subscriberRef = useRef<Subscriber>({
        callback,
        handler: undefined,
        data: {
            direction: {
                x: null,
                y: null,
            },
            position: {
                x: 0,
                y: 0,
            },
        },
        shouldTrigger,
    });

    const listener = useEventListener(options.target || 'window');

    useEffect(() => {
        if (options.subscribe !== false) {
            const subscriber = subscriberRef.current;

            subscriberRef.current.handler = listener.subscribe(
                'scroll',
                () => {
                    const target: any = listener.target || {};
                    const subscriber = subscriberRef.current;
                    const scrollX = target.scrollX || target.pageXOffset;
                    const scrollY =
                        options.target === 'window' ? target.scrollY || target.pageYOffset : target.scrollTop;

                    const data: ScrollData = {
                        direction: {
                            x:
                                subscriber.data.position.x > scrollX
                                    ? 'LEFT'
                                    : subscriber.data.position.x < scrollX
                                    ? 'RIGHT'
                                    : null,
                            y:
                                subscriber.data.position.y < scrollY
                                    ? 'DOWN'
                                    : subscriber.data.position.y > scrollY
                                    ? 'UP'
                                    : null,
                        },
                        position: {
                            x: scrollX,
                            y: scrollY,
                        },
                    };

                    if (subscriber.data.position.x < 0 || subscriber.data.position.y < 0) {
                        data.direction = subscriber.data.direction;
                    }

                    if (subscriber.shouldTrigger(data, subscriber.data)) {
                        subscriber.callback(data);
                    }

                    subscriberRef.current.data = data;
                },
                { passive: true, ...options }
            );

            if (subscriber.shouldTrigger(subscriber.data, subscriber.data)) {
                subscriber.callback(subscriber.data);
            }
        }

        return () => {
            if (subscriberRef.current.handler) {
                listener.unsubscribe('scroll', subscriberRef.current.handler);
                subscriberRef.current.handler = undefined;
            }
        };
    }, [options.subscribe]);

    useDidUpdate(() => {
        subscriberRef.current.callback = callback;
        subscriberRef.current.shouldTrigger = shouldTrigger;
        const subscriber = subscriberRef.current;
        if (subscriber.shouldTrigger(subscriber.data, subscriber.data)) {
            subscriber.callback(subscriber.data);
        }
    }, [...deps]);

    useWillUnmount(() => {
        if (subscriberRef.current.handler) {
            listener.unsubscribe('scroll', subscriberRef.current.handler);
            subscriberRef.current.handler = undefined;
        }
    });

    return {
        unsubscribe: () => listener.unsubscribe('scroll', subscriberRef.current.handler!),
    };
};

export default useScrollPosition;
