import { ImageBackground, RawImageData } from '@activebrands/core-web/libs/storyblok/storyblok';

// /**
//  * Accepts raw image data from Storyblok.
//  */

export default (rawImage: RawImageData): ImageBackground => ({
    alt: rawImage?.image?.alt,
    hostedBy: 'storyblok',
    position: rawImage?.position,
    size: rawImage?.size,
    src: rawImage?.image?.filename,
    title: rawImage?.image?.title,
    type: 'image',
});
