import { colorsCssVariablesCore } from '@activebrands/core-web/config/branding/colors';
import { siteCssVariables } from '@activebrands/core-web/config/branding/site';
import { breakpoints } from '@activebrands/core-web/config/breakpoints';
import { colorsCssVariables } from '../colors';
import { gridCssResponsiveVariables } from '../grid';
import { opacityCssVariables } from '../opacity';
import { ratiosCssResponsiveVariables } from '../ratios';
import { transitionsCssResponsiveVariables } from '../transitions';
import { zIndexCssResponsiveVariables } from '../z-index';
import { Theme } from './index';

/**
 * When creating variables, use the color palette in color.ts when defining the values.
 * For example, '--color-text' uses 'var(--color-palette-off-black)' from the color palette.
 *
 * We don't want to use other variables as variable values as this defeats the purpose of individual variables.
 * For example, '--link-color' should not use '--color-accent-secondary', even if they have the same value,
 * as they could be different in another theme.
 *
 * Knowing when to use an already existing variable or creating a new one can be difficult.
 * A rule of thumb is to think about the colors as they could be used in different themes.
 * Maybe the link color and the accent color are both blue in the default theme, but it's possible they could be different
 * in different themes. In that case they should probably have their own variable so they can easily be modified
 * in different themes.
 *
 * Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

const theme: Theme = {
    statics: {
        // Core colors
        ...colorsCssVariablesCore,

        // Default Configs
        ...colorsCssVariables,
        ...opacityCssVariables,
        ...siteCssVariables,

        // TODO: Rearrange and rename core section
        // ----------------------------//
        // Core
        // ---------------------------//

        // Gradient
        '--color-bg-gradient': 'var(--color-palette-brand-white)',

        // Backdrop
        '--color-bg-backdrop': 'var(--color-palette-brand-brown-25)',
        '--top-content-backdrop': 'var(--dynamic-main-content-top)',
        '--transition-duration-content-backdrop': 'var(--duration-fast)',
        '--transition-timing-function-content-backdrop': 'var(--ease)',
        '--zindex-content-overlay-backdrop': 'var(--zindex-overlay)',

        // Breadcrumbs
        '--color-text-breadcrumbs-color-default': 'var(--color-palette-brown)',
        '--color-text-breadcrumbs-color-active': 'var(--color-palette-off-black)',
        '--padding-content-breadcrumbs': 'var(--margin-content)',

        // Price
        '--color-price-active-original': 'var(--color-palette-off-black)',
        '--color-text-price-original-active': 'var(--color-base-error)',
        '--color-text-price-sale-active': 'var(--color-palette-brown)',

        // Tooltip
        '--tooltip-content-background-color': 'var(--color-bg-secondary)',
        '--tooltip-content-border-color': 'var(--color-contrast-medium)',

        // ----------------------------//
        // Text
        // ---------------------------//

        '--color-text-accent': 'var(--color-base-error)',
        '--color-text-disabled': 'var(--color-palette-off-black)',
        '--color-text-error': 'var(--color-base-error)',
        '--color-text-inverted': 'var(--color-palette-off-white)',
        '--color-text-subtle': 'var(--color-palette-brown)',
        '--color-text': 'var(--color-palette-off-black)',
        '--color-text-pagination': 'var(--color-palette-off-black)',
        '--color-text-pagination-subtle': 'var(--color-palette-brown)',

        // ----------------------------//
        // Background
        // ----------------------------//

        '--color-bg-accent': 'var(--color-base-error)',
        '--color-bg-error': 'var(--color-base-error)',
        '--color-bg-highlight': 'var(--color-palette-white)',
        '--color-bg-inverted': 'var(--color-palette-off-black)',
        '--color-bg-secondary': 'var(--color-palette-neutral-200)',
        '--color-bg': 'var(--color-palette-off-white)',
        '--color-bg-contrast': 'var(--color-palette-neutral-200)',
        '--color-bg-active-navigation': 'var(--color-palette-neutral-400)',
        '--color-bg-list-bullet': 'var(--color-palette-off-black)',

        // ----------------------------//
        // Form Information
        // ----------------------------//

        '--color-bg-form-information': 'var(--color-palette-off-white)',
        '--color-border-form-information-error': 'var(--color-base-error)',
        '--color-border-form-information-success': 'var(--color-palette-brown)',
        '--color-icon-form-information-error': 'var(--color-base-error)',
        '--color-icon-form-information-success': 'var(--color-base-success)',
        '--color-text-form-information': '--color-palette-off-black',

        // ----------------------------//
        // Border
        // ----------------------------//

        '--color-border': 'var(--color-palette-off-black)',
        '--color-border-subtle': 'var(--color-palette-neutral-300)',
        '--color-border-inverted': 'var(--color-palette-off-white)',
        '--color-border-accent': 'var(--color-base-error)',
        '--color-border-error': 'var(--color-base-error)',
        '--color-border-site-selector': 'var(--color-palette-brown)',

        // ----------------------------//
        // Contrast
        // ----------------------------//

        '--color-contrast-lowest': 'var(--color-palette-neutral-200)',
        '--color-contrast-lower': 'var(--color-palette-neutral-300)',
        '--color-contrast-low': 'var(--color-palette-neutral-400)',
        '--color-contrast-medium': 'var(--color-palette-neutral-500)',
        '--color-contrast-high': 'var(--color-palette-neutral-600)',
        '--color-contrast-higher': 'var(--color-palette-neutral-600)',
        '--color-contrast-dark': 'var(--color-palette-black)',

        // ----------------------------//
        // Links
        // ----------------------------//

        '--link-color': 'var(--color-palette-brown)',

        '--color-link-rich-text': 'var(--color-palette-neutral-600)',
        '--color-link-rich-text-hover': 'var(--color-palette-off-black)',

        // ----------------------------//
        // Shadow
        // ----------------------------//

        '--color-shadow': 'var(--color-palette-off-black)',
        '--color-shadow-inverted': 'var(--color-palette-off-white)',
        '--shadow-drop': '',

        // ----------------------------//
        // Buttons
        // ----------------------------//

        /** Default */
        '--color-bg-button-default': 'var(--color-palette-neutral-400)',
        '--color-text-button-default': 'var(--color-palette-off-black)',

        // Default (Hover)
        '--color-bg-button-default-hover': 'var(--color-palette-neutral-300)',
        '--color-text-button-default-hover': 'var(--color-palette-off-black)',

        // Default (Disabled)
        '--color-text-button-default-disabled': 'var(--color-palette-brown)',

        // Default (Active)
        '--color-bg-button-default-active': 'var(--color-palette-off-black)',
        '--color-text-button-default-active': 'var(--color-palette-off-white)',
        '--color-menu-button-default-active': 'var(--color-palette-neutral-400)',

        /** Inverted */
        '--color-bg-button-inverted': 'var(--color-palette-off-black)',
        '--color-text-button-inverted': 'var(--color-palette-off-white)',

        // Inverted(Hover)
        '--color-bg-button-inverted-hover': 'var(--color-palette-brown)',
        '--color-text-button-inverted-hover': 'var(--color-palette-off-white)',

        // Inverted (Disabled)
        '--color-text-button-inverted-disabled': 'var(--color-palette-off-white)',
        '--color-bg-button-inverted-disabled': 'var(--color-palette-brown)',

        // Inverted (Active)
        '--color-bg-button-inverted-active': 'var(--color-palette-neutral-400)',
        '--color-text-button-inverted-active': 'var(--color-palette-off-black)',

        /** Link */
        '--color-bg-button-link': 'transparent',
        '--color-text-button-link': 'var(--color-palette-off-black)',

        // Link (Hover)
        '--color-bg-button-link-hover': 'var(--color-palette-neutral-300)',
        '--color-text-button-link-hover': 'var(--color-palette-off-black)',

        // Link (Active)
        '--color-bg-button-link-active': 'var(--color-palette-neutral-400)',
        '--color-text-button-link-active': 'var(--color-palette-off-black)',

        // Link (Disabled)
        '--color-bg-button-link-disabled': 'var(--color-palette-brown)',
        '--color-text-button-link-disabled': 'var(--color-palette-off-white)',

        // ACT colors
        '--color-text-voucher-subtle': 'var(--color-palette-default-grey)',

        // Checkbox input
        '--color-label-checkbox-loading': 'var(--color-palette-default-grey)',
        '--color-label-checkbox-disabled': 'var(--color-palette-default-grey)',

        // ----------------------------//
        // Footer
        // ----------------------------//

        '--color-bg-footer-primary': 'var(--color-palette-neutral-200)',
        '--color-bg-footer-secondary': 'var(--color-palette-neutral-300)',
        '--color-bg-footer-button': 'var(--color-palette-neutral-200)',
        '--color-bg-footer-accordion': 'var(--color-palette-neutral-400)',

        // ----------------------------//
        // Header
        // ----------------------------//

        '--color-bg-header': 'var(--color-palette-neutral-200)',
        '--color-bg-search-header': 'var(--color-palette-off-white)',
        '--color-border-search-header': 'var(--color-palette-neutral-400)',
        '--color-text-header': 'var(--color-palette-off-black)',
        '--color-text-search-header': 'var(--color-palette-off-black)',
        '--color-top-logo-mobile': 'var(--color-palette-off-black)',

        // ----------------------------//
        // Header Overlays
        // ----------------------------//

        '--color-bg-header-overlay': 'var(--color-palette-neutral-200)',

        // ----------------------------//
        // Banner
        // ----------------------------//

        // Beige
        '--color-bg-banner-beige': 'var(--color-palette-neutral-300)',
        '--color-text-banner-beige': 'var(--color-palette-off-black)',

        // Black
        '--color-bg-banner-black': 'var(--color-palette-off-black)',
        '--color-text-banner-black': 'var(--color-palette-off-white)',

        // ----------------------------//
        // Navigation
        // ----------------------------//
        '--color-bg-navigation': 'var(--color-palette-off-white)',
        '--color-text-navigation': 'var(--color-palette-off-black)',

        // ----------------------------//
        // Badges
        // ----------------------------//

        // ----------------------------//
        // Swatches
        // ----------------------------//

        '--color-swatches-border-color-highlight': 'var(--color-palette-off-black)',
        '--color-swatches-border-color-highlight-inner': 'var(--color-palette-off-white)',
        '--color-swatches-border-color': 'var(--color-palette-neutral-500)',

        // ----------------------------//
        // Modules
        // ----------------------------//

        '--border-radius-module': '0px',

        // Default
        '--color-bg-badge': 'transparent',
        '--color-text-badge': 'var(--color-palette-off-black)',

        // Brand primary
        '--color-bg-badge-brand-primary': 'var(--color-palette-neutral-300)',
        '--color-text-badge-brand-primary': 'var(--color-palette-off-black)',

        // Accent
        '--color-bg-badge-accent': 'transparent',
        '--color-text-badge-accent': 'var(--color-base-error)',

        // ----------------------------//
        // Tags
        // ----------------------------//

        '--color-bg-article-tag': 'var(--color-palette-primary-400)',
        '--color-text-article-tag': 'var(--color-palette-off-black)',
        '--color-bg-order-tag': 'var(--color-palette-brown)',

        // ----------------------------//
        // Preview
        // ----------------------------//

        '--color-bg-preview': 'var(--color-palette-neutral-500)',
        '--color-bg-preview-button': 'var(--color-palette-neutral-500)',

        // ----------------------------//
        // Images
        // ----------------------------//

        '--color-bg-image': 'var(--color-palette-light-grey)',

        // ----------------------------//
        // PLP
        // ----------------------------//
        '--color-text-category-button': 'var(--color-palette-neutral-600)',
        '--color-bg-category-button': 'var(--color-palette-neutral-300)',
        '--color-text-category-button-active': 'var(--color-palette-off-white)',
        '--color-bg-category-button-active': 'var(--color-palette-neutral-600)',
        '--color-text-category-button-hover': 'var(--color-palette-off-white)',
        '--color-bg-category-button-hover': 'var(--color-palette-neutral-400)',
        '--color-border-button': 'var(--color-palette-off-black)',

        // Variant Selector
        '--color-text-variant-label': 'var(--color-palette-brown)',
        '--color-border-variant-image-active': 'var(--color-palette-off-black)',
        '--color-border-variant-image-hover': 'var(--color-palette-brown)',
        '--color-bg-variant-sale-badge': 'var(--color-base-error)',
        '--color-text-variant-sale-badge': 'var(--color-palette-white)',

        // Sticky filter bar
        '--color-bg-sticky-filter-bar': 'var(--color-palette-off-black)',
        '--color-text-sticky-filter-bar': 'var(--color-palette-off-white)',

        // ----------------------------//
        // PDP
        // ----------------------------//

        '--color-bg-gallery-button': 'var(--color-palette-neutral-400)',
        '--color-bg-gallery-placeholder': 'var(--color-palette-neutral-200)',
        '--color-bg-model-size': 'var(--color-palette-off-white)',
        '--color-bg-pdp-flipclock': 'var(--color-palette-white)',
        '--color-bg-pdp-important-content': 'var(--color-palette-neutral-200)',
        '--color-text-pdp-flipclock': 'var(--color-palette-off-black)',
        '--color-text-pdp-info': 'var(--color-palette-brown)',

        // ----------------------------//
        // Checkout
        // ----------------------------//

        '--color-bg-checkout-info-boxes': 'var(--color-palette-white)',
        '--color-bg-checkout-act-content': 'var(--color-palette-white)',
        '--color-bg-checkout-act-information': 'var(--color-palette-white)',
        '--color-bg-checkout-act-vouchers-active': 'var(--color-palette-act-blue)',
        '--color-bg-checkout-act-vouchers': 'var(--color-palette-white)',
        '--color-bg-checkout-summary-products': 'var(--color-palette-neutral-200)',
        '--color-bg-checkout-summary-total': 'var(--color-palette-neutral-200)',
        '--color-bg-checkout-summary-vouchers-autofill': 'var(--color-palette-neutral-200)',
        '--color-bg-checkout': 'var(--color-palette-off-white)',
        '--color-border-checkout-act-content': 'var(--color-palette-neutral-300)',
        '--color-border-checkout-act-vouchers-active': 'var(--color-palette-act-blue)',
        '--color-border-checkout-act-vouchers': 'var(--color-palette-neutral-300)',
        '--color-border-checkout-info-boxes': 'var(--color-palette-off-black)',
        '--color-border-checkout-subtle': 'var(--color-palette-neutral-300)',
        '--color-icon-checkout-act-content': 'var(--color-palette-act-blue)',
        '--color-text-checkout-act-information': 'var(--color-palette-off-white)',
        '--color-text-checkout-act-vouchers-active': 'var(--color-palette-off-white)',
        '--color-text-checkout-act-vouchers': 'var(--color-palette-off-black)',
        '--color-text-checkout-error': 'var(--color-base-error)',
        '--color-text-checkout-steps-active': 'var(--color-palette-off-black)',
        '--color-text-checkout-steps': 'var(--color-palette-brown)',
        '--color-text-checkout-subtle': 'var(--color-palette-brown)',
    },
    responsives: {
        'mobile.sm': {
            '--height-overlay': 'calc(100dvh - var(--height-header) - 8px)', // -8px is for the gap between header and overlay
            '--margin-bottom-archive-hero': '64px',
            '--margin-bottom-default-hero': '40px',
            '--margin-bottom-module': '64px',
            '--margin-content': '8px',
            '--padding-content-shop-the-look': '0px',
            '--padding-image-pdp': '5%',
            '--padding-image-productCard': '10%',
            '--width-overlays': 'calc(100vw - 8px)', // -8px is 4px x 2 gap between browser edge and overlay
            '--width-basket-overlay': 'calc(100vw - 8px)', // -8px is 4px x 2 gap between browser edge and overlay
        },
        'mobile.md': {},
        'mobile.lg': {
            '--width-basket-overlay': '500px',
            '--width-overlays': '400px',
        },
        'tablet.sm': {},
        'tablet.lg': {},
        'desktop.sm': {
            '--checkout-corridor-max-width': '1440px',
            '--margin-bottom-archive-hero': '80px',
            '--margin-bottom-default-hero': '64px',
            '--margin-bottom-module': '80px',
            '--margin-content': '16px',
            '--padding-image-productCard': '5%',
        },
        'desktop.md': {
            '--padding-image-pdp': '10%',
        },
        'desktop.lg': {
            '--site-corridor-max-width': '1920px',
        },
    },
};

breakpoints.forEach(breakpoint => {
    theme.responsives[breakpoint.label] = {
        ...ratiosCssResponsiveVariables[breakpoint.label],
        ...gridCssResponsiveVariables[breakpoint.label],
        ...transitionsCssResponsiveVariables[breakpoint.label],
        ...zIndexCssResponsiveVariables[breakpoint.label],

        // Always write the theme variables last
        ...theme.responsives[breakpoint.label],
    };
});

export default theme;
