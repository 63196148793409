import {
    RawExternalSiteSelectorLinkData,
    RawSiteSelectorLinkData,
    SiteSelectorLinkData,
} from '@activebrands/core-web/libs/storyblok/storyblok';

type LinkType = 'internal' | 'external';

/**
 * Object of raw site selector link data from Storyblok
 */

export const transformLink = (
    link: RawSiteSelectorLinkData | RawExternalSiteSelectorLinkData,
    linkType: LinkType
): SiteSelectorLinkData => {
    const countryCode = link.countryCode?.toUpperCase();
    let id = '';
    const icon = link.countryCode ? `https://flagcdn.com/h20/${link.countryCode.toLowerCase()}.png` : undefined;
    const label = link.label;
    let path = '';

    if (linkType === 'internal') {
        const linkData = link as RawSiteSelectorLinkData;

        id = linkData.site?.data?.id;
        path = linkData.site?.data?.path;
    }

    if (linkType === 'external') {
        const linkData = link as RawExternalSiteSelectorLinkData;

        id = link.label;
        path = linkData.link?.url;
    }

    return {
        countryCode,
        icon,
        label,
        id,
        path,
    };
};

/**
 * Array of raw site selector links data from Storyblok
 */

export const transformSiteSelectorLinks = (
    links: Array<RawSiteSelectorLinkData | RawExternalSiteSelectorLinkData>,
    linkType: LinkType
): Array<SiteSelectorLinkData> => links?.map(link => transformLink(link, linkType));
