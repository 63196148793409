import Gtm from '@grebban/gtm';

export default ({ paymentInfo, items }) => {
    const valueWithoutTax = items.reduce((prev, item) => {
        return prev + (item.priceEachWithoutTaxAsNumber * item.quantity);
    }, 0);

    Gtm.addDataLayerEvent('add_payment_info', {
        currency: paymentInfo?.currency,
        ecommerce: {
            value: valueWithoutTax,
            payment_type: paymentInfo?.paymentType,
            items: items
        }
    });
}