import Gtm from '@grebban/gtm';

export default ({ currencyCode, locale, pageTitle, userId }: {
    currencyCode: string;
    locale: string;
    pageTitle: string;
    userId: string;
}) => {
    Gtm.addDataLayerEvent('page_view', {
        currencyCode,
        locale,
        page_title: pageTitle || '404',
        user_id: userId,
    });
};
