import { PageActionTypes, PageState } from '@activebrands/core-web/state/page/types';

const initialState: PageState = {
    name: '',
};

export default (state = initialState, action: PageActionTypes) => {
    switch (action.type) {
        case 'SET_PAGE_SUCCESS':
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};
