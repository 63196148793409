const FEATURE_FLAGS_LOCAL_STORAGE = process.env.REACT_APP_FEATURE_FLAGS_LOCAL_STORAGE as string;

const getFlagsFromLocalStorage = (): { [key: string]: boolean } => {
    let stored = {};
    try {
        const data = localStorage.getItem(FEATURE_FLAGS_LOCAL_STORAGE);
        stored = data ? JSON.parse(data) : {};
    } catch (e) {
        stored = {};
    }

    return stored;
};

const getFlagState = (envVar: string) => {
    // check env settings
    if (process.env.REACT_APP_FEATURE_FLAGS?.includes(envVar)) {
        return false;
    }
    // check localstorage
    return getFlagsFromLocalStorage()[envVar] ? false : true;
};

export { getFlagState, getFlagsFromLocalStorage };
