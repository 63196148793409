interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

interface Logger {
    log: LogFn;
    warn: LogFn;
    error: LogFn;
}

class ConsoleLogger implements Logger {
    readonly log: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;

    constructor() {
        this.log = console.log.bind(console);
        this.warn = console.warn.bind(console);
        this.error = console.error.bind(console);
    }
}

export const logger = new ConsoleLogger();
