interface Category {
    name: string;
    categories?: Category[];
}

const getRecursiveCategories = (category: Category, currentCategory = '') => {
    currentCategory += `${category?.name}/`;
    if (category?.categories?.length) {
        currentCategory = getRecursiveCategories(category.categories[0], currentCategory);
    }
    return currentCategory;
};

export default getRecursiveCategories;
