import DataLayer from './DataLayer';
import Gtm from '@grebban/gtm';

export default ({ currencyCode, products }) => {
    Gtm.addDataLayerEvent('checkout', {
        ecommerce: {
            currencyCode,
            checkout: {
                products: DataLayer.getProducts(products),
            },
        },
    });
};
