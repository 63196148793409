import { CampaignSiteActionTypes, CampaignSiteState } from '@activebrands/core-web/state/campaignSite/types';

const initialState: CampaignSiteState = {
    campaignMarketId: undefined,
    campaignName: undefined,
    campaignPricelist: undefined,
    originalMarketId: undefined,
};

export default (state = initialState, action: CampaignSiteActionTypes) => {
    switch (action.type) {
        case 'SET_CAMPAIGN_SITE':
            return {
                ...action.payload,
            };
        case 'RESET_CAMPAIGN_SITE':
            return {
                ...state,
            };
        case 'SET_CAMPAIGN_SITE_ERROR':
            return initialState;
        default:
            return state;
    }
};
