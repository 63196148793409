import { inServer } from '@activebrands/core-web/utils/constants';

const connection =
    !inServer &&
    ((navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection);

/**
 * Calculate the total page load time
 *
 * Total amount of time it took to load the page.
 */
export const getPageLoadTime = () => {
    if (inServer) {
        return 0;
    }

    const { loadEventEnd, navigationStart } = performance.timing || {};

    return Math.max(loadEventEnd - navigationStart, 0);
};

/**
 * Calculate request response time
 *
 * Calculate the time elapsed between the beginning of a request and the completion of getting the response.
 */
export const getRequestResponseTime = () => {
    if (inServer) {
        return 0;
    }

    const { responseEnd, requestStart } = performance.timing || {};

    return Math.max(responseEnd - requestStart, 0);
};

/**
 * Calculate page render time
 *
 * Get the amount of time it took to render the page.
 */
export const getPageRenderTime = () => {
    if (inServer) {
        return 0;
    }

    const { domComplete, domLoading } = performance.timing || {};

    return Math.max(domComplete - domLoading, 0);
};

/**
 * Get a timeout used to delay functions on slow connections.
 */
export const getConnectionTimeout = () => {
    if (connection?.effectiveType === '3g') {
        return 20000;
    }

    const pageLoad = getPageLoadTime();

    // If it takes more then 5s to load page, its probably a slow connection so delay calculation even more.
    return pageLoad > 5000 ? 20000 : pageLoad;
};
