import { ProductPrice } from '@activebrands/core-web/types/product';

const parseProductPrice = (pricelist: Record<string, any>): ProductPrice => {
    // @todo: Get currency string by pricelist.currency somehow. pricelist.currency is a id from centra.
    const currencyString = pricelist.priceWithCurrency.replace(/[^A-Z]+/g, '');

    return {
        currency: currencyString,
        onSale: !!pricelist.onSale,
        price: pricelist.price,
        priceExVat: pricelist.priceExVat,
        priceHistory: pricelist.priceHistory?.price,
        priceHistoryWithCurrency: pricelist.priceHistory?.priceWithCurrency,
        priceWithCurrency: pricelist.priceWithCurrency,
        salePrice: pricelist.salePrice,
        salePriceExVat: pricelist.salePriceExVat,
        salePriceWithCurrency: pricelist.salePriceWithCurrency,
    };
};

export default parseProductPrice;
