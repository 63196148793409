import { ProductActionTypes, ProductBaseState } from '@activebrands/core-web/state/product/types';

const initialState: ProductBaseState = {
    categories: [],
    containImage: false,
    customAttributes: undefined,
    id: '',
    media: [],
    name: '',
    price: undefined,
    selectedVariant: undefined,
    sku: '',
    uri: '',
    variations: [],
};

export default (state = initialState, action: ProductActionTypes) => {
    switch (action.type) {
        case 'SET_PRODUCT':
            return {
                ...initialState,
                ...action.payload,
            };
        case 'SET_VARIANT':
            return {
                ...state,
                selectedVariant: action.payload,
            };
        case 'SET_BUNDLE':
            return {
                ...state,
                selectedBundle: action.payload,
                selectedVariant: undefined,
            };
        case 'SET_PRODUCT_PRICE':
            return {
                ...state,
                price: action.payload,
            };
        default:
            return state;
    }
};
