import { breakpointLabels, maxBreakpoints, minBreakpoints } from '@activebrands/core-web/config/breakpoints';

const fallbackLabelObject = {
    'mobile.sm': '',
    'mobile.md': '',
    'mobile.lg': '',
    'tablet.sm': '',
    'tablet.lg': '',
    'desktop.sm': '',
    'desktop.md': '',
    'desktop.lg': '',
};

const min = minBreakpoints.reduce(
    (obj, value, i) => ({
        ...obj,
        [breakpointLabels[i]]: `@media screen and (min-width: ${value}px)`,
    }),
    fallbackLabelObject
);

const max = maxBreakpoints.reduce(
    (obj, value, i) => ({
        ...obj,
        [breakpointLabels[i]]: `@media screen and (max-width: ${value}px)`,
    }),
    fallbackLabelObject
);

const hover = '@media (hover: hover), (-ms-high-contrast: none)';

export default {
    min,
    max,
    hover,
};
