import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import overlay from '@activebrands/core-web/libs/overlay';

const WrongSiteHandler = ({ alternateHrefs = [] }) => {
    // List of all sites
    const { siteSelectorLinks } = useSiteSelectorQuery();

    // The id of the current site that the user is on right now
    let currentSiteId = Number(useSelector(state => state.application.aliasId));
    // Make sure that the current id exists
    currentSiteId = Number(siteSelectorLinks.find(site => site.id == currentSiteId)?.id);

    // The id of the site that is suggested by CloudFront with geolocation
    let userPrimarySiteId = useSelector(state => state.user.primarySite);
    // Make sure that the primary id exists
    userPrimarySiteId = Number(siteSelectorLinks.find(site => site.id == userPrimarySiteId)?.id);
    // The href url of the site that is suggested by CloudFront with geolocation
    const userPrimaryHrefSiteUrl = alternateHrefs.find(href => href.aliasId === userPrimarySiteId)?.uri;

    // The id of the site that the user has manually selected in the site selector
    let userSelectedSiteId = useSelector(state => state.user.selectedSite);
    // Make sure that the selected id exists
    userSelectedSiteId = Number(siteSelectorLinks.find(site => site.id == userSelectedSiteId)?.id);
    // The href url of the site that is the user has manually selected in the site selector
    const userSelectedHrefSiteUrl = alternateHrefs.find(href => href.aliasId === userSelectedSiteId)?.uri;

    // We get back site id 0 first before everything has got resolved. Then we don't want to do something.
    if (currentSiteId === 0) {
        return null;
    }

    // The current site is missing from sites, something is wrong, do nothing.
    if (!currentSiteId) {
        return null;
    }

    // The user has selected a site
    if (userSelectedSiteId) {
        // The user is on a site that they have not selected
        if (currentSiteId !== userSelectedSiteId) {
            overlay.open('wrong-site-overlay', {
                currentSiteId,
                hrefUrl: userSelectedHrefSiteUrl,
                suggestedSiteId: userSelectedSiteId,
            });
            return null;
        } else {
            overlay.close('wrong-site-overlay');
            return null;
        }
    }

    // The uses has not selected a site but we have a primary site
    if (userPrimarySiteId) {
        // The current site is not the site suggested by geolocation
        if (currentSiteId !== userPrimarySiteId) {
            overlay.open('wrong-site-overlay', {
                currentSiteId,
                hrefUrl: userPrimaryHrefSiteUrl,
                suggestedSiteId: userPrimarySiteId,
            });
            return null;
        } else {
            overlay.close('wrong-site-overlay');
            return null;
        }
    }

    return null;
};

WrongSiteHandler.propTypes = {
    alternateHrefs: PropTypes.arrayOf(
        PropTypes.exact({
            aliasId: PropTypes.number,
            app: PropTypes.string,
            appId: PropTypes.number,
            hrefLang: PropTypes.string,
            locale: PropTypes.string,
            uri: PropTypes.string,
        })
    ),
};

export default WrongSiteHandler;
