// import isObject from '@grebban/utils/object/isObject';

const locks: { [key: string]: Promise<unknown>[] } = {};

export const acquireMutex = (mutex: string) => {
    if (typeof mutex === 'string') {
        // It's a single lock.
        let mutexResolver: any = null;

        const mutexPromise = new Promise(resolve => {
            mutexResolver = resolve;
        });

        if (!locks[mutex]) {
            locks[mutex] = [];
        }

        locks[mutex].push(mutexPromise);

        if (locks[mutex].length > 1) {
            // Mutex exist.
            return locks[mutex][locks[mutex].length - 2].then(() => mutexResolver);
        }

        return mutexResolver;
    }
    // else if (isObject(mutex) && locks?.length > 0 && locks.length > 0) {
    // It's an array.
    // @todo: Implement multiple mutexes.
    // }
};
