let supportsPassiveEvents = false;

declare global {
    interface WindowEventMap {
        test: CustomEvent;
    }
}

try {
    const opts = Object.defineProperty({}, 'passive', {
        get() {
            supportsPassiveEvents = true;
            return true;
        },
    });

    window.addEventListener('test', () => null, opts);
    window.removeEventListener('test', () => null, opts);
} catch (e) {
    // noop();
}

export default supportsPassiveEvents;
