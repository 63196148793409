import {
    ColorBackground,
    ImageBackground,
    RawBynderMediaData,
    RawColorData,
    RawImageData,
    RawSplitData,
    RawVideoData,
    VideoBackground,
} from '@activebrands/core-web/libs/storyblok/storyblok';
import transformBynderMedia from './transform-bynder-media';
import transformImage from './transform-image';
import transformVideo from './transform-video';

export type RawBackgroundData = RawImageData | RawVideoData | RawColorData | RawBynderMediaData;

export type TransformedBackgroundTypes = ImageBackground | VideoBackground | ColorBackground | undefined;
export type TransformedBackground = TransformedBackgroundTypes[] | TransformedBackgroundTypes;

const allowedBackgroundColors: Record<string, string> = {
    grey: 'var(--color-contrast-low)',
    black: 'var(--color-bg-inverted)',
};

/**
 * Accepts raw background data from Storyblok and interperates it either as a image, video or color.
 */

const transformFlexibleBackground = (
    flexibleBackground: RawBackgroundData | RawSplitData,
    settings: Record<string, any> = {}
): TransformedBackground | undefined => {
    let splitBackground;

    switch (flexibleBackground?.component) {
        case 'backgroundSplit':
            splitBackground = flexibleBackground.background?.map(
                (background: RawBackgroundData): TransformedBackground =>
                    transformFlexibleBackground(background, settings)
            );

            if (splitBackground?.length === 1) {
                return splitBackground[0];
            } else if (splitBackground) {
                return splitBackground;
            }
            return undefined;

        case 'backgroundBynder':
            return transformBynderMedia(flexibleBackground, settings.targetBreakpointBynder);

        case 'backgroundImage':
            return transformImage(flexibleBackground);

        case 'backgroundVideo':
            return transformVideo(flexibleBackground);

        case 'backgroundColor':
            return {
                color: allowedBackgroundColors[flexibleBackground.color] || 'transparent',
                type: 'color',
            };

        default:
            return undefined;
    }
};

export default transformFlexibleBackground;
