import { StoreAction } from '@activebrands/core-web/state/store';
import { BundleInfo } from '@activebrands/core-web/types/basket';
import { PageViewProduct, ProductPrice, ProductVariant } from '@activebrands/core-web/types/product';

export const setProduct =
    (product: PageViewProduct): StoreAction =>
    (dispatch, getState) => {
        const state = getState();

        if (state.product.sku === product.sku) {
            return;
        }

        let selectedVariant: ProductVariant | undefined = undefined;

        // If the previous product is related to the current, save selected variant
        if (state.product.selectedVariant) {
            const relatedProducts = product.relatedVariations.map(v => v.sku);
            if (relatedProducts.includes(state.product.sku)) {
                const selectedSize = state.product.selectedVariant.name;
                const selectSize = product.variations.filter(v => v.name === selectedSize)[0] || null;
                if (selectSize?.inStock) {
                    selectedVariant = selectSize;
                }
            }
        }

        // If the product only has one size option, save selected variant
        if (product.variations.length === 1) {
            if (product.variations[0]?.inStock) {
                selectedVariant = product.variations[0];
            }
        }

        // Add campaign price if possible (doesn't work in SSR as campaign price will never be present there)
        let price = product.price;
        if (state.campaignSite.campaignPricelist && product.id && state.campaignSite.campaignPricelist[product.id]) {
            price = state.campaignSite.campaignPricelist[product.id];
        }

        dispatch({
            type: 'SET_PRODUCT',
            payload: {
                categories: product.categories,
                customAttributes: product.customAttributes,
                id: product.id,
                media: product.media[0],
                name: product.name,
                price,
                selectedVariant,
                sku: product.sku,
                uri: product.uri,
                variations: product.variations,
            },
        });
    };

export const setVariant =
    (data: any): StoreAction =>
    dispatch => {
        dispatch({ type: 'SET_VARIANT', payload: data });
    };

export const setBundle =
    (bundleInfo?: BundleInfo): StoreAction =>
    dispatch => {
        dispatch({ type: 'SET_BUNDLE', payload: bundleInfo });
    };

export const setProductPrice =
    (price: ProductPrice): StoreAction =>
    dispatch => {
        dispatch({ type: 'SET_PRODUCT_PRICE', payload: price });
    };
