import fm from 'format-message';
import { FacetMap } from '@activebrands/core-web/types/faceting';

/*
 * Used to display label for filters on category pages and the search page
 * Remember to add facetDataExtractors in @activebrands/core-server/utils/facets.ts
 */
export const facetMap: FacetMap = {
    'price': {
        label: fm('Price'),
        type: 'range',
        param: 'price',
    },
    // 'inStock': {
    //     label: fm('Show only in stock'),
    //     type: 'property',
    //     param: 'inStock',
    // },
    'activity': {
        label: fm('Activity'),
        type: 'set',
        param: 'activity',
    },
    'category': {
        label: fm('Category'),
        type: 'set',
        param: 'category',
    },
    'color': {
        label: fm('Color'),
        type: 'set',
        param: 'color',
    },
    'fit': {
        label: fm('Fit'),
        type: 'set',
        param: 'fit',
    },
    'gender': {
        label: fm('Gender'),
        type: 'set',
        param: 'gender',
    },
    'materials': {
        label: fm('Materials'),
        type: 'set',
        param: 'materials',
    },
    'productType': {
        label: fm('Product type'),
        type: 'set',
        param: 'productType',
    },
    'size': {
        label: fm('Size'),
        type: 'set',
        param: 'size',
    },
    'technology': {
        label: fm('Technology'),
        type: 'set',
        param: 'tech',
    },
};

/*
 * Facets template for Algolia Facets
 */

export const getAlgoliaFacetsMapByMarketId = (marketId: string): FacetMap => {
    const algoliaFacetsMap: FacetMap = {
        'activity': facetMap['activity'],
        'filter_categories': facetMap['category'],
        'filter_colors': facetMap['color'],
        'filter_technology': facetMap['technology'],
        'fit': facetMap['fit'],
        'gender': facetMap['gender'],
        'materials': facetMap['materials'],
    };

    // The size facet is dynamic by market
    const sizesKey = `sizes_by_market_stock.${marketId}`;
    algoliaFacetsMap[sizesKey] = facetMap['size'];

    return algoliaFacetsMap;
};

/*
 * Some attributes only have values, add the label with this map
 */

export const attributeValueLabelMap: Record<string, string> = {
    // Example data:
    // 'women': fm('Women'),
    // 'men': fm('Men'),
    // 'unisex': fm('Unisex'),
};
