import { graphql, useStaticQuery } from 'gatsby';

const useAppQuery = () => {
    const { application } = useStaticQuery(graphql`
        {
            application {
                data {
                    application {
                        id
                        locale
                    }
                    site {
                        id
                        path
                        domain
                        protocol
                        pricelist_public_id
                        market_public_id
                    }
                    pricelist {
                        currency
                        currency_decimal_digits
                    }
                    config {
                        dedicatedPages {
                            checkoutPage
                            checkoutResponsePage
                        }
                    }
                    countries {
                        currency_id
                        id
                        language_id
                        market_id
                        market_public_id
                        name
                        pricelist_id
                        pricelist_public_id
                        ship_to
                        states
                    }
                }
            }
        }
    `);

    const content = application.data;
    return {
        ...content,
        countries: content.countries.map(country => ({
            ...country,
            states: country.states ? country.states : [], // Empty array if there is no states
        })),
    };
};

export default useAppQuery;
