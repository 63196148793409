import { PageState } from '@activebrands/core-web/state/page/types';
import { StoreAction } from '@activebrands/core-web/state/store';

export const setPage =
    (page: PageState): StoreAction =>
    dispatch => {
        dispatch({
            type: 'SET_PAGE_SUCCESS',
            payload: {
                name: page?.data?.name,
            },
        });
    };
