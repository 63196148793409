/* eslint-disable @typescript-eslint/no-explicit-any */
import './src/styles/reset.css';
import './src/styles/global.css';
import './src/styles/fonts.css';
import './src/styles/misc.css';
import replaceHydrateFunctionCore from '@activebrands/core-server/setup/replace-hydrate-function';
import { facetMap } from '@activebrands/core-web/config/filters';
import { scrollToHashOnRouteUpdate, shouldResetScrollPositionOnRouteUpdate } from '@activebrands/core-web/utils/scroll';
import Layout from './src/components/Layout';
import RootElementWrapper from './src/components/wrappers/RootElementWrapper';

/**
 * Can be used to the wrap the root element. This is useful to set up any Provider components that will wrap your application.
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 */

export const wrapRootElement = RootElementWrapper;

/**
 * Can be used to wrap each page element. This is useful for setting wrapper components around pages that won’t get unmounted on page changes.
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 */

export const wrapPageElement = Layout;

/**
 * Allow a plugin to replace the ReactDOM.render/ReactDOM.hydrate function call by a custom renderer.
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
 */

export const replaceHydrateFunction = () => (element: any, container: any, callback: any) => {
    replaceHydrateFunctionCore(element, container, callback);
};

/**
 * Allows a plugin to influence scrolling behavior on navigation.
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
 */

export const shouldUpdateScroll = props => {
    return shouldResetScrollPositionOnRouteUpdate(props.routerProps.location, facetMap);
};

/**
 * Called when the user changes routes, including on the initial load of the app.
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 */

export const onRouteUpdate = ({ location, prevLocation }) => {
    scrollToHashOnRouteUpdate(location, prevLocation);
};
