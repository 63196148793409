import { useMemo, useRef } from 'react';
import Events from '@activebrands/core-web/libs/Events';
import { EventTypes } from '@activebrands/core-web/libs/Events/types';
import { inServer } from '@activebrands/core-web/utils/constants';
import supportsPassiveEvents from '@activebrands/core-web/utils/supports-passive-events';
import uuid from '@grebban/utils/string/uuid';
import { useWillUnmount } from './lifecycle';

export type Listener = (ev: Event) => any;

interface Target extends EventTarget {
    __eventListenerKey?: string;
}

type TargetTypes = 'window' | 'document' | 'document.body' | Target;

export type Options = {
    once?: boolean;
    passive?: boolean;
    subscribe?: boolean;
    target?: TargetTypes;
};

export const getTargetElement = (target: TargetTypes): Target | undefined => {
    if (inServer) {
        return;
    }

    if (typeof target === 'function') {
        return target;
    }

    switch (target) {
        case 'window':
            return window;
        case 'document':
            return document;
        case 'document.body':
            return document.body;
        default:
            console.error('Invalid target', target);
            return;
    }
};

const eventListener = (ev: Event) => {
    const key = (ev.target as Target).__eventListenerKey || (window as Target).__eventListenerKey;

    Events.trigger(`${key}.${ev.type}`, ev);
};

const useEventListener = (target: TargetTypes) => {
    const handlers = useRef<Record<string, number>>({});

    const element = useMemo(() => getTargetElement(target), [target]);

    const subscribe = (event: EventTypes, callback: Listener, options: Options = {}) => {
        if (!element) {
            return 0;
        }

        if (!element.__eventListenerKey) {
            element.__eventListenerKey = typeof target === 'string' ? target : uuid();
        }

        const key = `${element.__eventListenerKey}.${event}`;
        const size = Object.keys(Events.get(key) || {}).length;

        if (size < 1) {
            element.addEventListener(
                event,
                eventListener,
                options.passive && supportsPassiveEvents ? { passive: true } : false
            );
        }

        const handler = Events.subscribe(key, callback) as number;

        handlers.current[key] = handler;

        return handler;
    };

    const unsubscribe = (event: EventTypes, handler: number) => {
        if (!element) {
            return false;
        }

        const key = `${element.__eventListenerKey}.${event}`;
        const size = Object.keys(Events.get(key) || {}).length;

        Events.unsubscribe(key, handler);

        if (size < 1) {
            element.removeEventListener(event, eventListener);
        }

        delete handlers.current[key];

        return true;
    };

    useWillUnmount(() => {
        Object.entries(handlers.current).map(h => unsubscribe(...h));
    });

    return {
        subscribe,
        unsubscribe,
        target: element,
    };
};

export default useEventListener;
