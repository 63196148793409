import { StoreAction } from '@activebrands/core-web/state/store';
import fetchProductsFromGcJson from '@activebrands/core-web/utils/fetch-products-from-gc-json';
import transformProductCardMiniData from '@activebrands/core-web/utils/product/transform-product-card-mini-data';
import Cookies from 'js-cookie';

const getProductsFromGC = async (parsedCookie: Array<string>) => await fetchProductsFromGcJson(parsedCookie);

// if env var exist and is a valid number (!== NaN), use it, else default to 10
const MAX_RECENTLY_VIEWED = Number(process.env.REACT_APP_RECENTLY_VIEWED_COUNT) || 10

/**
 * Util function to check if list is full
 * @param length 
 * @returns bool
 */
const isListFull = (arr: any[]) => {
    return arr.length >= MAX_RECENTLY_VIEWED;
}

/**
 * Util function to check if a string is valid JSON
 * @param str 
 * @returns 
 */
const isValidJson = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * Util function to handle edge-case where user has old cookie which is not JSON.stringified, 
 * but a comma separated number list.
 */
export const parseRecentlyViewedCookie = (productsFromCookie: string | undefined): Array<string> => {
    if (!productsFromCookie) return [];

    // handles edge-case where user has old cookie which is not JSON.stringified, but a comma separated number list.
     if (isValidJson(productsFromCookie)) {
        const val = JSON.parse(productsFromCookie); // new case returns a Array<string>
        return typeof val === 'number' ? [val.toString()] : val; // if someone messes with the cookie and uses a single number, we put it in an array here
    } else if (isValidJson(`[${productsFromCookie}]`)) {
        return JSON.parse(`[${productsFromCookie}]`);  // old case returns a comma separated list
    } else {
        return []; // if someone messes with the cookie
    }
}

export const getRecentlyViewedItems = () => async (dispatch, getState) => {
    const productsFromRedux = getState().recentlyViewedList?.items; // start with product data from redux (as this is synced to be the most recently viewed)
    const productIdsFromRedux = productsFromRedux.map(product => product.id);

    const transformedProductData: Array<object> = []; // new array to contain complete list

    // transform the redux list to the new container 
    productsFromRedux?.forEach(product => {
        transformedProductData.push(
            transformProductCardMiniData({ product: product || {}, source: 'GrebCommerce' })
        );
    })

    // use data stored in cookie if the list has available space
    if (!isListFull(transformedProductData)) {
        const productsFromCookie = Cookies?.get(`${process.env.REACT_APP_COOKIES_RECENTLY_VIEWED}`);
        
        if (productsFromCookie) {
            const parsedCookieArray = parseRecentlyViewedCookie(productsFromCookie)            
            const uniqueAdditionsFromCookie: Array<string> = []
            
            parsedCookieArray?.forEach(productId => {
                /**
                 * If the product is not a duplicate to redux, and the combined list has available space,
                 * aswell as (edge-case) if it is a old cookie, add a check to handle duplicates.
                 */
                if (
                    !productIdsFromRedux.includes(productId) 
                    && !isListFull([...productIdsFromRedux, ...uniqueAdditionsFromCookie])
                    && !uniqueAdditionsFromCookie.includes(productId)    
                ) {
                    uniqueAdditionsFromCookie.push(productId);
                }
            })
            
            const productsArray = await getProductsFromGC(uniqueAdditionsFromCookie); // get the missing product data 
            
            productsArray.forEach((product: object) => {
                // add this product data to the container 
                transformedProductData.push(transformProductCardMiniData({ product: product, source: 'JSON' }));

            });
        }
    }

    return transformedProductData;
};

//TODO: Add ProductCardMini data type and replace with object
export const addRecentlyViewedListItem =
    (listProduct: object): StoreAction =>
    (dispatch, getState) => {
        // if there is no product.id, return early.
        if (!listProduct['id']) return null; 

        const recentlyViewedList = getState().recentlyViewedList?.items || [];
        
        // check if product already in list, if so return the index
        const indexFound = recentlyViewedList.findIndex(listItem => {
            return listItem.id === listProduct['id'] 
        });


        /* 
        * There is 2 types of removals, 
        * 'if' a product id is a duplicate (index found), remove that particular one
        * 'else' simply remove the oldest item 
        */
        if (indexFound !== -1) {
            recentlyViewedList.splice(indexFound, 1); // if product id already in list (valid index), remove it.
        } else if (isListFull(recentlyViewedList)) {
            recentlyViewedList.pop(); // remove oldest item first if list too long
        }

        
        recentlyViewedList.unshift(listProduct); // add the 'new' product to the beginning of the list
        const productIds = recentlyViewedList.map(product => product.id);
        const productsFromCookie = Cookies?.get(`${process.env.REACT_APP_COOKIES_RECENTLY_VIEWED}`);
        
        /*
        * If there is a cookie value, and we don't have a full list already, merge these with our redux values.
        */
        if (!!productsFromCookie && !isListFull(productIds)) {
            const parsedCookieArray = parseRecentlyViewedCookie(productsFromCookie)            
            
            parsedCookieArray?.forEach((productId) => {
                /*
                * Too keep the most recent version of each id, we check to see that the productIds found in cookie are not
                * already present in redux, which we know is a unique list given the previous logic of removing duplicates.
                * We also check to see that it is relevant to add a new id (list not full)
                */
                if (!productIds.includes(productId) && !isListFull(productIds)) {
                    productIds.push(productId);
                }
            })
        }

        Cookies.set(`${process.env.REACT_APP_COOKIES_RECENTLY_VIEWED}`, JSON.stringify(productIds.slice(0, MAX_RECENTLY_VIEWED)), { expires: 28 });

        try {
            dispatch({ type: 'ADD_RECENTLY_VIEWED_PRODUCT', payload: { items: recentlyViewedList } }); // save list to redux store
        } catch (e) {}
    };
