import { useEffect } from 'react';
import useSiteQuery from 'queries/useSiteQuery';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import overlay from '@activebrands/core-web/libs/overlay';
import { Popup } from '@activebrands/core-web/libs/storyblok/storyblok';
import handleVisibilitySettings from '@activebrands/core-web/libs/storyblok/utils/handle-visibility-settings';
import { useTypedSelector } from '@activebrands/core-web/state/store';
import loadable from '@loadable/component';
import Cookies from 'js-cookie';

const PopupOverlay = loadable(() => import('components/overlays/PopupOverlay'));

const POPUP_COOKIE = process.env.REACT_APP_POPUP_COOKIE;
const POPUP_SESSION_STORAGE = process.env.REACT_APP_POPUP_SESSION_STORAGE;

const RenderComponent = (popup: Popup) => {
    const { closeButtonColor, content, cookieLifespan, id, show, timeOut } = popup || {};

    let openTimeout;

    const openPopupWithTimeout = (id: string, timeOut: number) => {
        openTimeout = setTimeout(() => {
            overlay.open(id);
        }, timeOut);
    };

    const popupHasCookieOrSessionStorage = (id: string) => {
        const cookie = Cookies.get(`${POPUP_COOKIE}_${id}`);
        const session = sessionStorage.getItem(`${POPUP_SESSION_STORAGE}_${id}`);

        return cookie === id || session === id;
    };

    // Sets a cookie or a session storage
    // Cookies is used for popups that has a cookie lifespan
    // Session storage is used for popups that has no cookie lifespan
    const handleCallback = (id: string, cookieLifespan: number) => {
        if (cookieLifespan) {
            Cookies.set(`${POPUP_COOKIE}_${id}`, `${id}`, { expires: cookieLifespan });
        } else {
            sessionStorage.setItem(`${POPUP_SESSION_STORAGE}_${id}`, `${id}`);
        }
    };

    useEffect(() => {
        // Show is from the visibility settings and decides if the popup should be shown at all
        // Only show the popup if the user has not seen it before
        if (show && !popupHasCookieOrSessionStorage(id)) {
            if (timeOut) {
                openPopupWithTimeout(id, timeOut);
            } else {
                overlay.open(id);
            }
        }
        return () => {
            clearTimeout(openTimeout);
        };
    }, []);

    return (
        <AnimatedOverlay
            lockScroll
            $style={{ height: 'auto', top: '50%', zIndex: 'var(--zindex-popup)' }}
            animation="slideBTM"
            animationDuration="none"
            callback={handleCallback}
            closeButtonColor={closeButtonColor}
            component={PopupOverlay}
            content={content}
            cookieLifespan={cookieLifespan}
            id={id}
        />
    );
};

const Popups = () => {
    const { popups } = useSiteQuery();
    const currentSiteId = useTypedSelector(state => state.application.aliasId);

    if (!popups.length) {
        return null;
    }

    const popupsData = handleVisibilitySettings({ items: popups, aliasId: `${currentSiteId}` });

    return popupsData.map((props: Popup) => <RenderComponent key={`${props.id}-${props.show}}`} {...props} />);
};

export default Popups;
