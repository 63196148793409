import { CssResponsiveVariables, CssVariables } from '@activebrands/core-web/types/css-types';
import { GridCssResponsiveVariables } from '../grid';
import { RatiosCssResponsiveVariables } from '../ratios';
import {
    EasingCssResponsiveVariables,
    PacingCssResponsiveVariables,
    TransitionsCssResponsiveVariables,
} from '../transitions';
import { ZIndexCssResponsiveVariables } from '../z-index';
import defaultTheme from './default';

interface ThemeVariables extends CssVariables<any> {
    // @TODO Extend the config interfaces
    // @TODO Add all the "static" ThemeVars here
    // '--site-corridor-max-width': string; // @TODO Change this to Pixel|Percent|Vw

    [key: string]: any;
}
export interface Theme {
    statics: ThemeVariables;
    responsives: CssResponsiveVariables<
        GridCssResponsiveVariables &
            RatiosCssResponsiveVariables &
            PacingCssResponsiveVariables &
            EasingCssResponsiveVariables &
            TransitionsCssResponsiveVariables &
            ZIndexCssResponsiveVariables &
            Partial<ThemeVariables>
    >;
}
export interface Themes {
    default: Theme;
    // dark: Theme;
}

export const themes: Themes = {
    // Examples:
    // light: {},
    // xmas: {},
    // dark: darkTheme,
    default: defaultTheme,
};
export const validThemes = Object.keys(themes);

// @TODO Make it work with responsives as well or scrap?
// export const activateTheme = (themeName: string): void => {
//     if (validThemes.indexOf(themeName) === -1) {
//         return;
//     }
//     const theme = themes[themeName];
//     const root = document.documentElement;
//     Object.keys(theme).forEach(key => {
//         const value = theme[key];
//         root.style.setProperty(key, value);
//     });
// };

// @TODO Doesn't work?
// export const getCssVariableValue = (cssVariableName: string) => {
//     const root = document.documentElement;
//     return root.style.getPropertyValue(cssVariableName);
// };

export default themes;
