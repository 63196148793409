import { Helmet } from 'react-helmet';
import { Provider as ReduxProvider } from 'react-redux';
import PropTypes from 'prop-types';
import { themes } from 'config/branding/themes';
import { breakpoints } from '@activebrands/core-web/config/breakpoints';
import store from '@activebrands/core-web/state/store';
import CampaignSiteHandler from 'components/CampaignSiteHandler';
import ThirdPartyScripts from 'components/ThirdPartyScripts';

const RootElementWrapper = ({ element }) => (
    <ReduxProvider store={store}>
        <Helmet
            style={[
                {
                    'cssText': `
                            :root {               
                                /* Theme variables */
                                --current-breakpoint: '${breakpoints[0].label}';
                                ${Object.entries(themes.default.statics)
                                    .map(([key, value]) => `${key}: ${value};`)
                                    .join('')}

                                /* @media (prefers-color-scheme: dark) {
                                    Loop dark theme variables here if you want a dark theme
                                    
                                } */

                                /* @media (-ms-high-contrast: active) {
                                    Loop high-contrast theme variables here if you want a high-contrast theme
                                } */
                            }
                            
                            ${breakpoints
                                .map(
                                    breakpoint =>
                                        `@media screen and (min-width: ${breakpoint.from}px) {
                                :root {
                                        --current-breakpoint: '${breakpoint.label}';
                                        ${Object.entries(themes.default.responsives[breakpoint.label])
                                            .map(([key, value]) => `${key}: ${value};`)
                                            .join('')}}}`
                                )
                                .join('')}    
                            
                        `,
                },
            ]}
        />
        <ThirdPartyScripts />
        <CampaignSiteHandler />
        {element}
    </ReduxProvider>
);

RootElementWrapper.propTypes = {
    element: PropTypes.node.isRequired,
};

export default RootElementWrapper;
