import aa from 'search-insights';

export interface ConvertedObjectIDsAfterSearchData {
    eventName: string;
    index: string;
    objectIDs: string[];
    queryID: string;
    userToken: string;
}

/*
 * Send a conversion event to capture a query and its clicked items.
 *
 * Note that unlike convertedObjectIDs, convertedObjectIDsAfterSearch takes a queryID.
 * Therefore, the captured event goes towards both Personalization and Click and Conversion Analytics.
 * convertedObjectIDs only goes towards personalization.
 */
export default (data: ConvertedObjectIDsAfterSearchData) => {
    aa('convertedObjectIDsAfterSearch', data);
};
