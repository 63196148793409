import { CssResponsiveVariables, Pixels } from '@activebrands/core-web/types/css-types';

export interface GridCssResponsiveVariables {
    '--grid-item-default-columnn-size'?: number;
    '--grid-item--default-row-size'?: number;
    '--grid-columns'?: number;
    '--grid-padding'?: Pixels;
    '--grid-gap'?: Pixels;
}
export const gridCssResponsiveVariables: CssResponsiveVariables<GridCssResponsiveVariables> = {
    'mobile.sm': {},
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {},
    'desktop.md': {},
    'desktop.lg': {},
};
