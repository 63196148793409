/* eslint-disable @typescript-eslint/no-explicit-any */
import { FacetMap } from '@activebrands/core-web/types/faceting';
import arrayIntersection from '@grebban/utils/array/intersection';
import { scrollTo, scrollToHash } from '@grebban/utils/function/scroll';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { WindowLocation } from '@reach/router';

export const scrollToHashOnRouteUpdate = (location: WindowLocation, prevLocation: WindowLocation) => {
    // Handle anchor tags on new pages
    // Note: Anchor tag links on the same page gets handled inside components/base/Link
    if (location.hash && location.hash !== prevLocation?.hash) {
        // Reset scroll position so it scrolls from top
        scrollTo({ behavior: 'auto', top: 0 });
        // Scroll to target after a short delay because:
        // 1. So that the user registers it's a new page and
        // 2. The element gets time to render properly
        setTimeout(() => {
            scrollToHash(location.hash);
        }, 250);
    }
};

export const shouldResetScrollPositionOnRouteUpdate = (location: WindowLocation, facetMap: FacetMap) => {
    // Don't reset scroll if there are any filters present or if pagination is triggered
    const query = location.search;
    if (query) {
        const params = extractQueryParams(query);
        const paramKeys = Object.keys(params);
        const facetKeys = Object.keys(facetMap);
        const intersections = arrayIntersection(paramKeys, facetKeys);

        if (intersections.length > 0 || paramKeys.includes('page')) {
            return false;
        }
    }

    return true;
};
