import aa from 'search-insights';

export interface ClickedFiltersData {
    eventName: string;
    filters: string[];
    index: string;
    userToken: string;
}

/*
 * Send a click event to capture the filters a user clicks on.
 */
export default (data: ClickedFiltersData) => {
    aa('clickedFilters', data);
};
