/**
 * Replaces all occurrences of a specified value in an object with a new value.
 * @param {object} obj - The object in which to replace values.
 * @param {*} valueToReplace - The value to search for and replace.
 * @param {*} newValue - The new value to replace the old value with.
 * @returns {object} The object with replaced values.
 */
const replaceObjectValue = (obj: Record<string, any>, valueToReplace: any, newValue: any): Record<string, any> => {
    for (const key in obj) {
        if (obj[key] === valueToReplace) {
            obj[key] = newValue;
        }
    }
    return obj;
};

export default replaceObjectValue;
