import { EventsHandler } from '@activebrands/core-web/libs/Events/types';
import { inServer, isStoryblokPreview } from '@activebrands/core-web/utils/constants';

export const createEventsHandler = (): EventsHandler => {
    const events: Record<string, any | Record<number, Function>> = {};

    let nextHandle = 0;
    const getNextHandle = () => ++nextHandle;

    return {
        get: event => events[event],
        subscribe: (event, callback) => {
            if (inServer || isStoryblokPreview) {
                return;
            }

            if (!events[event]) {
                events[event] = {};
            }

            const handle = getNextHandle();

            events[event][handle] = callback;

            return handle;
        },
        trigger: (event, ...args) => {
            if (inServer || isStoryblokPreview) {
                return;
            }
            if (events[event] && Object.keys(events[event]).length > 0) {
                Object.keys(events[event]).forEach(handle => {
                    typeof events[event][handle] === 'function' && events[event][handle](...args);
                });
            } else {
                if (process.env.REACT_APP_DEBUG_ENABLED === 'true') {
                    console.warn(`Event ${event} is unknown or doesn't have any subscribers.`);
                }
            }
        },
        unsubscribe: (event, handle) => {
            if (inServer || isStoryblokPreview) {
                return;
            }

            if (events[event] && events[event][handle] !== undefined) {
                delete events[event][handle];
                return true;
            }

            return false;
        },
    };
};

const Events = createEventsHandler();

export default Events;
