export const toHash = (str: string): string => {
    let hash = 5381;
    let i = str.length;

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }

    return (hash >>> 0).toString(36);
};

export const toSnakeCase = (s: string) => s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toKebabCase = (s: string) => s.replace(/\s+/g, '-').toLowerCase().replace(/[åä]/g, 'a').replace(/ö/g, 'o');

export const toCamelCase = (s: string) => s.replace(/[-_]([a-z])/g, g => g[1].toUpperCase());

export const stringReplace = (s = '', replace: Record<string, string>) => {
    Object.keys(replace).forEach(key => {
        const re = new RegExp(key, 'g');
        s = s.replace(re, replace[key] || '');
    });
    return s;
};

export const jsonParse = (s: any) => {
    let result = s;
    try {
        if (typeof s === 'string') {
            result = JSON.parse(s);
        }
    } catch {}

    return result;
};

export const toDoubleDigitString = (n: number) => {
    return n > 9 ? `${n}` : `0${n}`;
};
export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeWords = (string: string) => string.replace(/\b\w/g, char => char.toUpperCase());

export const endWithSlah = (string: string) => (string.charAt(string.length - 1) === '/' ? string : `${string}/`);

export const randomKey = () => Math.random().toString(36).substring(7);

export const camelCaseToNormalString = (s: string) => capitalize(s.replace(/[A-ZÅÄÖ]/g, letter => ` ${letter}`));

export const commaSeparatedListToArray = (s: string): string[] => {
    return s.split(',').map(s => s.trim());
};

export const removePreceedingSlash = (string: string) => (string?.startsWith('/') ? string.substring(1) : string);

export const removeHtmlTags = (string: string) => {
    return string.replace(/<\/?[\w\s/]+>/g, '');
};
