/**
 * Generates a product name string by combining the name and color if provided.
 *
 * @param {string} name - The name of the product. Must not be empty.
 * @param {string} [color] - The color of the product. Optional.
 * @returns {string} The product name, optionally combined with the color in the format: "name - color".
 *                   If no color is provided, returns the name. If the name is not provided, returns an empty string.
 */
const parseProductName = (name: string, color?: string) => {
    if (!name) {
        return '';
    }
    if (name && color) {
        return `${name} - ${color}`;
    }
    return name;
};

export default parseProductName;
