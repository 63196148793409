import { RawVideoData, VideoBackground } from '@activebrands/core-web/libs/storyblok/storyblok';

// @todo: add correct props for entire file

/**
 * Accepts raw video data from Storyblok.
 */

// @todo: Add correct video transformation
export default (rawVideo: RawVideoData): VideoBackground => ({
    hostedBy: 'storyblok',
    src: rawVideo?.video?.filename,
    title: rawVideo?.video?.title,
    type: 'video',
});
