import { DependencyList, useEffect, useRef } from 'react';
import Events from '@activebrands/core-web/libs/Events';
import { EventTypes } from '@activebrands/core-web/libs/Events/types';

const useEvent = <E extends EventTypes, C>(
    event: E,
    callback: (args: C) => void,
    deps: DependencyList = []
): (() => void) => {
    const handler = useRef<number | null>(null);

    const unsubscribe = () => {
        if (handler.current) {
            Events.unsubscribe(event, handler.current!);
            handler.current = null;
        }
    };

    useEffect(() => {
        handler.current = Events.subscribe(event, callback)!;

        return () => unsubscribe();
    }, deps);

    return unsubscribe;
};

export default useEvent;
