/* eslint-disable camelcase */
import DataLayer from './DataLayer';
import Gtm from '@grebban/gtm';
import type { Product, TotalDiscount, Totals, User } from '@activebrands/core-web/types/tracking-events';

interface Props {
    id: number;
    products: Product[];
    totals: Totals;
    totalDiscount: TotalDiscount;
    currencyCode: string;
    user: User;
}

export default ({ id, products, totals, totalDiscount, currencyCode, user }: Props) => {
    const hasDiscounts = totalDiscount?.anyDiscount || products.reduce((a, c) => a || c.anyDiscount, false);
    const discountWithoutTax = Math.abs(totals.totalDiscountAsNumber * ((100 - totals.taxPercentage) / 100));

    const revenue = products.reduce((prev, item) => {
        return prev + (item.price.price * item.quantity);
    }, 0);

    Gtm.addDataLayerEvent('transaction', {
        value: revenue,
        ecommerce: {
            currencyCode,
            purchase: {
                actionField: {
                    id,
                    affiliation: 'Online Store',
                    revenue: revenue - discountWithoutTax,
                    shipping: totals?.shippingPriceAsNumber,
                    tax: totals?.finalTaxSumAsNumber,
                    discount: discountWithoutTax,
                    coupon: hasDiscounts ? totalDiscount.discounts.map(({ id }) => id).join('|') : '',
                },
                products: DataLayer.getProducts(products),
            },
            email: user.email,
            phone: user.phoneNumber
        },
    });
};
