type EnumValue<T extends string | number | bigint | boolean | null | undefined> = `${T}`;

//
// APP EVENTS
//
export enum ApplicationEvents {
    RESOLVED = 'APPLICATION.RESOLVED',
    CONSENT = 'PAGE.CONSENT',
}
export type ApplicationEventsType = EnumValue<ApplicationEvents>;

export enum PageEvents {
    VIEW = 'PAGE.VIEW',
    RESET = 'PAGE.RESET',
    RESOLVED = 'PAGE.RESOLVED',
}
export type PageEventsType = EnumValue<PageEvents>;

export enum SiteEvents {
    MOUNTED = 'SITE.MOUNTED',
}
export type SiteEventsType = EnumValue<SiteEvents>;

export enum OverlayEvents {
    STATE_CHANGE = 'OVERLAY.STATE_CHANGE',
}
export type OverlayEventsType = EnumValue<OverlayEvents>;

export enum SectionTrackerEvents {
    ADD_SECTIONS = 'SECTION_TRACKER.ADD_SECTIONS',
    REMOVE_SECTIONS = 'SECTION_TRACKER.REMOVE_SECTIONS',
    SCROLL_DOWN = 'SECTION_TRACKER.SCROLL_DOWN',
    SCROLL_UP = 'SECTION_TRACKER.SCROLL_UP',
}
export type SectionTrackerEventsType = EnumValue<SectionTrackerEvents>;

//
// ECOMMERCE EVENTS.
//
export enum BasketEvents {
    // Basket.
    CREATED = 'BASKET.CREATED',
    SET = 'BASKET.SET',
    OPENED = 'BASKET.OPENED',
    CLOSED = 'BASKET.CLOSED',
    TOGGLED = 'BASKET.TOGGLED',
    RETRIEVED = 'BASKET.RETRIEVED',
    EMPTIED = 'BASKET.EMPTIED',
    ERROR = 'BASKET.ERROR',

    // Products.
    PRODUCT_ADDED = 'BASKET.PRODUCT_ADDED',
    PRODUCT_REMOVED = 'BASKET.PRODUCT_REMOVED',
    OUT_OF_STOCK = 'BASKET.OUT_OF_STOCK',

    // Vouchers.
    VOUCHER_ADDED = 'BASKET.VOUCHER_ADDED',
    VOUCHER_FAILED = 'BASKET.VOUCHER_FAILED',
    VOUCHER_REMOVED = 'BASKET.VOUCHER_REMOVED',
}
export type BasketEventsType = EnumValue<BasketEvents>;

export enum CheckoutEvents {
    VIEW = 'CHECKOUT.INIT',
    OPTION = 'CHECKOUT.OPTION',
    SHIPPING_INFO_ADDED = 'CHECKOUT.SHIPPING_INFO_ADDED',
    PAYMENT_INFO_ADDED = 'CHECKOUT.PAYMENT_INFO_ADDED',
    TRANSACTION = 'CHECKOUT.TRANSACTION',
    REFUND = 'CHECKOUT.REFUND',
    VIEW_CART_STEP = 1,
    SHIPPING_STEP = 2,
    PAYMENT_STEP = 3,
}
export type CheckoutEventsType = EnumValue<CheckoutEvents>;

export enum AccountEvents {
    EMAIL_SIGNUP = 'ACCOUNT.EMAIL_SIGNUP',
    SIGN_UP = 'ACCOUNT.SIGN_UP',
    SIGN_IN = 'ACCOUNT.SIGN_IN'
}
export type AccountEventsType = EnumValue<AccountEvents>;

export enum ErrorEvents {
    PAGE = 'ERROR.PAGE'
}
export type ErrorEventsType = EnumValue<ErrorEvents>;

export enum ProductEvents {
    CLICK = 'PRODUCT.CLICK',
    COUNT = 'PRODUCT.COUNT',
    IMPRESSION = 'PRODUCT.IMPRESSION',
    LIST = 'PRODUCT.LIST.VIEW',
    SEARCH = 'PRODUCT.SEARCH',
    VIEW = 'PRODUCT.VIEW',
}

export type ProductEventsType = EnumValue<ProductEvents>;

export type EventTypes =
    | string
    // APP
    | ApplicationEventsType
    | PageEventsType
    | SiteEventsType
    | OverlayEventsType
    | SectionTrackerEventsType
    // ECOMMERCE
    | BasketEventsType
    | CheckoutEventsType
    | AccountEventsType
    | ErrorEventsType
    | ProductEventsType;

export interface EventsHandler {
    get: (event: EventTypes) => undefined | number[];
    subscribe: (event: EventTypes, callback: Function) => number | undefined;
    trigger: <E extends EventTypes, A>(event: E, ...args: A[]) => void;
    unsubscribe: (event: EventTypes, handle: number) => undefined | boolean;
}
