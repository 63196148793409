import { StoreAction } from '@activebrands/core-web/state/store';

export const setApplication =
    ({ application, sites }): StoreAction =>
    dispatch => {
        dispatch({
            type: 'SET_APPLICATION',
            payload: {
                aliasId: application.site.id,
                isInitialized: true,
                locale: application.application.locale,
                sites,
                path: application.site.path,
                shopConfig: {
                    checkoutPage: application.config.dedicatedPages?.checkoutPage,
                    checkoutResponsePage: application.config.dedicatedPages?.checkoutResponsePage,
                    countries: application.countries,
                    currency: application.pricelist?.currency,
                    currencyDecimals: application.pricelist?.currency_decimal_digits,
                    marketId: application.site.market_public_id,
                    pricelistId: application.site.pricelist_public_id,
                    userCountry: process.env.REACT_APP_DEFAULT_COUNTRY,
                },
            },
        });
    };

export const setApplicationMarket =
    (marketId: string): StoreAction =>
    dispatch => {
        dispatch({
            type: 'SET_APPLICATION_MARKET',
            payload: {
                marketId,
            },
        });
    };
