//TODO: import from core when possible to use absolut imports.
const inServer = typeof window === 'undefined';

const URL_PREFIX = `/${process.env.REACT_APP_PREFIX}`;

export const getLocalePrefix = () => {
    const location = !inServer ? window.location : { host: undefined };
    return location.host === 'localhost:8000' ? '' : URL_PREFIX;
};

export const addLocalePrefix = (path: string, addTrailing = true): string => {
    const location = !inServer ? window.location : { host: undefined };
    const prefix = location.host === 'localhost:8000' ? '' : URL_PREFIX;
    const preceding = path?.charAt(0) === '/' ? '' : '/';
    const trailing = !addTrailing || path?.charAt(path.length - 1) === '/' ? '' : '/';
    return `${prefix}${preceding}${path}${trailing}`;
};
