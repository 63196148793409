import { BasketEvents, CheckoutEvents, ProductEvents } from './Events/types';

import { DPC } from '@depict-ai/dpc';
import Event from '@activebrands/core-web/libs/Events'
import { inBrowser } from '@activebrands/core-web/utils/constants';
import { useEffect } from 'react';
import useOnetrustOptInLoad from '@activebrands/core-web/hooks/useOnetrustOptInLoad'
import { useTypedSelector } from '@activebrands/core-web/state/store';
import { getDepictSessionId } from '@activebrands/core-web/libs/depict/utils';
import LocalStorage from './local-storage';

declare global {
    interface Window {
        dpc?: DPC;
    }
}

const TENANT = process.env.REACT_APP_DEPICT_TENANT ?? '';
const DEPICT_TRANSACTION_QUEUE = 'ab_queue_depict_purchase';
const DEPICT_PDP_VIEW_QUEUE = 'ab_queue_depict_pdp_view';


const parseDepictPurchaseData = (data: Record<string, any>) => ({
    'transaction_id': data.id,
    items: data.products.map((item: Record<string, any>) => ({
        'item_id': item.sku,
        'price': item.price.price,
        'quantity': item.quantity,
    })),
    'currency': data.currencyCode,
});

export const queueDepictPurchase = (data: Record<string, any>) => {
    if (data) {
        LocalStorage.set(DEPICT_TRANSACTION_QUEUE, parseDepictPurchaseData(data));
    }
};

export const queueDepictProductView = (data: Record<string, any>) => {
    if (data?.product?.sku) {
        LocalStorage.set(DEPICT_PDP_VIEW_QUEUE, { product: { sku: data.product.sku} });
    }
};

const DepictAI = () => {
    if (!TENANT || !inBrowser) {
        return null;
    }

    const { adStorage } = useOnetrustOptInLoad();
    const marketId = useTypedSelector(state => state.application.shopConfig.marketId);

    const depictProductView = (data: Record<string, any>) => {
        if (window?.dpc && data) {
            window.dpq('setProductId', `${data.product.sku}`);
        }
    };

    const depictAddToCart = (data: Record<string, any>) => {
        if (window?.dpc && data) {
            window.dpq('addToCart', `${data.product.sku}`);
        }
    };

    const depictPurchase = (data: Record<string, any>) => {
        if (window?.dpc && data) {
            window.dpq('purchase', parseDepictPurchaseData(data));
        }
    };

    const queues = {
        [DEPICT_TRANSACTION_QUEUE]: depictPurchase,
        [DEPICT_PDP_VIEW_QUEUE]: depictProductView,
    }

    useEffect(() => {
        if (!marketId) {
            return;
        }

        window.dpc = new DPC(TENANT, { market: marketId });

        const depictProductViewHandle = Event.subscribe(ProductEvents.VIEW, depictProductView);
        const depictAddToCartHandle = Event.subscribe(BasketEvents.PRODUCT_ADDED, depictAddToCart);
        const depictPurchaseHandle = Event.subscribe(CheckoutEvents.TRANSACTION, depictPurchase);

        // Handle if consent is given or not
        if (adStorage) {
            window.dpc.setSessionId(getDepictSessionId());
        } else {
            window.dpc.setSessionId('anonymous');
        }

        Object.entries(queues).forEach(([key, Func]) => {
            const queuedEventData = LocalStorage.get(key, null);

            if (queuedEventData) {
                try {
                    LocalStorage.remove(key);
                    Func(queuedEventData);
                } catch (error) {
                    console.error('Error while processing queued event', error);
                }
            }
        });

        return () => {
            depictProductViewHandle && Event.unsubscribe(ProductEvents.VIEW, depictProductViewHandle);
            depictAddToCartHandle && Event.unsubscribe(BasketEvents.PRODUCT_ADDED, depictAddToCartHandle);
            depictPurchaseHandle && Event.unsubscribe(CheckoutEvents.TRANSACTION, depictPurchaseHandle);
        };
    }, [marketId, adStorage]);

    return  null;
};

export default DepictAI;
