import { minBreakpoints } from '@activebrands/core-web/config/breakpoints';
import { generateSrcRegExp, generateSrcSetRegExp } from '@activebrands/core-web/utils/create-regExp';
import { ImgixType, StormType } from './types';

const mediaCondition = (minWidth: string, size: string) => `(min-width: ${minWidth}) ${size}`;

/**
 *
 * @param {string[]} widths - Array of widths specified in unit
 * @param {string[]} [breakpoints] = Array of breakpoints. Defaults to theme breakpoints.
 * @returns {string} - String with media queries based on widths and breakpoints.
 *
 * @example
 *      generateSizes(['50vw', null, '25vw'], ['375px', '768px', '1024px']);
 *      '(min-width: 1024px) 25vw, 50vw'
 *
 */
export const generateSizes = (widths: string[], breakpoints: (string | number)[] = minBreakpoints) => {
    const result = [];

    if (!widths || !widths.length) {
        return null;
    }

    if (!Array.isArray(widths)) {
        return widths;
    }

    for (let i = 0; i < widths.length; i++) {
        const width = widths[i];

        if (width === null || width === undefined) {
            continue;
        }

        result.push(!result.length ? width : mediaCondition(`${breakpoints[i]}px`, width));
    }

    return result.reverse().join(', ');
};

/**
 *
 * @param {string} url - URL to concat with query strings.
 * @param {Object} params - Object with param options.
 * @returns {string} - URL with query strings.
 *
 * @example
 *      generateSrc('img.jpg', { width: 200 });
 *      'img.jpg?width=200'
 *
 */
export const generateSrc = (url: string, params: StormType | ImgixType) => {
    let src = url;

    if (src && params) {
        let prefix = src?.indexOf('?') > -1 ? '&' : null;

        for (const param in params) {
            const key = param;
            const rawValue = (params[param] || '').toString();
            const value = rawValue.indexOf('#') === 0 ? rawValue.slice(1) : rawValue;

            if (value) {
                if (src.indexOf(`${key}=`) > -1) {
                    src = src.replace(generateSrcRegExp(key), `${key}=${value}`);
                } else {
                    // Since we dont have an index in this loop prefix starts with null and displays an ?. For remaning prefix is set to &
                    // If value starts with # it's a hex color, remove # since it's not valid in queryparams.
                    src = `${src}${(prefix = prefix ? '&' : '?')}${key}=${value}`;
                }
            }
        }
    }

    return src;
};

/**
 *
 * @param {string} url - URL to append width query string and height if supplied.
 * @param {number[]} widths - Range of different image widths
 * @param {number[]} [heights] - Range of different image heights, cannot be greater than widths.
 *
 * @example
 *      generateSrcSet('img.jpg', [100, 200], [100]);
 *      'img.jpg?w=100&h=100 100w, img.jpg?w=200 200w'
 *
 */
export const generateSrcSet = (url: string, widths: number[], heights: number[] = []) => {
    if (url) {
        const prefix = url.indexOf('?') > -1 ? '&' : '?';

        let src = url.endsWith('?') ? url.slice(0, -1) : url;

        // remove w and h query param from src if exsist
        src = src.replace(generateSrcSetRegExp(heights), '$1');

        return widths
            .map((width, index) => {
                const height = heights[index];
                let url = src + prefix + 'w=' + width;

                if (height) {
                    url += '&h=' + height;
                }

                return url + ' ' + width + 'w';
            })

            .join(', ');
    }
};

/**
 *
 * @param {string} url - URL to an imgix image
 * @param {Object} params - Format options
 *
 * @example
 *      toImgixSrc('img.jpg', { format: 'jpg', width: 200 });
 *      'img.jpg?fm=jpgw=200'
 *
 */
export const toImgixSrc = (url: string, params: ImgixType) => generateSrc(url, params);

/**
 *
 * @param {string} url - URL to an storm image
 * @param {Object} params - Format options
 *
 * @example
 *      toStormSrc('img.jpg', { mode: 'pad', width: 200 });
 *      'img.jpg?mode=padw=200'
 *
 */
export const toStormSrc = (url: string, params: StormType) => generateSrc(url, params);
