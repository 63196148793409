declare global {
    interface Window {
        OnetrustActiveGroups: string;
    }
}

export interface oneTrustConsent {
    adStorage: boolean
    analyticsStorage: boolean
    necessaryConsent: boolean
    performanceConsent: boolean
    functionalConsent: boolean
    targetingConsent: boolean
    socialMediaConsent: boolean
}

// Strictly Necessary Cookies: "C0001"
// Performance Cookies: "C0002"
// Functional Cookies: "C0003"
// Targeting Cookies: "C0004"
// Social Media Cookies: "C0005"
const checkScriptConsent = (groups: string[]): oneTrustConsent => ({
    adStorage: groups.includes('C0004'),
    analyticsStorage: groups.includes('C0002'),
    necessaryConsent: true,
    performanceConsent: groups.includes('C0002'),
    functionalConsent: groups.includes('C0003'),
    targetingConsent: groups.includes('C0004'),
    socialMediaConsent: groups.includes('C0005'),
});

export const getOneTrustConsent = () => {
    const consentGroups = window?.OnetrustActiveGroups?.split(',') || [];
    return checkScriptConsent(consentGroups);
};
