/* eslint-disable camelcase */

import { Redirect } from '@activebrands/core-server/types/grebcommerce';
import { Route } from '@activebrands/core-server/types/nodes';
import getCookiePath from '@activebrands/core-web/utils/get-cookie-path';
import axios from 'axios';
import Cookies from 'js-cookie';
import { logger } from './logger';
import { randomKey } from './string';

const loadRoutes = process.env.IS_SITE_SELECTOR !== 'true';

export const getRoutes = async (skip = 0, take = 100) => {
    console.info('Fetching routes', skip, '-', take + skip - 1);
    return await axios.get(
        `${process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL}/gatsby/site/${process.env.REACT_APP_ALIAS_ID}/routes`,
        {
            params: {
                authorization: process.env.GREBCOMMERCE_AUTHORIZATION_KEY,
                skip,
                take,
            },
        }
    );
};

export const resolveRoutes = async (parallel = 1) => {
    if (!loadRoutes) {
        return [];
    }

    const take = 100;
    let skip = 0;
    let lastLength;
    let routes: Route[] = [];

    do {
        const promises: Promise<any>[] = [];
        for (let i = 0; i < parallel; i++) {
            promises.push(getRoutes(skip, take));
            skip += take;
        }

        for (const promise of promises) {
            const { data } = await promise;
            if (!data?.data) {
                break;
            }

            routes = routes.concat(data.data);
            lastLength = data.data.length;
        }
    } while (lastLength > 0);

    // We filter out TeamStore routes because they are fetched dynamically instead.
    return routes.filter(route => !route.data.custom_attributes?.attr_teamwear_team_code);
};

export const resolveRedirects = async () => {
    let redirects: Redirect[] = [];

    const alias = process.env.IS_SITE_SELECTOR === 'true' ? 'global' : process.env.REACT_APP_ALIAS_ID;

    const { data } = await axios.get(
        `${process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL}/gatsby/site/${alias}/redirects`,
        {
            params: {
                authorization: process.env.GREBCOMMERCE_AUTHORIZATION_KEY,
            },
        }
    );

    if (data?.data) {
        const dbRedirectData = data?.data;
        redirects = dbRedirectData.map(dbRedirect => ({
            id: dbRedirect.id,
            from: dbRedirect.from,
            to: dbRedirect.to,
            status: dbRedirect.permanent ? 301 : 302,
        }));
    }

    return redirects;
};

export const resolveSiteSource = async () => {
    const result = await axios.get(
        `${process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL}/gatsby/site/${process.env.REACT_APP_ALIAS_ID}/resolve`,
        {
            params: {
                authorization: process.env.GREBCOMMERCE_AUTHORIZATION_KEY,
            },
        }
    );

    return result.data.data;
};

export const getUserCountryCode = async () => {
    try {
        const result = await axios.get(`${process.env.REACT_APP_CURRENT_CUSTOMER_LOCATION_PATH}`);

        if (result && result.status === 200) {
            return result.data?.countryCode;
        } else {
            console.error("Couldn't get countryCode");
        }
    } catch (error) {
        return null;
    }
};

export interface GetUserParameters {
    user_country_code?: string;
    user_selected_country?: string;
}

export const getUser = async (parameters: GetUserParameters) => {
    try {
        const result = await axios.get(
            `${process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL}/content/resolve/user-ecommerce/`,
            { params: parameters }
        );

        return result.data;
    } catch (error) {
        return null;
    }
};

export const getSitemap = async (aliasId: number) => {
    const result = await axios.get(`${process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL}/sitemap.xml`, {
        params: {
            alias_id: aliasId,
        },
    });

    return result.data;
};

export const resolveCampaignSite = async (campaign: string) => {
    try {
        const result = await axios.get(
            `${process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL}/content/resolve-campaign-site?campaign_site=${campaign}`
        );

        return result.data;
    } catch (error) {
        return null;
    }
};

export const getDepictSessionId = () => {
    const path = getCookiePath();
    const cookie = process.env.REACT_APP_DEPICT_SESSION_ID_COOKIE!;
    const value = Cookies.get(cookie);

    if (value) {
        return value;
    }
    const sessionId = randomKey();

    Cookies.set(cookie, sessionId, { expires: 30, path });

    return sessionId;
};

export const getDepictProducts = async ({ marketId, pricelistId, productIds, siteId, type }) => {
    if (!marketId) throw new Error("Missing marketId");
    if (!pricelistId) throw new Error("Missing pricelistId");
    if (!productIds || (Array.isArray(productIds) && productIds.length === 0)) {
        throw new Error("Missing or invalid productIds");
    }
    if (!siteId) throw new Error("Missing siteId");
    if (!type) throw new Error("Missing type");

    const basePayload = {
        market: marketId,
        session_id: getDepictSessionId(),
        type: type,
        tenant: process.env.REACT_APP_DEPICT_TENANT,
    };

    try {
        const isMultipleProducts = Array.isArray(productIds);
        const endpoint = isMultipleProducts
            ? `${process.env.REACT_APP_DEPICT_BASE_REQUEST_URL}/products/products`
            : `${process.env.REACT_APP_DEPICT_BASE_REQUEST_URL}/products/product`;

        const payload = isMultipleProducts
            ? { ...basePayload, product_ids: productIds }
            : { ...basePayload, product_id: productIds };

        const response = await axios.post(endpoint, payload);

        if (response.status !== 200) {
            throw new Error(
                `Unexpected status code ${response.status}: ${response.statusText}`
            );
        }
        if (!response.data) {
            throw new Error("API response is empty or invalid");
        }

        return response.data;
    } catch (error) {
        logger.error('Error fetching products from Depict', {
            errorMessage: (error as Error).message,
            stack: (error as Error).stack,
        });

        return null;
    }
};
