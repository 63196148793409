export type MobileBreakpointLabels = 'mobile.sm' | 'mobile.md' | 'mobile.lg';
export type TabletBreakpointLabels = 'tablet.sm' | 'tablet.lg';
export type DesktopBreakpointLabels = 'desktop.sm' | 'desktop.md' | 'desktop.lg';
export type ValidBreakpointLabels = MobileBreakpointLabels | TabletBreakpointLabels | DesktopBreakpointLabels;

export interface IBreakpoint {
    label: ValidBreakpointLabels;
    from: number;
    to: `${string}${'px' | 'em' | 'vw'}` | number;
}

// Refers to the breakpoint decided here:
// https://www.notion.so/grebban/Breakpoints-cc8a13ef3c494abeb8b1acce9e2ea13f

export const breakpoints: IBreakpoint[] = [
    { label: 'mobile.sm', from: 0, to: 359 },
    { label: 'mobile.md', from: 360, to: 767 },
    { label: 'mobile.lg', from: 768, to: 1023 },
    { label: 'tablet.sm', from: 1024, to: 1279 },
    { label: 'tablet.lg', from: 1280, to: 1365 },
    { label: 'desktop.sm', from: 1366, to: 1535 },
    { label: 'desktop.md', from: 1536, to: 1919 },
    { label: 'desktop.lg', from: 1920, to: '100vw' }, // 1920px-infinite
];

export const breakpointLabels = breakpoints.map(breakpoint => breakpoint.label);
export const breakpointValues = breakpoints.map(breakpoint => ({ from: breakpoint.from, to: breakpoint.to }));
export const minBreakpoints = breakpoints.map(breakpoint => breakpoint.from);
export const maxBreakpoints = breakpoints.map(breakpoint => breakpoint.to);

export const getBreakpoint = (breakpointLabel: ValidBreakpointLabels): IBreakpoint | null => {
    for (const index in breakpoints) {
        const bp = breakpoints[index];
        if (bp.label === breakpointLabel) {
            return bp;
        }
    }

    if (process.env.APP_DEBUG_ENABLED === 'true') {
        console.warn(`The breakpoint label ${breakpointLabel} does not exist.`);
    }
    return null;
};
