import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import useSiteMetaQuery from 'queries/useSiteMetaQuery';
import OrganizationStructuredData from '@activebrands/core-web/components/seo-meta/structured-data/OrganizationStructuredData';
import SiteSearchStructuredData from '@activebrands/core-web/components/seo-meta/structured-data/SiteSearchStructuredData';
import { generateSrc } from '@activebrands/core-web/libs/image';
import { addLocalePrefix } from '@activebrands/core-web/utils/add-locale-prefix';
import { getOptimalContentImageSrc } from '@activebrands/core-web/utils/content-meta-transformers';
import { formatMetaDescription } from '@activebrands/core-web/utils/format-meta-description';
import parseProductName from '@activebrands/core-web/utils/product/parse-product-name';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';

const SEO = ({ data, location }) => {
    const { lang, title: titleSuffix, siteURL } = useSiteMetaQuery();

    const isHomePage = `${location.pathname}/`.replace('//', '/') === `/${process.env.REACT_APP_PREFIX}/`;
    const isProduct = !!data.sku;

    const content = data.content || {};
    let cmsMeta = content.seo?.[0] || {};
    const productMeta = data.seo || {};

    const { canonical: cmsCanonical, index: cmsIndex, follow: cmsFollow } = cmsMeta;
    cmsMeta = cmsMeta.meta || {};
    let canonicalUrl = `${siteURL}${cmsCanonical ? addLocalePrefix(cmsCanonical) : location.pathname}`;

    const index = cmsIndex === 'no_index' ? 'noindex' : 'index';
    const follow = cmsFollow === 'no_follow' ? 'nofollow' : 'follow';

    // Extract query params from location, check if page param exist and add to canonical url
    const queryParams = extractQueryParams(location.search);
    if (queryParams?.page && queryParams?.page > 0) {
        canonicalUrl += `?page=${queryParams?.page}`;
    }

    const hrefLang =
        data.hrefs?.map(alternate => ({
            rel: 'alternate',
            hreflang: alternate.href_lang || alternate.hrefLang,
            href: alternate.uri,
        })) || [];

    let title = cmsMeta.title || data.name;
    let description = cmsMeta.description || null;

    const ogType = isProduct ? 'product' : 'website';

    if (isProduct) {
        title = parseProductName(productMeta.title, data.color);
        description = productMeta.description || null;
    }

    if (description) {
        description = formatMetaDescription(description);
    }

    const contentImageSrc = getOptimalContentImageSrc(content);
    const ogImage = generateSrc(cmsMeta.og_image || contentImageSrc, { w: 1200, h: 630, auto: 'format', q: 75 });
    const ogTwitterImage = generateSrc(cmsMeta.twitter_image || cmsMeta.og_image || contentImageSrc, {
        w: 1200,
        h: 675,
        auto: 'format',
        q: 75,
    });

    let metaTags = [
        {
            name: 'robots',
            content: `${index}, ${follow}`,
        },
        {
            name: 'og:type',
            content: ogType,
        },
        {
            name: 'description',
            content: description,
        },
        {
            property: 'og:title',
            content: cmsMeta.og_title || title,
        },
        {
            property: 'og:description',
            content: cmsMeta.og_description || description,
        },
        {
            property: 'og:image',
            content: ogImage || null,
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'twitter:card',
            content: 'summary',
        },
        {
            property: 'twitter:title',
            content: cmsMeta.twitter_title || title,
        },
        {
            property: 'twitter:description',
            content: cmsMeta.twitter_description || description,
        },
        {
            property: 'twitter:twitter_image',
            content: ogTwitterImage || null,
        },
    ];

    if (isProduct) {
        metaTags = [
            ...metaTags,
            {
                name: 'product:price.amount',
                content: data.price?.salePrice,
            },
            {
                name: 'product:price.currency',
                content: data.price?.currency,
            },
            {
                name: 'product:condition',
                content: 'new',
            },
            {
                name: 'product:availability',
                content: data.variations?.some(v => v.inStock) ? 'in stock' : 'out of stock',
            },
        ];
    }

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                link={[
                    { rel: 'canonical', href: canonicalUrl },
                    { rel: 'alternate', href: process.env.APP_DOMAIN, hrefLang: 'x-default' },
                    ...hrefLang,
                ]}
                meta={metaTags.filter(tag => tag.content)}
                title={`${title}`}
                titleTemplate={titleSuffix ? `%s | ${titleSuffix}` : null}
            />
            <OrganizationStructuredData />
            {isHomePage && <SiteSearchStructuredData />}
        </>
    );
};

SEO.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export default SEO;
