export const CheckoutTracking = {
    VIEW_CART_STEP: 1,
    SHIPPING_STEP: 2,
    PAYMENT_STEP: 3,
};

export const TrackingLists = {
    BASKET_PRODUCTS: 'Basket Products',
    CHECKOUT_LIST: 'Checkout Product',
    PRODUCT_GALLERY: 'Product Gallery',
    PRODUCT_GRID: 'Category Page',
    PRODUCT_PAGE: 'Product Page',
    SHOP_THE_LOOK: 'Shop The Look',
    UPPSELL_PRODUCTS: 'Upsell Products',
    RELATED_PRODUCTS: 'Related Products',
    RECOMENDED_PRODUCTS: 'Recomended Products',
    HEADER_SEARCH: 'Header',
};
