import { TeamStoreActionTypes, TeamStoreBaseState } from './types';

const initialState: TeamStoreBaseState = {
    deliveryWeek: '',
    orderingStartDate: '',
    orderingEndDate: '',
    products: [],
    variantSkus: [],
};

export default (state = initialState, action: TeamStoreActionTypes) => {
    if (action.type === 'SET_TEAM_STORE_DATA') {
        return {
            ...state,
            ...action.payload,
        };
    }

    return state;
};
