import getRecursiveCategories from '@activebrands/core-web/utils/get-recursive-categories';
import { logger } from '@activebrands/core-web/utils/logger';
import { formatCentraFeedId } from '@activebrands/core-web/utils/tracking';

class DataLayer {
    constructor() {
        this.intervalHandler = null;
        this.queue = [];
    }

    handler() {
        if (this.intervalHandler === null) {
            this.intervalHandler = setInterval(() => {
                if (this.isLoaded()) {
                    clearInterval(this.intervalHandler);
                    this.processQueue();
                }
            }, 100);
        }
    }

    track(data = {}) {
        if (window.google_tag_manager === undefined) {
            this.queue.push(() => this.process(data));
            this.handler();
        } else {
            this.process(data);
        }
    }

    process(data) {
        try {
            window.dataLayer.push(data);
        } catch (e) {
            logger.error('gtm error');
        }
    }

    processQueue() {
        while (this.queue.length > 0) {
            this.queue.shift()();
        }
    }

    isLoaded() {
        return window.google_tag_manager !== undefined;
    }

    reset() {
        const gtmTrackingKey = process.env.REACT_APP_GTM_TRACKING_KEY;

        if (window.google_tag_manager === undefined) {
            this.queue.push(() => {
                window.google_tag_manager[gtmTrackingKey].dataLayer.reset();
            });
            this.handler();
        } else {
            window.google_tag_manager[gtmTrackingKey].dataLayer.reset();
        }
    }

    basketItems(items, country) {
        return items.map(item => this.basketItem(item, country));
    }

    basketItem(item, country, quantity) {
        const centraId = formatCentraFeedId({
                silkProductId: item.parentId,
                silkVariantId: item.variantId,
                silkItemId: item.variationId.split('-')[1]
            },
            country,
        );

        return {
            id: centraId,
            sku: item.sku,
            name: item.productName,
            categories: item.productReference?.categories,
            price: { price: item.priceEachAsNumber || item.originalPrice || item.priceEachWithoutTaxAsNumber },
            quantity: quantity ? Math.abs(quantity) : item.quantity || item.qty,
            size: item.size,
        };
    }

    getProducts(items) {
        return items.map(item => this.getProduct(item));
    }

    getProduct(product) {
        const { id, anyDiscount, categories = [], name, position, price = {}, quantity, size, sku, trackingList } = product;

        const splitSku = sku?.match(/(.*_[^-]*)/);
        const layerProduct = {
            id,
            name,
            sku,
        };

        if (categories.length > 0) {
            layerProduct.category = getRecursiveCategories(categories[0]).slice(0, -1);
        }

        if (trackingList) {
            layerProduct.list = trackingList;
        }

        if (typeof position !== 'undefined') {
            layerProduct.position = parseInt(position, 10) + 1;
        }

        if (price.price || price.salePrice) {
            const priceToUse = price.price || price.priceExVat;
            const saleToUse = price.salePrice || price.salePriceExVat;

            layerProduct.price = saleToUse ? Math.min(priceToUse, saleToUse) : priceToUse;
        }

        if (quantity) {
            layerProduct.quantity = quantity;
        }

        if (size) {
            layerProduct.variant = size;
        }

        if (anyDiscount) {
            layerProduct.coupon = anyDiscount;
        }

        return layerProduct;
    }
}

const instance = new DataLayer();

export default instance;
