import DataLayer from './DataLayer';
import Gtm from '@grebban/gtm';
import type { Product } from '@activebrands/core-web/types/tracking-events';

export default ({ currencyCode, product, trackingList }: {
    currencyCode: string;
    product: Product;
    trackingList: string;
}) => {
    Gtm.addDataLayerEvent('product_click', {
        ecommerce: {
            currencyCode,
            click: {
                actionField: { list: trackingList },
                products: [DataLayer.getProduct(product)],
            },
        },
    });
};
