import { UserActionTypes, UserState } from '@activebrands/core-web/state/user/types';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

const initialState: UserState = {
    consent: {},
    accessibility: {},
    countryCode: '',
    selectedCountryCode: '',
};

export default (state = initialState, action: UserActionTypes) => {
    switch (action.type) {
        case 'RESOLVE_USER_SUCCESS':
            return {
                ...initialState,
                ...objectKeysToCamelCase(action.payload),
            };
        case 'RESOLVE_USER_ERROR':
            return { ...initialState };
        default:
            return state;
    }
};
