import { BasketActionTypes, BasketState } from '@activebrands/core-web/state/basket/types';

const initialState: BasketState = {
    address: {},
    callback: undefined,
    centraCheckoutScript: '',
    changed: undefined,
    cloudflareTurnstileStatus: 'not tested',
    country: '',
    currency: '',
    currencyFormat: undefined,
    id: undefined,
    isFetching: false,
    items: [],
    marketplacePublicId: '',
    paymentCallback: undefined,
    paymentMethodId: undefined,
    paymentMethods: [],
    shippingAddress: {},
    shippingMethodId: '',
    shippingMethods: {},
    totalDiscount: undefined,
    totals: undefined,
};

export default (state = initialState, action: BasketActionTypes) => {
    switch (action.type) {
        case 'ADD_BASKET_INFORMATION':
        case 'ADD_BASKET_ITEM':
        case 'ADD_BUNDLE_ITEM':
        case 'ADD_EXTERNAL_VOUCHER':
        case 'ADD_VOUCHER':
        case 'BASKET_PAYMENT_CALLBACK':
        case 'CREATE_BASKET':
        case 'GET_BASKET':
        case 'GET_PAYMENT_METHOD':
        case 'REMOVE_BASKET_ITEM':
        case 'REMOVE_VOUCHER':
        case 'RESET_BASKET':
        case 'SELECT_PAYMENT_METHOD':
        case 'SET_BASKET_MARKET':
        case 'UPDATE_BASKET_ITEM':
            return {
                ...state,
                isFetching: true,
            };
        case 'ADD_BASKET_INFORMATION_ERROR':
        case 'ADD_BASKET_ITEM_ERROR':
        case 'ADD_BUNDLE_ITEM_ERROR':
        case 'ADD_EXTERNAL_VOUCHER_ERROR':
        case 'ADD_VOUCHER_ERROR':
        case 'CREATE_BASKET_ERROR':
        case 'GET_BASKET_ERROR':
        case 'GET_PAYMENT_METHOD_ERROR':
        case 'REMOVE_BASKET_ITEM_ERROR':
        case 'REMOVE_VOUCHER_ERROR':
        case 'RESET_BASKET_ERROR':
        case 'SELECT_PAYMENT_METHOD_ERROR':
        case 'SET_BASKET_MARKET_ERROR':
        case 'SET_BASKET_MARKET_SUCCESS':
        case 'UPDATE_BASKET_ITEM_ERROR':
            return {
                ...state,
                isFetching: false,
            };
        case 'ADD_BASKET_ITEM_SUCCESS':
        case 'ADD_BUNDLE_ITEM_SUCCESS':
        case 'ADD_EXTERNAL_VOUCHER_SUCCESS':
        case 'ADD_VOUCHER_SUCCESS':
        case 'REMOVE_BASKET_ITEM_SUCCESS':
        case 'REMOVE_VOUCHER_SUCCESS':
        case 'UPDATE_BASKET_ITEM_SUCCESS':
            return {
                ...state,
                isFetching: false,
                items: action.payload.items,
                totalDiscount: action.payload.totalDiscount,
                totals: action.payload.totals,
                shippingMethods: action.payload.shippingMethods,
            };
        case 'ADD_BASKET_INFORMATION_SUCCESS':
        case 'CREATE_BASKET_SUCCESS':
        case 'GET_BASKET_SUCCESS':
            return {
                ...state,
                address: action.payload.address,
                shippingAddress: action.payload.shippingAddress,
                centraCheckoutScript: action.payload.centraCheckoutScript,
                country: action.payload.country,
                currency: action.payload.currency,
                currencyFormat: action.payload.currencyFormat,
                id: action.payload.id,
                isFetching: false,
                items: action.payload.items,
                marketplacePublicId: action.payload.marketplacePublicId,
                paymentMethodId: action.payload.paymentMethodId,
                paymentMethods: action.payload.paymentMethods,
                shippingMethodId: action.payload.shippingMethodId,
                shippingMethods: action.payload.shippingMethods,
                totalDiscount: action.payload.totalDiscount,
                totals: action.payload.totals,
            };
        case 'SELECT_SHIPPING_METHOD_SUCCESS':
            return {
                ...state,
                isFetching: false,
                totals: action.payload.totals,
                shippingMethodId: action.payload.shippingMethodId,
            };
        case 'GET_PAYMENT_METHOD_SUCCESS':
            return {
                ...state,
                isFetching: false,
                paymentCallback: action.payload,
            };
        case 'GET_PAYMENT_METHOD_WARNING':
            return {
                ...state,
                isFetching: false,
                items: action.payload.basket.items,
                totalDiscount: action.payload.basket.totalDiscount,
                totals: action.payload.basket.totals,
                changed: action.payload.changed,
            };
        case 'SELECT_PAYMENT_METHOD_SUCCESS':
            return {
                ...state,
                isFetching: false,
                paymentMethodId: action.payload.paymentMethodId,
                centraCheckoutScript: action.payload.centraCheckoutScript,
            };
        case 'BASKET_PAYMENT_CALLBACK_SUCCESS':
            return {
                ...state,
                isFetching: false,
                callback: action.payload,
            };
        case 'BASKET_PAYMENT_CALLBACK_ERROR':
            return {
                ...state,
                isFetching: false,
                callback: {
                    ...state.callback,
                    error: action.payload,
                },
            };
        case 'CLEAR_PAYMENT_CALLBACK':
            return {
                ...state,
                paymentCallback: undefined,
            };
        case 'SET_CLOUDFLARE_TURNSTILE_STATUS':
            return {
                ...state,
                cloudflareTurnstileStatus: action.payload,
            };
        case 'RESET_BASKET_SUCCESS':
            return initialState;
        default:
            return state;
    }
};
