import type { FacetOptionCountryCode, FacetOptionSite } from '@activebrands/core-web/libs/storyblok/storyblok';

interface HandleVisibilitySettingsProps {
    items: Array<any>;
    countryCode?: string;
    aliasId: string;
}

/**
 * Handles the rules and filters InfoBoxes based on visibility settings.
 *
 * @param {HandleVisibilitySettingsProps} props - The component's properties.
 * @property {Array<any>} items - An array of objects.
 * @property {string} countryCode - The selected countryCode.
 * @property {string} aliasId - The site aliasId .
 * @returns {Array<object>} An array of filtered items with attribute show.
 */

const handleVisibilitySettings = ({ items, countryCode, aliasId }: HandleVisibilitySettingsProps) =>
    items.map(({ visibilitySettings, ...rest }) => {
        let isAliasIdMatched = true;
        let isCountryMatched = true;

        visibilitySettings?.forEach(setting => {
            if (setting.component === 'facetOption_country_code') {
                const restricTo = setting.restricTo as FacetOptionCountryCode;
                isCountryMatched = restricTo.some(code => code == countryCode);
            }

            if (setting.component === 'facetOption_site') {
                const restricTo = setting.restricTo as FacetOptionSite;
                isAliasIdMatched = restricTo.data.id == aliasId;
            }
        });

        return { ...rest, show: isAliasIdMatched && isCountryMatched };
    });

export default handleVisibilitySettings;
