import { useEffect, useRef } from 'react';
import { getPageLoadTime } from '@activebrands/core-web/utils/timings';

export const useDidUpdate = (callback: Function, deps?: any[]) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            callback();
        }
    }, deps);
};

export const useWillUnmount = (callback: Function) => {
    useEffect(() => () => callback(), []);
};

export const usePageLoad = (callback: (time: number) => void) => {
    // getPageLoadTime sometimes gives 0 on hard page reload.
    // Loop until we get a real value.
    const calculate = () => {
        const pageLoad = getPageLoadTime();

        if (pageLoad === 0) {
            requestAnimationFrame(calculate);
        } else {
            callback(pageLoad);
        }
    };

    useEffect(() => {
        requestAnimationFrame(calculate);
    }, []);
};
