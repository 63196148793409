import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import useEvent from '@activebrands/core-web/libs/Events/useEvent';
import useScrollLock from '@activebrands/core-web/libs/scroll';
import { styled } from '@activebrands/core-web/libs/styletron';
import { getBreakpointIndex } from '@activebrands/core-web/utils/responsive';

const Wrapper = styled('aside', {
    position: 'fixed',
    color: 'var(--color-text)',
    animationTimingFunction: 'var(--ease)',
    animationDuration: 'var(--duration-fast)',
    animationFillMode: 'forwards',

    '--start-bottom': 'translate(0, 100%)',
    '--start-left': 'translate(-100%, 0)',
    '--start-right': 'translate(100%, 0)',
    '--start-middle': 'translate(0, 0)',
    '--in-view': 'translate(0, 0)',
    '--in-middle': 'translate(-50%, -50%)',

    // These animate from the bottom in mobile
    '--from-rtl': 'var(--start-bottom)',
    '--from-ltr': 'var(--start-bottom)',

    [media.min['desktop.sm']]: {
        '--from-rtl': 'var(--start-right)',
        '--from-ltr': 'var(--start-left)',
    },
});

const animations = {
    slideRTL: {
        transform: 'var(--from-rtl)',
        animationFillMode: 'forwards',
        animationName: [
            {
                from: {
                    transform: 'var(--from-rtl)',
                },
                to: {
                    transform: 'var(--in-view)',
                },
            },
            {
                from: {
                    transform: 'var(--in-view)',
                },
                to: {
                    transform: 'var(--from-rtl)',
                },
            },
        ],
        bottom: 'var(--height-header)',
        top: 'auto',
        right: 0,
    },
    slideLTR: {
        transform: 'var(--from-ltr)',
        animationFillMode: 'forwards',
        animationName: [
            {
                from: {
                    transform: 'var(--from-ltr)',
                },
                to: {
                    transform: 'var(--in-view)',
                },
            },
            {
                from: {
                    transform: 'var(--in-view)',
                },
                to: {
                    transform: 'var(--from-ltr)',
                },
            },
        ],
        bottom: 'var(--height-header)',
        top: 'auto',
        left: 0,
    },
    slideBTT: {
        transform: 'var(--start-bottom)',
        animationFillMode: 'forwards',
        animationName: [
            {
                from: {
                    transform: 'var(--start-bottom)',
                },
                to: {
                    transform: 'var(--in-view)',
                },
            },
            {
                from: {
                    transform: 'var(--in-view)',
                },
                to: {
                    transform: 'var(--start-bottom)',
                },
            },
        ],
        bottom: 0,
        top: 'auto',
        left: 0,
    },
    slideBTM: {
        transform: 'var(--in-middle)',
        animationFillMode: 'forwards',

        animationName: [
            {
                from: {
                    opacity: '0',
                },
                to: {
                    opacity: '1',
                },
            },
            {
                from: {
                    opacity: '1',
                },
                to: {
                    opacity: '0',
                },
            },
        ],
        left: '50%',
    },
};

const AnimatedOverlay = ({
    $style = {},
    animation = 'slideRTL',
    animationDuration = 'var(--duration-fast)',
    component,
    id,
    isOpen = false,
    isRendered = false,
    lockScroll: lockPageScroll = false,
    ...rest
}) => {
    const [state, setState] = useState({
        isOpen,
        animate: isOpen,
        isRendered,
        props: undefined,
    });

    const { lockScroll, unlockScroll } = useScrollLock();

    const handleScrollLock = useCallback(
        (lock = true) => {
            if (lockPageScroll) {
                if (Array.isArray(lockPageScroll)) {
                    const arrLock = lockPageScroll.reduce((a, c, i) => {
                        if (c === null) {
                            return [...a, a[i - 1]];
                        }

                        return [...a, c];
                    }, []);

                    if (!arrLock[getBreakpointIndex()]) {
                        return;
                    }
                }

                lock ? lockScroll() : unlockScroll();
            }
        },
        [(lockPageScroll || '').toString()]
    );

    useEvent(`OVERLAY.OPEN_${id}`, props => {
        handleScrollLock(true);
        setState(prev => ({ ...prev, props, animate: true, isOpen: true, isRendered: true }));
    });

    useEvent(`OVERLAY.CLOSE_${id}`, () => {
        handleScrollLock(false);
        setState(prev => ({ ...prev, isOpen: false }));
    });

    const Overlay = component || state.props?.component;

    if (!state.isRendered || !Overlay) {
        return null;
    }

    const animationStyle =
        animation && animations[animation]
            ? {
                  ...animations[animation],
                  animationName: animations[animation].animationName[state.isOpen ? 0 : 1],
                  animationDuration: animationDuration,
              }
            : {};

    if (!state.animate) {
        // styletron dosn't handle null or undefined on animationName so we need to delete it.
        delete animationStyle.animationName;
    }

    const onAnimationEnd = state.isOpen
        ? undefined
        : () => setState(prev => ({ ...prev, props: undefined, isRendered }));

    return (
        <Wrapper
            $style={{
                ...animationStyle,
                zIndex: 'var(--zindex-overlay)',
                top: ['auto', null, null, null, null, 'var(--height-header)'],
                height: ['auto', null, null, null, null, '100%'],
                ...$style,
            }}
            onAnimationEnd={onAnimationEnd}
        >
            <Overlay {...rest} {...state.props} id={id} isOpen={state.isOpen} />
        </Wrapper>
    );
};

AnimatedOverlay.propTypes = {
    $style: PropTypes.object,
    animation: PropTypes.oneOf(['slideLTR', 'slideBTT', 'slideRTL', 'slideBTM']),
    animationDuration: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    isRendered: PropTypes.bool,
    lockScroll: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AnimatedOverlay;
