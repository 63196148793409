import {
    CentraItem,
    GoogleTrackingConsent,
    ProductTracking,
    TrackingCookies
} from '@activebrands/core-web/types/tracking';
import { getOneTrustConsent } from '@activebrands/core-web/utils/get-onetrust-consent';
import Cookies from 'js-cookie';

/**
 * formatProductTrackingMeta
 * Formats product tracking meta to match expected
 * parameters in the API
 *
 * @param productTracking
 */
export const formatProductTracking = (productTracking: ProductTracking): Record<string, any> => {
    return {
        brand_name: productTracking.brandName,
        ean: productTracking.ean ?? '',
        item: productTracking.item ?? '',
        product_name: productTracking.productName,
        price_each_as_number: productTracking.priceAsNumber ?? 0,
        price_each_without_tax_as_number: productTracking.priceWithoutTaxAsNumber ?? 0,
        quantity: productTracking.quantity,
        sku: productTracking.sku,
        size: productTracking.size ?? '',
        parent_id: productTracking.parentId ?? '',
        silk_variant: productTracking.silkVariant,
    };
};

export const getTrackingCookies = (): TrackingCookies => {
    let ga4SessionId: undefined|string = undefined;
    try {
        let sessionCookie = (`; ${document.cookie}`).split('; _ga_').pop() ?? '';
        sessionCookie = sessionCookie.split(';').shift() ?? '';
        ga4SessionId = sessionCookie.split('.')[2] ?? '';
    } catch (e) {
        console.error(e);
    }

    return {
        'ga4_session_id': ga4SessionId,
        '_ga': Cookies.get('_ga'),
        '_fbp': Cookies.get('_fbp'),
        '_fbc': Cookies.get('_fbc'),
    };
};

/**
 * formatBasketTrackingMeta
 * Formats the basket meta to match expected parameters in the API
 *
 * @param productTracking
 */
export const formatBasketTracking = (productTracking: ProductTracking): Record<string, any> => {
    const product = formatProductTracking(productTracking);

    return {
        'meta': {
            'url': window.location.href,
            'timestamp_micros': Date.now() * 1000,
            ...getTrackingCookies()
        },
        'consent': getGoogleTrackingConsent(),
        'product': product,
    };
};

export const formatCentraFeedId = (centraItem: CentraItem, country = ''): string => {
    const userCountry = country.length
        ? country
        : process.env.REACT_APP_DEFAULT_COUNTRY as string;

    return `${process.env.REACT_APP_CENTRA_STORE_ID ?? ""}`
    + `/${userCountry.toLowerCase()}`
    + `-p${centraItem.silkProductId ?? ""}`
    + `-v${centraItem.silkVariantId ?? ""}`
    + `-s${centraItem.silkItemId ?? ""}`;
}

/**
 * getGoogleTrackingConsent
 * Check which consents have been given to us by the user
 */
export const getGoogleTrackingConsent = (): GoogleTrackingConsent => {
    const { targetingConsent, performanceConsent } = getOneTrustConsent();

    return {
        ad_storage: targetingConsent,
        analytics_storage: performanceConsent,
    };
};
