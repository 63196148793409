import { useLayoutEffect, useState } from 'react';

const PREVENT_SCROLL_ATTRIBUTE = 'data-prevent-scroll';
const SCROLL_POSITION_KEY = 'scroll-position';

const isLocked = () => document.body.getAttribute(PREVENT_SCROLL_ATTRIBUTE) === 'true';

const useScrollLock = () => {
    const [initialScrollPosition, setInitialScrollPosition] = useState(0);

    const lockScroll = () => {
        if (!isLocked()) {
            const body = document.body;
            const scrollPosition = window.pageYOffset;
            body.style.position = 'fixed';
            body.style.overflowY = 'scroll';
            body.style.width = '100%';
            body.setAttribute(PREVENT_SCROLL_ATTRIBUTE, 'true');
            localStorage.setItem(SCROLL_POSITION_KEY, scrollPosition.toString());
            body.style.top = `-${localStorage.getItem(SCROLL_POSITION_KEY)}px`;
            setInitialScrollPosition(scrollPosition);
        }
    };

    const unlockScroll = () => {
        if (isLocked()) {
            const body = document.body;
            const scrollPosition = parseFloat(localStorage.getItem(SCROLL_POSITION_KEY)) || initialScrollPosition;
            body.style.removeProperty('position');
            body.style.removeProperty('overflowY');
            body.style.removeProperty('top');
            body.style.removeProperty('width');
            window.scrollTo({ top: scrollPosition });
            body.removeAttribute(PREVENT_SCROLL_ATTRIBUTE);
            localStorage.removeItem(SCROLL_POSITION_KEY);
        }
    };

    useLayoutEffect(() => {
        setInitialScrollPosition(window.pageYOffset);

        if (isLocked()) {
            const scrollPosition = parseFloat(localStorage.getItem(SCROLL_POSITION_KEY)) || 0;
            document.body.style.top = `-${scrollPosition}px`;
        } else {
            localStorage.removeItem(SCROLL_POSITION_KEY);
        }
    }, []);

    return { isLocked, lockScroll, unlockScroll };
};

export default useScrollLock;
