import Event from '@activebrands/core-web/libs/Events';
import {
    ApplicationEvents,
    BasketEvents,
    CheckoutEvents,
    ErrorEvents,
    PageEvents,
    ProductEvents,
    AccountEvents,
} from '@activebrands/core-web/libs/Events/types';
import BasketProductAdded from './BasketProductAdded';
import BasketProductRemoved from './BasketProductRemoved';
import CheckoutOption from './CheckoutOption';
import CheckoutView from './CheckoutView';
import Consent from './Consent';
import PageView from './PageView';
import ProductClick from './ProductClick';
import ProductImpression from './ProductImpression';
import ProductView from './ProductView';
import Reset from './Reset';
import Transaction from './Transaction';
import PaymentInfoAdded from "./PaymentInfoAdded";
import ShippingInfoAdded from "./ShippingInfoAdded";
import EmailSignup from "./EmailSignup";
import Signup from "./Signup";
import SignIn from "./SignIn";
import PageError from "./PageError";
import Search from "./Search";

export default {
    init() {
        // Application
        Event.subscribe(ApplicationEvents.CONSENT, Consent);

        // Page
        Event.subscribe(PageEvents.RESET, () => Reset());
        Event.subscribe(PageEvents.VIEW, PageView);

        // Product
        Event.subscribe(ProductEvents.VIEW, ProductView);
        Event.subscribe(ProductEvents.CLICK, ProductClick);
        Event.subscribe(ProductEvents.IMPRESSION, ProductImpression);
        Event.subscribe(ProductEvents.SEARCH, Search);

        // Basket
        Event.subscribe(BasketEvents.PRODUCT_ADDED, BasketProductAdded);
        Event.subscribe(BasketEvents.PRODUCT_REMOVED, BasketProductRemoved);

        // Checkout
        Event.subscribe(CheckoutEvents.VIEW, CheckoutView);
        Event.subscribe(CheckoutEvents.OPTION, CheckoutOption);
        Event.subscribe(CheckoutEvents.SHIPPING_INFO_ADDED, ShippingInfoAdded);
        Event.subscribe(CheckoutEvents.PAYMENT_INFO_ADDED, PaymentInfoAdded);
        Event.subscribe(CheckoutEvents.TRANSACTION, Transaction);

        // Account
        Event.subscribe(AccountEvents.EMAIL_SIGNUP, EmailSignup);
        Event.subscribe(AccountEvents.SIGN_UP, Signup);
        Event.subscribe(AccountEvents.SIGN_IN, SignIn);

        // Error
        Event.subscribe(ErrorEvents.PAGE, PageError);
    },
};
