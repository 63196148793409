class GTM {
    constructor() {
        this.intervalHandler = null;
        this.queue = [];
    }

    handler() {
        if (this.intervalHandler === null) {
            this.intervalHandler = setInterval(() => {
                if (this.isLoaded()) {
                    clearInterval(this.intervalHandler);
                    this.processQueue();
                }
            }, 100);
        }
    }

    track(data = {}) {
        if (window.google_tag_manager === undefined) {
            this.queue.push(() => this.process(data));
            this.handler();
        } else {
            this.process(data);
        }
    }

    process(data) {
        window.dataLayer.push(data);
    }

    processQueue() {
        while (this.queue.length > 0) {
            this.queue.shift()();
        }
    }

    isLoaded() {
        return window.google_tag_manager !== undefined;
    }

    reset() {
        const gtmTrackingKey = process.env.REACT_APP_GTM_TRACKING_KEY;

        if (window.google_tag_manager === undefined) {
            this.queue.push(() => {
                window.google_tag_manager[gtmTrackingKey].dataLayer.reset();
            });
            this.handler();
        } else {
            window.google_tag_manager[gtmTrackingKey].dataLayer.reset();
        }
    }

    dataLayer = {
        getProducts(items, impression = false) {
            const Products = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (impression) {
                    Products.push(this.getImpressionProduct(item, i));
                } else {
                    Products.push(this.getProduct(item, i));
                }
            }
            return Products;
        },

        getImpressionProduct(product, listPosition = null) {
            const dataProduct = [];

            dataProduct.push({
                id: product.sku,
                name: product.name,
                brand: product.brand.name,
                price: product.price.salePrice < product.price.price ? product.price.salePrice : product.price.price,
                category: product.categories.map(data => data.name).join(' | '),
            });

            if (product.list !== undefined) {
                dataProduct[0].list = product.list;
            }

            if (listPosition !== null) {
                dataProduct[0].listPosition = listPosition;
            }

            return dataProduct[0];
        },

        getProduct(product, listPosition = null) {
            const dataProduct = [];

            const variant = [];
            if (product.size) {
                variant.push(`size=${product.size}`);
            }

            if (product.color) {
                variant.push(`color=${product.color}`);
            }

            dataProduct.push({
                id: product.sku,
                name: product.product_name,
                brand: product.brand_name,
                variant: variant.join('|'),
                price: product.price_each_as_number,
            });

            if (product.quantity !== undefined) {
                dataProduct[0].quantity = product.quantity;
            }

            if (product.list !== undefined) {
                dataProduct[0].list = product.list;
            }

            if (listPosition !== null) {
                dataProduct[0].listPosition = listPosition;
            }
            return dataProduct[0];
        },
    };
}

const instance = new GTM();

export default instance;
