import { CssVariables, HEX, RGB, RGBA } from 'types/css-types';

export interface ColorCssVariablesCore extends CssVariables<RGBA | RGB | HEX> {
    '--color-palette-act-blue': RGBA | RGB | HEX;
    '--color-palette-act-white': RGBA | RGB | HEX;
    '--color-palette-act-black': RGBA | RGB | HEX;
}

export const colorsCssVariablesCore = {
    // Act Rewards
    '--color-palette-act-blue': '#015778',
    '--color-palette-act-white': '#FFFFFF',
    '--color-palette-act-black': '#000000',
};
