import getCookiePath from '@activebrands/core-web/utils/get-cookie-path';
import Cookies from 'js-cookie';
import generateRandomString from '@grebban/utils/string/generateRandomString';

/**
 * getDepictSessionId
 * Get the depict session id or create a new in none exists
 */
export const getDepictSessionId = () => {
    const path = getCookiePath();
    const cookie = 'depict_session_id'
    const value = Cookies.get(cookie);

    if (value) {
        return value;
    }
    const sessionId = generateRandomString(32);

    Cookies.set(cookie, sessionId, { expires: 30, path });

    return sessionId;
};
