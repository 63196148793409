import { useEffect, useState } from 'react';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';
import { getOneTrustConsent } from "@activebrands/core-web/utils/get-onetrust-consent";

declare global {
    interface Window {
        OnetrustActiveGroups: any;
    }

    interface Event {
        detail: any;
    }
}

const useOnetrustOptInLoad = () => {
    const [scriptConsent, setScriptConsent] = useState(getOneTrustConsent());
    const listener = useEventListener('window');

    useEffect(() => {
        // Handle consent when optanonConsent is updated
        const handler = listener.subscribe('OneTrustGroupsUpdated', () => {
            setScriptConsent(getOneTrustConsent());
        });

        return () => {
            listener.unsubscribe('OneTrustGroupsUpdated', handler);
        };
    }, []);

    return scriptConsent;
};

export default useOnetrustOptInLoad;
