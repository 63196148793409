/* eslint-disable @typescript-eslint/no-explicit-any */
import transformFlexibleBackground, {
    TransformedBackground,
} from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-background';

/**
 * Get image from page content that can be used for og-image etc.
 * Where to look for the image will differ depending on page type and project.
 */

export const getOptimalContentImageSrc = (content: Record<string, any>) => {
    let src: string | null = null;

    if (content.mediaHorizontal) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.mediaHorizontal[0]));
    }

    if (!src && content.backgroundDesktop) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.backgroundDesktop[0]));
    }

    if (!src && content.heroContent && content.heroContent[0]?.backgroundDesktop) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.heroContent[0].backgroundDesktop?.[0]));
    }

    if (!src && content.backgroundMobile) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.backgroundMobile[0]));
    }

    if (!src && content.heroContent && content.heroContent[0]?.backgroundMobile) {
        src = getMediaSrcIfImage(transformFlexibleBackground(content.heroContent[0].backgroundMobile?.[0]));
    }

    return src;
};

export const getMediaSrcIfImage = (media: TransformedBackground): string | null => {
    if (!media) {
        return null;
    } else if (Array.isArray(media)) {
        const backgroundSplit = media.map(getMediaSrcIfImage).filter(v => v);
        return backgroundSplit[0] || null;
    } else if (media.type === 'image') {
        return media.src || null;
    }

    return null;
};

/**
 * Get products from page content that can be used for structured data.
 * Where to look for the products will differ depending on page type and project.
 */

export const getContentProducts = (content: Record<string, any>) => {
    const products = getContentProductsRecursivly(content?.pageContent, {});
    return Object.values(products);
};

const availableLayouts = ['twoColumnsLayout', 'twoColumnsArticleLayout'];
const availableModules = ['shopTheLookModule', 'shopTheLookSpecialModule', 'productGalleryModule'];
const getContentProductsRecursivly = (modules: Record<string, any>[], products: Record<string, any>) => {
    if (modules) {
        modules?.forEach(module => {
            const type = module.component;
            if (availableLayouts.includes(type)) {
                products = getContentProductsRecursivly(module.columns, products);
            } else if (availableModules.includes(type)) {
                const moduleProducts =
                    module?.productSelection?.[0]?.products?.reduce(
                        (obj: Record<string, any>, product: Record<string, any>) => ({
                            ...obj,
                            [product.id]: product,
                        }),
                        {}
                    ) || {};
                products = { ...products, ...moduleProducts };
            }
        });
    }
    return products;
};
