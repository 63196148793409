import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetaQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        application {
                            locale
                        }
                        site {
                            path
                            domain
                            protocol
                        }
                        pricelist {
                            currency
                        }
                        config {
                            organizationConfig {
                                companyEmail
                                companyLogo {
                                    ...Image
                                }
                                companyStreetAddress
                                companyAddressLocality
                                companyPostalCode
                                companyAddressCountry
                            }
                            dedicatedPages {
                                searchPage
                            }
                        }
                    }
                }
            }
        `
    );

    const { site, pricelist, config, application: app } = application.data || {};
    const { organizationConfig, dedicatedPages } = config || {};

    return {
        ...organizationConfig,
        companyLogo: organizationConfig?.companyLogo?.filename,
        currency: pricelist?.currency,
        domain: site.domain,
        lang: app.locale,
        path: site.path,
        protocol: site.protocol,
        searchPage: dedicatedPages?.searchPage,
        siteURL: `${site.protocol}://${site.domain}`,
    };
};

export default useSiteMetaQuery;
