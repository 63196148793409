import { setApplicationMarket } from '@activebrands/core-web/state/application/actions';
import { setBasketMarket } from '@activebrands/core-web/state/basket/actions';
import { setProductPrice } from '@activebrands/core-web/state/product/actions';
import { StoreAction } from '@activebrands/core-web/state/store';
import { ProductPrice } from '@activebrands/core-web/types/product';
import parseProductPrice from '@activebrands/core-web/utils/product/parse-product-price';
import Cookies from 'js-cookie';

const BASKET_ID_COOKIE = process.env.REACT_APP_BASKET_ID_COOKIE as string;

export const setCampaignSite =
    (data: Record<string, any>): StoreAction =>
    (dispatch, getState) => {
        const state = getState();

        try {
            const transformedPricelist: Record<string, ProductPrice> = {};
            Object.keys(data.pricelist).reduce((prices, key) => {
                prices[key] = parseProductPrice(data.pricelist[key]);
                return prices;
            }, transformedPricelist);

            dispatch({
                type: 'SET_CAMPAIGN_SITE',
                payload: {
                    campaignMarketId: data.campaignMarketId,
                    campaignName: data.campaignName,
                    campaignPricelist: transformedPricelist,
                    originalMarketId: data.originalMarketId,
                },
            });

            // Update application.shopConfig.marketId
            dispatch(setApplicationMarket(data.campaignMarketId));

            // Update basket market if any is present
            const basketId = Cookies.get(BASKET_ID_COOKIE);
            if (basketId) {
                dispatch(setBasketMarket(basketId, data.campaignMarketId));
            }

            // Update product.price if campaign price differs from current
            const { id, price } = state.product;
            if (
                price &&
                id &&
                transformedPricelist[id] &&
                JSON.stringify(transformedPricelist[id]) !== JSON.stringify(price)
            ) {
                dispatch(setProductPrice(transformedPricelist[id]));
            }
        } catch (e) {
            dispatch({ type: 'SET_CAMPAIGN_SITE_ERROR' });
            throw e;
        }
    };

export const resetCampaignSite =
    (originalMarketId: string): StoreAction =>
    dispatch => {
        dispatch({ type: 'RESET_CAMPAIGN_SITE' });

        // Reset basket market to original
        const basketId = Cookies.get(BASKET_ID_COOKIE);
        if (basketId) {
            dispatch(setBasketMarket(basketId, originalMarketId));
        }

        // No need to reset application.shopConfig.marketId or
        // product.price as it won't be manipulated if no campaign is present
    };
