import { Helmet } from 'react-helmet';
import useSiteMetaQuery from 'queries/useSiteMetaQuery';
import { addLocalePrefix, getLocalePrefix } from '@activebrands/core-web/utils/add-locale-prefix';

const SiteSearchStructuredData = () => {
    const { lang, searchPage, siteURL } = useSiteMetaQuery();

    const sitelinksSearchbox = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': `${siteURL}${getLocalePrefix()}/`,
        'inLanguage': lang,
        'potentialAction': {
            '@type': 'SearchAction',
            'target': `${siteURL}${addLocalePrefix(searchPage)}?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
        },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(sitelinksSearchbox, null, 0)}</script>
        </Helmet>
    );
};

export default SiteSearchStructuredData;
