import DataLayer from './DataLayer';
import Gtm from '@grebban/gtm';
import type { Product } from '@activebrands/core-web/types/tracking-events';

export default ({ currencyCode, product }: {
    currencyCode: string;
    product: Product;
}) => {
    Gtm.addDataLayerEvent('add_to_cart', {
        ecommerce: {
            currencyCode,
            add: {
                products: [DataLayer.getProduct(product)],
            },
        },
    });
};
