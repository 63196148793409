import { useMemo, useRef, useState } from 'react';
import asArray from '@grebban/utils/array/asArray';
import Events from '@activebrands/core-web/libs/Events';
import { OverlayEventsType } from '@activebrands/core-web/libs/Events/types';
import useEvent from '@activebrands/core-web/libs/Events/useEvent';

type StateChange = {
    type: 'open' | 'close' | 'close-all';
    ids: string[];
    props?: Record<string, any>;
};

const state: {
    overlays: string[];
} = {
    overlays: [],
};

const open = (id: string, props?: Record<string, any>) => {
    if (state.overlays.indexOf(id) === -1) {
        state.overlays.push(id);
        Events.trigger<OverlayEventsType, StateChange>(`OVERLAY.STATE_CHANGE`, { type: 'open', ids: [id], props });
        Events.trigger(`OVERLAY.OPEN_${id}`, props);
    }
};

const update = (id: string, props: Record<string, any>) => {
    if (state.overlays.indexOf(id) === -1) {
        Events.trigger(`OVERLAY.UPDATE_${id}`, props);
    }
}

const close = (id: string | string[]) => {
    if (state.overlays.length) {
        const arr = asArray(id);
        const ids = [];

        for (let index = 0; index < arr.length; index++) {
            const overlay = arr[index];
            const overlayIndex = state.overlays.indexOf(overlay);

            if (overlayIndex > -1) {
                ids.push(overlay);
                state.overlays.splice(overlayIndex, 1);
                Events.trigger(`OVERLAY.CLOSE_${overlay}`);
            }
        }

        Events.trigger<OverlayEventsType, StateChange>(`OVERLAY.STATE_CHANGE`, { type: 'close', ids });
    }
};

const closeAll = (except?: string[]) => {
    if (state.overlays.length) {
        const filtered = except?.length ? state.overlays.filter(o => except.indexOf(o) === -1) : state.overlays;

        close(filtered);
    }
};

const toggle = (id: string, props?: Record<string, any>) => {
    const index = state.overlays.indexOf(id);

    index === -1 ? open(id, props) : close(id);
};

export default {
    open,
    close,
    update,
    closeAll,
    toggle,
};

export const useOverlaySubscriber = (overlays?: string | string[]) => {
    const [forceUpdate, setForceUpdate] = useState(0);
    const memoKey = overlays ? (typeof overlays === 'string' ? overlays : overlays.join()) : true;
    const prev = useRef<null | boolean | string>(null);

    useEvent<OverlayEventsType, StateChange>(
        'OVERLAY.STATE_CHANGE',
        ({ ids }) => {
            if (!overlays || !ids) {
                return setForceUpdate(prev => ++prev);
            }
            if (typeof overlays === 'string') {
                if (prev.current !== state.overlays.indexOf(overlays) > -1) {
                    setForceUpdate(prev => ++prev);
                }
            } else if (prev.current !== overlays.reduce((a, c) => `${a}${state.overlays.indexOf(c) > -1}`, '')) {
                setForceUpdate(prev => ++prev);
            }
        },
        [memoKey]
    );

    return useMemo(() => {
        let result: boolean | boolean[] | string[] = state.overlays;

        if (overlays) {
            if (typeof overlays === 'string') {
                result = state.overlays.indexOf(overlays) > -1;
                prev.current = result;
            } else {
                result = overlays.reduce<boolean[]>((a, c) => [...a, state.overlays.indexOf(c) > -1], []);
                prev.current = result.join();
            }
        } else {
            prev.current = null;
        }

        return result;
    }, [memoKey, forceUpdate]);
};
