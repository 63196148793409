import fm from 'format-message';
import isObject from '@grebban/utils/object/isObject';

const parseError = (raw: any) => {
    // The message can look different from the backend..
    let message =
        raw?.data?.data?.error ||
        raw?.data?.error?.reason ||
        raw?.data?.error ||
        raw?.data?.message ||
        raw?.data?.firebaseResponse?.message ||
        raw?.data?.error ||
        raw?.error?.reason ||
        raw?.error ||
        raw?.message ||
        raw?.firebaseResponse?.message ||
        raw?.data ||
        fm('Something went wrong');

    // Error can be both string and object
    if (!message && isObject(raw?.error)) {
        message = Object.values(raw.error).join(', ');
    }

    // Translation for known errors from backend
    const translationObject: Record<string, string> = {
        'Email is not verified': fm('Email is not verified'),
    };

    // We can recieve both status and code from backend
    const status = raw?.status || raw?.code || 500;

    // Translate the message if we can
    message = translationObject[message] || message;

    return {
        status,
        message,
    };
};

export default parseError;
