import aa from 'search-insights';

export interface ClickedObjectIDsAfterSearchData {
    eventName: string;
    index: string;
    objectIDs: string[];
    positions: number[];
    queryID: string;
    userToken: string;
}

/*
 * Send a click event to capture a query and its clicked items and positions.
 *
 * Unlike clickedObjectIDs, ClickedObjectIDsAfterSearch takes a queryID.
 * The captured click event applies to both Personalization and Click and Conversion Analytics.
 * ClickedObjectIDs only applies to personalization.
 */
export default (data: ClickedObjectIDsAfterSearchData) => {
    aa('clickedObjectIDsAfterSearch', data);
};
