import { request } from '@activebrands/core-web/libs/fetch';
import { del, get, post, put } from '@activebrands/core-web/libs/grebcommerce/fetch';
import { BundleInfo, PaymentData } from '@activebrands/core-web/types/basket';
import {
    formatBasketTracking,
    getGoogleTrackingConsent,
    getTrackingCookies,
} from '@activebrands/core-web/utils/tracking';
import { BasketTrackingMeta, ProductTracking } from 'types/tracking';

const ACTIVE_BRANDS_BRAND_CODE = process.env.REACT_APP_ACTIVE_BRANDS_BRAND_CODE;

// *** Create/Get basket ***
export const CreateBasket = async (
    country: string,
    market: string,
    pricelist: string,
    locale: string,
    aliasId: number,
    basketTrackingMeta: BasketTrackingMeta
) => {
    return await post('/basket', {
        'country_code': country,
        'market_id': market,
        'language': locale,
        'pricelist_id': pricelist,
        'alias_id': aliasId,
        'consent' : getGoogleTrackingConsent(),
        'meta': {
            'ga4_session_id': basketTrackingMeta?.ga4_session_id,
            'url': basketTrackingMeta?.url,
            'timestamp_micros': basketTrackingMeta?.timestampMicros,
            '_fbp': basketTrackingMeta?._fbp,
            '_fbc': basketTrackingMeta?._fbc,
            '_ga': basketTrackingMeta?._ga,
        },
    });
};

export const GetBasket = async (id: string) => await get(`/basket/${id}`);

// *** Manipulate basket content ***
export const AddBasketItem = async (
    basketId: string,
    productVariationId: string,
    productTracking: ProductTracking,
    quantity = 1
) => {
    const data: any = {
        ...formatBasketTracking(productTracking),
        'product_variation_id': productVariationId,
        quantity,
    };

    return await post(`/basket/${basketId}/items`, data);
};

export const RemoveBasketItem = async (basketId: string, line: string, productTracking: ProductTracking) => {
    const data: any = {
        ...formatBasketTracking(productTracking),
    };

    return await put(`/basket/${basketId}/line/${line}/quantity/0`, data);
};

export const AddBundleItem = async (
    basketId: string,
    bundleInfo: BundleInfo,
    quantity = 1,
    url?: string,
    comment?: string
) => {
    const data: Record<string, any> = {
        ...bundleInfo,
        quantity,
    };

    if (comment) {
        data.comment = comment;
    }

    if (url) {
        data.url = url;
    }

    // Rename productVariantId to match API expectations.
    data.product_variant_id = data.productVariantId;
    delete data.productVariantId;

    return await post(`/basket/${basketId}/bundles`, data);
};

export const UpdateBasketLineQuantity = async (
    basketId: string,
    line: string,
    productTracking: ProductTracking,
    quantity = 1
) => {
    const data: any = {
        ...formatBasketTracking(productTracking),
    };

    return await put(`/basket/${basketId}/line/${line}/quantity/${quantity}`, data);
};

export const ResetBasket = async (basketId: string) => await del(`/basket/${basketId}/items`, []);

export const SetMarket = async (basketId: string, marketId: string) =>
    await put(`/basket/${basketId}/market/${marketId}`, {});

// *** Add voucher(s) to basket ***
const EncodeString = (code: string) => btoa(encodeURIComponent(code)).replace('+', '-').replace('/', '_');
export const AddVoucher = async (basketId: string, voucherId: string) =>
    await post(`/basket/${basketId}/voucher/${EncodeString(voucherId)}`, {});

export const AddActVoucher = async (basketId: string, voucherId: string, token: string) =>
    await request({
        method: 'POST',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/voucher/activate',
        data: { basketId, actVoucherId: voucherId, brandCode: ACTIVE_BRANDS_BRAND_CODE },
        headers: { 'Authorization': `Bearer ${token}` },
    });

export const RemoveVoucher = async (basketId: string, voucherId: string) =>
    await del(`/basket/${basketId}/voucher/${EncodeString(voucherId)}`, {});

export const AddExternalVoucher = async (basketId: string, voucherId: string) =>
    await post(`/basket/${basketId}/external-voucher/${voucherId}`, {});

// *** Add/Uppdate address in basket ***
export const AddBasketInformation = async (basketId: string, data: any) => await put(`/basket/${basketId}`, data);

// *** Get/Select Payment Method
export const GetBasketPaymentMethod = async (basketId: string, data: PaymentData) => {
    const formatedData = {
        'payment_method_id': data.paymentMethodId,
        'success_url': data.responseUrl,
        'error_url': data.errorUrl || data.responseUrl,
        'terms_and_conditions': true,
        'language': data.locale,
        'address': {
            'first_name': data.address.firstName,
            'last_name': data.address.lastName,
            'address1': data.address.address1,
            'address2': data.address.address2,
            'zip': data.address.zip,
            'city': data.address.city,
            'email': data.address.email,
            'company': data.address.company,
            'phone': data.address.phone,
            'country': data.address.country,
            'state': data.address.state,
            'newsletter': data.address.newsletter,
        },
        'shipping_address': {
            'first_name': data.shippingAddress.firstName,
            'last_name': data.shippingAddress.lastName,
            'address1': data.shippingAddress.address1,
            'address2': data.shippingAddress.address2,
            'zip': data.shippingAddress.zip,
            'city': data.shippingAddress.city,
            'email': data.shippingAddress.email,
            'company': data.shippingAddress.company,
            'phone': data.shippingAddress.phone,
            'country': data.shippingAddress.country,
            'state': data.shippingAddress.state,
        },
        'payment_method_specific_fields': data.paymentMethodSpecificFields,
        'payment_initiate_only': data.paymentInitiateOnly,
        'addressIncluded': data.addressIncluded,
        'token': data.token,
        'consent': getGoogleTrackingConsent(),
        'meta': {
            'url': window.location.href,
            'timestamp_micros': Date.now() * 1000,
            ...getTrackingCookies()
        },
    };

    return await post(`/basket/${basketId}/payment`, formatedData);
};

export const SelectPaymentMethod = async (basketId: string, paymentMethodId: string) =>
    await put(`/basket/${basketId}/payment-method/${paymentMethodId}`, []);

// *** Select Shipping method ***
export const SelectShippingMethod = async (basketId: string, shippingMethodId: string) =>
    await put(`/basket/${basketId}/shipping-method/${shippingMethodId}`, []);
