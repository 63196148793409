import DataLayer from './DataLayer';
import Gtm from '@grebban/gtm';

export default ({ currencyCode, products }) => {
    Gtm.addDataLayerEvent('impressions', {
        ecommerce: {
            currencyCode,
            impressions: DataLayer.getProducts(products), // clear possible duplicate impression
        },
    });
};
