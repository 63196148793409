import { useState } from 'react';
import { inServer } from '@activebrands/core-web/utils/constants';

export const get = (key: string, initialValue: any): any => {
    if (inServer) {
        return initialValue;
    }

    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
    } catch (error) {
        // If error also return initialValue
        console.error(error);
        return initialValue;
    }
};

export const set = (key: string, value: any): any => {
    if (inServer) {
        return;
    }

    try {
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error);
    }
};

export const remove = (key: string): any => {
    if (inServer) {
        return;
    }

    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error);
    }
};

export const useLocalStorage = (key: string, initialValue: any) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => get(key, initialValue));

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);

        set(key, valueToStore);
    };

    return [storedValue, setValue];
};

export default {
    get,
    set,
    remove,
};
