import { Animation, CssResponsiveVariables, Curve, Duration, Transition } from '@activebrands/core-web/types/css-types';

export interface PacingCssResponsiveVariables {
    '--pacing-slow'?: Duration;
    '--pacing-medium'?: Duration;
    '--pacing-fast'?: Duration;
}
export interface EasingCssResponsiveVariables {
    '--easing-fast-to-slow'?: Curve;
}
export interface TransitionsCssResponsiveVariables {
    '--transition-primary'?: Transition;
    '--transition-primary-fast'?: Transition;
    '--transition-primary-slow'?: Transition;
}

export interface AnimationsCssResponsiveVariables {
    '--animation-infinite-spin'?: Animation;
}

export const transitionsCssResponsiveVariables: CssResponsiveVariables<
    PacingCssResponsiveVariables &
        EasingCssResponsiveVariables &
        TransitionsCssResponsiveVariables &
        AnimationsCssResponsiveVariables
> = {
    'mobile.sm': {
        '--ease': 'cubic-bezier(0.25, 0, 0.34, 1)',

        '--duration-fast': '200ms',
        '--duration-medium': '400ms',
        '--duration-slow': '600ms',

        '--transition-fast': 'var(--duration-fast) var(--ease)',
        '--transition-medium': 'var(--duration-medium) var(--ease)',
        '--transition-slow': 'var(--duration-slow) var(--ease)',
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {},
    'desktop.md': {},
    'desktop.lg': {},
};
