/* eslint-disable @typescript-eslint/no-explicit-any */
import { hydrate, render } from 'react-dom';
import { isProduction } from '@activebrands/core-web/utils/constants';
import { loadableReady } from '@loadable/component';

const replaceHydrateFunction = (element: any, container: any, callback: any) => {
    const renderFn = process.env.GATSBY_BUILD_STAGE?.includes('develop') ? render : hydrate;

    if (isProduction) {
        loadableReady(() => renderFn(element, container, callback));
    } else {
        renderFn(element, container, callback);
    }
};

export default replaceHydrateFunction;
