import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import { styled } from '@activebrands/core-web/libs/styletron';
import convertHexToBase64 from '@activebrands/core-web/utils/convert-hex-to-base64';

const Background = styled('span', ({ $cursorColor }) => ({
    backgroundColor: 'var(--color-bg-backdrop)',
    display: 'block',
    top: 'var(--top-content-backdrop)',
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0,
    transitionDuration: 'var(--transition-duration-content-backdrop)',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'var(--transition-timing-function-content-backdrop)',
    pointerEvents: 'none',
    cursor: `url('data:image/svg+xml,%3Csvg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1l32 32M33 1L1 33" stroke="${$cursorColor}"/%3E%3C/svg%3E'), auto`,
}));

/**
 * Backdrop, used by overlays.
 * @param {object} [$style] - CSS style object.
 * @param {boolean} [isVisible=false] - Is backdrop visible or no.
 * @param {func} [onClick] - Callback on backdrop click.
 * @param {number} [position='fixed'] - Css position on background div.
 * @param {number} [zIndex] - Z index on background div.
 * @param {string} [cursorColor] - Change hex color of cursor.
 */

const Backdrop = ({
    $style = {},
    children,
    cursorColor = '#FFFFFF',
    isVisible = false,
    onClick,
    position = 'fixed',
    zIndex,
}) => {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        // Fade in on next animation frame if backdrop is visible on mount.
        if (isVisible) {
            requestAnimationFrame(() => setOpacity(1));
        }
    }, []);

    useDidUpdate(() => {
        if (isVisible && opacity === 0) {
            setOpacity(1);
        }
    }, [isVisible]);

    return (
        <Background
            aria-hidden
            $style={{
                position,
                zIndex,
                ...$style,
            }}
            $cursorColor={convertHexToBase64(cursorColor)}
            style={isVisible ? { pointerEvents: 'auto', opacity } : null}
            onClick={isVisible && onClick ? onClick : undefined}
        >
            {children}
        </Background>
    );
};

Backdrop.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.node,
    cursorColor: PropTypes.string,
    isVisible: PropTypes.bool,
    onClick: PropTypes.func,
    position: PropTypes.string,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Backdrop;
