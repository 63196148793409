import { ProductListActionTypes } from '@activebrands/core-web/state/productList/types';

const initialState = {
    layout: 'grid',
};

export default (state = initialState, action: ProductListActionTypes) => {
    if (action.type === 'SET_PRODUCT_LIST_LAYOUT') {
        return {
            ...state,
            layout: action.payload,
        };
    } else {
        return state;
    }
};
