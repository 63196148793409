import Gtm from '@grebban/gtm';

export default ({ userId, customerNumber }: {
    userId: string;
    customerNumber: string;
}) => {
    Gtm.addDataLayerEvent('myAccountSignIn', {
        event_action: 'Sign In',
        event_category: 'customer_homepage',
        event_label: customerNumber,
        user_id: userId
    });
}