import decodeHTMLEntities from '@grebban/utils/string/decodeHtmlEntities';

/**
 * Format meta description
 * @param {string} description
 */

export const formatMetaDescription = (description: string) => {
    let cloneString = ` ${description}`.slice(1);

    if (cloneString) {
        cloneString = decodeHTMLEntities(cloneString);

        // Slice description since google only reads 150 characters
        if (cloneString.length > 151) {
            cloneString.slice(0, 152);
            cloneString.concat('...');
        }
    }

    return cloneString;
};
