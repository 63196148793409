import { FEATURE_FLAGS, FeaturesActionTypes, FeaturesState } from '@activebrands/core-web/state/features/types';
import { getFlagState } from '@activebrands/core-web/utils/feature-flags';

const defaultValues = Object.values(FEATURE_FLAGS).reduce((defaults: FeaturesState, flag) => {
    defaults[flag] = getFlagState(flag);
    return defaults;
}, {});

if (!process.env.REACT_APP_GTM_TRACKING_KEY) {
    defaultValues[FEATURE_FLAGS.GTM] = false;
}

const initialState: FeaturesState = defaultValues;

export default (state = initialState, action: FeaturesActionTypes) => {
    if (action.type === 'SET_FEATURES_SUCCESS') {
        const preSetValues = Object.entries(defaultValues).filter(([key, value]) => !value);
        return {
            ...state,
            ...action.payload,
            ...Object.fromEntries(preSetValues),
        };
    }

    return state;
};
