import Event from '@activebrands/core-web/libs/Events';
import getCookiePath from '@activebrands/core-web/utils/get-cookie-path';
import uuid from '@grebban/utils/string/uuid';
import Cookies from 'js-cookie';
import aa from 'search-insights';
import clickedFilters, { ClickedFiltersData } from './insights/clickedFilters';
import clickedObjectIDsAfterSearch, { ClickedObjectIDsAfterSearchData } from './insights/clickedObjectIDsAfterSearch';
import convertedObjectIDsAfterSearch, {
    ConvertedObjectIDsAfterSearchData,
} from './insights/convertedObjectIDsAfterSearch';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APP_ID as string;
const API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY as string;

export enum SearchEvents {
    VIEW = 'SEARCH_INSIGHTS.VIEW',
    CLICK = 'SEARCH_INSIGHTS.CLICK',
    PRODUCT_ADDED = 'SEARCH_INSIGHTS.PRODUCT_ADDED',
    LIST = 'SEARCH_INSIGHTS.LIST.VIEW',
    FILTER = 'SEARCH_INSIGHTS.LIST.FILTER',
    FILTER_CLICK = 'SEARCH_INSIGHTS.LIST.FILTER_CLICK',
}

const getSessionId = () => {
    const path = getCookiePath();
    const cookie = process.env.REACT_APP_ALGOLIA_SESSION_ID_COOKIE!;
    const value = Cookies.get(cookie);

    if (value) {
        return value;
    }
    const sessionId = uuid();

    Cookies.set(cookie, sessionId, { expires: 30, path });

    return sessionId;
};

export const init = () => {
    aa('init', {
        appId: APPLICATION_ID,
        apiKey: API_KEY,
    });

    const userToken = getSessionId();

    Event.subscribe(SearchEvents.CLICK, (data: Omit<ClickedObjectIDsAfterSearchData, 'userToken'>) =>
        clickedObjectIDsAfterSearch({ ...data, userToken })
    );

    Event.subscribe(SearchEvents.PRODUCT_ADDED, (data: Omit<ConvertedObjectIDsAfterSearchData, 'userToken'>) =>
        convertedObjectIDsAfterSearch({ ...data, userToken })
    );

    Event.subscribe(SearchEvents.FILTER_CLICK, (data: Omit<ClickedFiltersData, 'userToken'>) =>
        clickedFilters({ ...data, userToken })
    );
};
