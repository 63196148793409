import { FeaturesState } from '@activebrands/core-web/state/features/types';
import { StoreAction } from '@activebrands/core-web/state/store';

export const setFeatures =
    (features: FeaturesState): StoreAction =>
    dispatch => {
        dispatch({
            type: 'SET_FEATURES_SUCCESS',
            payload: features,
        });
    };
