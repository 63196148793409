import fm from 'format-message';
import DataLayer from '@activebrands/core-web/components/tracking/DataLayer';
import Events from '@activebrands/core-web/libs/Events';
import { BasketEvents, CheckoutEvents } from '@activebrands/core-web/libs/Events/types';
import { CheckoutTracking } from '@activebrands/core-web/libs/Tracking/constants';
import { renderCloudflareTurnstileWidgetInOverlay } from '@activebrands/core-web/libs/cloudflareTurnstile/cloudflareTurnstileRender';
import {
    AddActVoucher,
    AddBasketInformation,
    AddBasketItem,
    AddBundleItem,
    AddExternalVoucher,
    AddVoucher,
    CreateBasket,
    GetBasket,
    GetBasketPaymentMethod,
    RemoveBasketItem,
    RemoveVoucher,
    SelectPaymentMethod,
    SelectShippingMethod,
    SetMarket,
    UpdateBasketLineQuantity,
} from '@activebrands/core-web/libs/grebcommerce/basket';
import {
    clearBasketIdCookie,
    getBasketIdCookie,
    getBasketItemById,
    getBasketItemByLine,
    refreshCheckout,
    setBasketIdCookie,
} from '@activebrands/core-web/state/basket/utils';
import { StoreAction } from '@activebrands/core-web/state/store';
import { acquireMutex } from '@activebrands/core-web/state/utils';
import { BundleInfo, CentraVoucher, PaymentData } from '@activebrands/core-web/types/basket';
import { ProductTracking } from '@activebrands/core-web/types/tracking';
import { getTrackingCookies } from '@activebrands/core-web/utils/tracking';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

export const ADD_VOUCHER_COMBINATION_ERROR = 'ADD_VOUCHER_COMBINATION_ERROR';

export const setCloudflareTurnstileStatus =
    (status: string): StoreAction =>
    dispatch => {
        dispatch({
            type: 'SET_CLOUDFLARE_TURNSTILE_STATUS',
            payload: status,
        });
    };

export const createBasket = (): StoreAction<Promise<any>> => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.createBasket');
    const { isFetching } = getState().basket;

    if (isFetching) {
        return;
    }

    const {
        shopConfig: { userCountry = '', marketId, pricelistId },
        locale,
        aliasId,
    } = getState().application;

    dispatch({ type: 'CREATE_BASKET' });

    try {
        const meta = {
            ...getTrackingCookies(),
            url: window.location.href,
            timestampMicros: Date.now() * 1000,
        };

        const response = await CreateBasket(userCountry, marketId, pricelistId, locale, aliasId, meta);

        if (response.status === 200 || response.status === 201) {
            if (response.data.data.id) {
                setBasketIdCookie(response.data.data.id);
            }

            dispatch({
                type: 'CREATE_BASKET_SUCCESS',
                payload: objectKeysToCamelCase(response.data.data),
            });

            Events.trigger(BasketEvents.CREATED);

            mutexLock();

            return response;
        }
    } catch (e) {
        Events.trigger(
            BasketEvents.ERROR,
            `${fm('Something went wrong...')}} ${fm('We were not able to create your basket.')}`
        );
        dispatch({ type: 'CREATE_BASKET_ERROR' });
        mutexLock();
        throw e;
    }
};

const getBasketId = (): StoreAction<Promise<string>> => async (dispatch, getState) => {
    let basketId = getState().basket.id || getBasketIdCookie() || '';

    if (!basketId || basketId === 'undefined') {
        const response = await dispatch(createBasket());

        basketId = response.data.data.id;
    }

    return basketId;
};

export const getBasket =
    (id?: string): StoreAction =>
    async dispatch => {
        let basketId: string = id || (await dispatch(getBasketId()));
        const mutexLock = await acquireMutex('basket.getBasket');

        dispatch({ type: 'GET_BASKET' });

        try {
            let getBasketResponse = await GetBasket(basketId);

            if (getBasketResponse.status === 404) {
                dispatch({ type: 'GET_BASKET_ERROR' });

                // @todo: Implement sentry (?)
                // Sentry.captureEvent({
                //     message: 'GET_BASKET_ERROR',
                //     contexts: {
                //         basket: { id: basketId },
                //     },
                //     extra: {
                //         response: { ...getBasketResponse },
                //         status: 404,
                //     },
                // });

                clearBasketIdCookie();

                mutexLock();

                const createBasketResponse = await dispatch(createBasket());

                if (createBasketResponse.status === 200 || createBasketResponse.status === 201) {
                    basketId = createBasketResponse.data.id as string;
                    getBasketResponse = await GetBasket(basketId);
                }
            }

            if (basketId && (getBasketResponse.status === 200 || getBasketResponse.status === 201)) {
                dispatch({
                    type: 'GET_BASKET_SUCCESS',
                    payload: objectKeysToCamelCase(getBasketResponse.data.data),
                });

                mutexLock();

                return getBasketResponse;
            }

            throw getBasketResponse.statusText;
        } catch (error) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to fetch your basket.')}`
            );
            dispatch({ type: 'GET_BASKET_ERROR' });

            clearBasketIdCookie();
            mutexLock();

            return error;
        }
    };

export const addBasketItem =
    (productVariationId: string, productTracking: ProductTracking, quantity = 1): StoreAction =>
    async (dispatch, getState) => {
        const basketId = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.addBasketItem');

        dispatch({ type: 'ADD_BASKET_ITEM' });
        try {
            const basketItem = getBasketItemById(getState().basket.items, productVariationId);

            productTracking.quantity = basketItem.quantity ?? 0;
            const response = await refreshCheckout(
                AddBasketItem(basketId, productVariationId, productTracking, quantity)
            );

            if (response.status === 200 || response.status === 201) {
                const data = objectKeysToCamelCase(response.data.data);

                dispatch({
                    type: 'ADD_BASKET_ITEM_SUCCESS',
                    payload: data,
                });

                const item = getBasketItemById(data.items, productVariationId);

                Events.trigger(BasketEvents.PRODUCT_ADDED, {
                    product: DataLayer.basketItem(item, getState().basket?.country, quantity),
                    currencyCode: getState().basket?.currency,
                });

                mutexLock();
                return response;
            }
            throw response.error;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')}} ${fm('We were not able to create your basket.')}`
            );
            dispatch({ type: 'ADD_BASKET_ITEM_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const addBundleToBasket =
    (bundleInfo: BundleInfo, quantity = 1, url?: string): StoreAction =>
    async (dispatch, getState) => {
        const basketId = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.addBundleToBasket');

        dispatch({ type: 'ADD_BUNDLE_ITEM' });

        try {
            const response = await refreshCheckout(AddBundleItem(basketId, bundleInfo, quantity, url));
            if (response.status === 200 || response.status === 201) {
                const data = objectKeysToCamelCase(response.data.data);

                dispatch({
                    type: 'ADD_BUNDLE_ITEM_SUCCESS',
                    payload: data,
                });

                const item = getBasketItemById(data.items, bundleInfo.productVariantId);
                item.quantity = quantity;

                Events.trigger(BasketEvents.PRODUCT_ADDED, {
                    product: DataLayer.basketItems([item]),
                    currencyCode: getState().basket?.currency,
                });

                mutexLock();

                return response;
            }
            throw response.error;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to add that item to your basket.')}`
            );
            dispatch({ type: 'ADD_BUNDLE_ITEM_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const removeBasketItem =
    (line: string, productTracking: ProductTracking, quantity = 1): StoreAction =>
    async (dispatch, getState) => {
        const basketId: string = await dispatch(getBasketId());

        if (!basketId) {
            throw new Error('Basket id unknown');
        }

        const mutexLock = await acquireMutex('basket.removeBasketItem');

        dispatch({ type: 'REMOVE_BASKET_ITEM' });

        try {
            const basketItem = getBasketItemByLine(getState().basket.items, line);

            productTracking.priceWithoutTaxAsNumber = basketItem.priceEachWithoutTaxAsNumber ?? 0;
            productTracking.quantity = basketItem.quantity ?? 0;

            const response = await refreshCheckout(RemoveBasketItem(basketId, line, productTracking));

            if (response.status === 200) {
                const data = objectKeysToCamelCase(response.data.data);

                const item = getBasketItemByLine(getState().basket.items, line);

                Events.trigger(BasketEvents.PRODUCT_REMOVED, {
                    product: DataLayer.basketItem(item, getState().basket?.country, quantity),
                    currencyCode: getState().basket.currency,
                });

                dispatch({
                    type: 'REMOVE_BASKET_ITEM_SUCCESS',
                    payload: data,
                });

                mutexLock();
                return response;
            }
            throw response.error;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to remove that item from your basket.')}`
            );
            dispatch({ type: 'REMOVE_BASKET_ITEM_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const updateBasketLineQuantity =
    (
        line: string,
        productTracking: ProductTracking,
        quantityChange: number,
        setIsLoading: (arg: boolean) => void
    ): StoreAction =>
    async (dispatch, getState) => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.updateBasketLineQuantity');

        dispatch({ type: 'UPDATE_BASKET_ITEM' });

        try {
            const item = getBasketItemByLine(getState().basket.items, line);

            if (item) {
                const quantity = item.quantity + quantityChange;

                productTracking.priceWithoutTaxAsNumber = item.priceEachWithoutTaxAsNumber ?? 0;
                productTracking.quantity = item.quantity ?? 0;

                const response = await refreshCheckout(
                    UpdateBasketLineQuantity(basketId, line, productTracking, quantity)
                );

                if (response.status === 200) {
                    const data = objectKeysToCamelCase(response.data.data);

                    dispatch({
                        type: 'UPDATE_BASKET_ITEM_SUCCESS',
                        payload: data,
                    });

                    Events.trigger(quantityChange > 0 ? BasketEvents.PRODUCT_ADDED : BasketEvents.PRODUCT_REMOVED, {
                        product: DataLayer.basketItem(item, getState().basket?.country, quantityChange),
                        currencyCode: getState().application?.shopConfig?.currency,
                    });

                    mutexLock();
                    setIsLoading(false);
                    return response;
                }

                if (response.error) {
                    throw new Error(response.error);
                }
            }
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to update the quantity of that item.')}`
            );

            dispatch({ type: 'UPDATE_BASKET_ITEM_ERROR' });
            mutexLock();
            setIsLoading(false);
            throw e;
        }
    };

export const addBasketInformation =
    (data: any): StoreAction =>
    async dispatch => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.addBasketInformation');
        dispatch({ type: 'ADD_BASKET_INFORMATION' });

        // const applicationState = getState().application;
        // const basketState = getState().basket;
        try {
            const response = await refreshCheckout(AddBasketInformation(basketId, data));

            // @todo: Implement sentry (?)
            // if (response.status !== 200) {
            //     Sentry.captureEvent({
            //         message: 'ADD_BASKET_INFORMATION_ERROR',
            //         contexts: {
            //             basket: {
            //                 id: basketId,
            //             },
            //         },
            //         extra: {
            //             response: { ...response },
            //             arguments: {
            //                 data,
            //             },
            //             application: applicationState,
            //             basket: basketState,
            //         },
            //     });
            // }

            dispatch({
                type: 'ADD_BASKET_INFORMATION_SUCCESS',
                payload: objectKeysToCamelCase(response.data.data),
            });
            mutexLock();
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to update your basket.')}`
            );
            dispatch({ type: 'ADD_BASKET_INFORMATION_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const addVoucher =
    (voucherId: string): StoreAction =>
    async dispatch => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.addVoucher');
        dispatch({ type: 'ADD_VOUCHER' });

        try {
            const addVoucherResponse = AddVoucher(basketId, voucherId);

            const response = await refreshCheckout(addVoucherResponse);

            if (response.status === 200 || response.status === 201) {
                // Make sure that the vouchers is actually added
                // Vouchers that can't be combined will also return status 200
                const voucherAdded = response.data.data.total_discount.discounts.some(
                    (voucher: CentraVoucher) => voucher.id === voucherId
                );

                if (voucherAdded) {
                    dispatch({
                        type: 'ADD_VOUCHER_SUCCESS',
                        payload: objectKeysToCamelCase(response.data.data),
                    });

                    Events.trigger(BasketEvents.VOUCHER_ADDED);

                    mutexLock();

                    return response;
                }

                throw ADD_VOUCHER_COMBINATION_ERROR;
            } else if (response.status === 404) {
                dispatch({ type: 'ADD_VOUCHER_ERROR' });
                mutexLock();
            } else {
                throw response.statusText;
            }

            return response;
        } catch (e) {
            if (e === ADD_VOUCHER_COMBINATION_ERROR) {
                Events.trigger(
                    BasketEvents.ERROR,
                    `${fm('Something went wrong...')}  ${fm('We were not able to combine these discount codes.')}`
                );
            } else {
                Events.trigger(
                    BasketEvents.ERROR,
                    `${fm('Something went wrong...')}  ${fm('We were not able to add that discount code.')}`
                );
            }
            dispatch({ type: 'ADD_VOUCHER_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const addActVoucher =
    (voucherId: string, token: string): StoreAction =>
    async dispatch => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.addVoucher');
        dispatch({ type: 'ADD_VOUCHER' });

        try {
            const addVoucherResponse = await AddActVoucher(basketId, voucherId, token);

            const response = await refreshCheckout(addVoucherResponse);

            if (response.status === 200 || response.status === 201) {
                // Make sure that the vouchers is actually added
                // Vouchers that can't be combined will also return status 200
                const voucherAdded = response.data.total_discount.discounts.some(
                    (voucher: CentraVoucher) => voucher.id === voucherId
                );

                if (voucherAdded) {
                    dispatch({
                        type: 'ADD_VOUCHER_SUCCESS',
                        payload: objectKeysToCamelCase(response.data),
                    });

                    Events.trigger(BasketEvents.VOUCHER_ADDED);

                    mutexLock();

                    return response;
                }

                throw ADD_VOUCHER_COMBINATION_ERROR;
            } else if (response.status === 404) {
                dispatch({ type: 'ADD_VOUCHER_ERROR' });
                mutexLock();
            } else {
                throw response.statusText;
            }

            return response;
        } catch (e) {
            if (e === ADD_VOUCHER_COMBINATION_ERROR) {
                Events.trigger(
                    BasketEvents.ERROR,
                    `${fm('Something went wrong...')}  ${fm('We were not able to combine these discount codes.')}`
                );
            } else {
                Events.trigger(
                    BasketEvents.ERROR,
                    `{fm('Something went wrong...)}  ${fm('We were not able to add that discount code.')}`
                );
            }
            dispatch({ type: 'ADD_VOUCHER_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const removeVoucher =
    (voucherId: string): StoreAction =>
    async dispatch => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.removeVoucher');

        dispatch({ type: 'REMOVE_VOUCHER' });
        try {
            const response = await refreshCheckout(RemoveVoucher(basketId, voucherId));

            dispatch({
                type: 'REMOVE_VOUCHER_SUCCESS',
                payload: objectKeysToCamelCase(response.data.data),
            });

            Events.trigger(BasketEvents.VOUCHER_REMOVED);

            mutexLock();

            return response;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to remove that item from your basket.')}`
            );
            dispatch({ type: 'REMOVE_VOUCHER_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const addExternalVoucher =
    (voucherId: string): StoreAction =>
    async dispatch => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.addVoucher');
        dispatch({ type: 'ADD_EXTERNAL_VOUCHER' });

        try {
            const response = await refreshCheckout(AddExternalVoucher(basketId, voucherId));

            if (response.status === 200 || response.status === 201) {
                dispatch({
                    type: 'ADD_EXTERNAL_VOUCHER_SUCCESS',
                    payload: objectKeysToCamelCase(response.data.data),
                });

                Events.trigger(BasketEvents.VOUCHER_ADDED);

                mutexLock();

                return response;
            } else if (response.status === 404) {
                dispatch({ type: 'ADD_EXTERNAL_VOUCHER_ERROR' });
                mutexLock();
            } else {
                throw response.statusText;
            }

            return response;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to add that discount code.')}`
            );
            dispatch({ type: 'ADD_EXTERNAL_VOUCHER_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const getBasketPaymentMethod =
    (data: PaymentData): StoreAction =>
    async (dispatch, getState) => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.GetBasketPaymentMethod');

        dispatch({ type: 'GET_PAYMENT_METHOD' });

        try {
            data.token = await renderCloudflareTurnstileWidgetInOverlay(dispatch);
        } catch (error) {
            console.error(error);
            mutexLock();
            throw error;
        }

        try {
            const response = await refreshCheckout(GetBasketPaymentMethod(basketId, data));

            if (response.data.status === 410) {
                Events.trigger(BasketEvents.ERROR, fm('Important: Your basket has been updated.'));
                console.error('OUT_OF_STOCK_ERROR', response.data.data);

                await dispatch({
                    type: 'GET_PAYMENT_METHOD_WARNING',
                    payload: objectKeysToCamelCase(response.data.data),
                });

                // And around we go again...
                if (response.data.data.basket?.items?.length) {
                    dispatch(getBasketPaymentMethod(data));
                }
            } else if (response.data.status < 400) {
                dispatch({
                    type: 'GET_PAYMENT_METHOD_SUCCESS',
                    payload: objectKeysToCamelCase(response.data.data),
                });
            } else {
                // @todo: Implement sentry (?)
                // Sentry.captureEvent({
                //     message: 'GET_PAYMENT_METHOD_ERROR',
                //     contexts: {
                //         basket: {
                //             id: basketId,
                //         },
                //     },
                //     extra: {
                //         response: { ...response },
                //         arguments: {
                //             basketId,
                //             paymentMethodId,
                //             successUrl,
                //             errorUrl,
                //             language,
                //             country,
                //             state,
                //             address,
                //         },
                //         application: applicationState,
                //         basket: basketState,
                //     },
                // });

                Events.trigger(
                    BasketEvents.ERROR,
                    `${fm('Something went wrong with your payment.')} ${fm(
                        'Please check your payment details and try again.'
                    )}`
                );
                dispatch({ type: 'GET_PAYMENT_METHOD_ERROR', payload: { basket: getState().basket } });
            }

            mutexLock();

            return response;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong with your payment.')} ${fm(
                    'Please check your payment details and try again.'
                )}`
            );
            dispatch({ type: 'GET_PAYMENT_METHOD_ERROR', payload: { basket: getState().basket } });
            mutexLock();
            throw e;
        }
    };

export const selectPaymentMethod =
    (paymentMethodId: string): StoreAction =>
    async (dispatch, getState) => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.selectPaymentMethod');

        dispatch({ type: 'SELECT_PAYMENT_METHOD' });
        try {
            const response = await refreshCheckout(SelectPaymentMethod(basketId, paymentMethodId));
            dispatch({
                type: 'SELECT_PAYMENT_METHOD_SUCCESS',
                payload: objectKeysToCamelCase(response.data.data),
            });

            Events.trigger(CheckoutEvents.OPTION, {
                step: CheckoutTracking.PAYMENT_STEP,
                option: paymentMethodId,
                currencyCode: getState().basket?.currency,
            });

            mutexLock();

            return response;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to update the payment method.')}`
            );
            dispatch({ type: 'SELECT_PAYMENT_METHOD_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const selectShippingMethod =
    (shippingMethodId: string): StoreAction =>
    async dispatch => {
        const basketId: string = await dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.selectShippingMethod');

        dispatch({ type: 'SELECT_SHIPPING_METHOD' });
        try {
            const response = await refreshCheckout(SelectShippingMethod(basketId, shippingMethodId));
            dispatch({
                type: 'SELECT_SHIPPING_METHOD_SUCCESS',
                payload: objectKeysToCamelCase(response.data.data),
            });

            mutexLock();

            return response;
        } catch (e) {
            Events.trigger(
                BasketEvents.ERROR,
                `${fm('Something went wrong...')} ${fm('We were not able to select that shipping method.')}`
            );
            dispatch({ type: 'SELECT_SHIPPING_METHOD_ERROR' });
            mutexLock();
            throw e;
        }
    };

export const resetBasket = (): StoreAction => async dispatch => {
    const mutexLock = await acquireMutex('basket.resetBasket');

    dispatch({ type: 'RESET_BASKET' });
    try {
        clearBasketIdCookie();
        dispatch({ type: 'RESET_BASKET_SUCCESS' });
        mutexLock();
    } catch (e) {
        Events.trigger(
            BasketEvents.ERROR,
            `${fm('Something went wrong...')} ${fm('We were not able to reset your basket.')}`
        );
        dispatch({ type: 'RESET_BASKET_ERROR' });
        mutexLock();
        throw e;
    }
};

export const clearPaymentCallback = (): StoreAction => async dispatch => {
    const mutexLock = await acquireMutex('basket.clearPaymentCallback');

    dispatch({ type: 'CLEAR_PAYMENT_CALLBACK' });
    mutexLock();
};

export const setBasketMarket =
    (basketId: string, marketId: string): StoreAction =>
    dispatch => {
        dispatch({ type: 'SET_BASKET_MARKET' });
        try {
            SetMarket(basketId, marketId);

            dispatch({
                type: 'SET_BASKET_MARKET_SUCCESS',
            });
        } catch (e) {
            dispatch({ type: 'SET_BASKET_MARKET_ERROR' });
            console.error(e);
            throw e;
        }
    };
