import Gtm from '@grebban/gtm';

export default ({ currencyCode, step, option }) => {
    Gtm.addDataLayerEvent('checkoutOption', {
        ecommerce: {
            currencyCode,
            checkout_option: {
                actionField: { step, option },
            },
        },
    });
};
