import { ApplicationActionTypes, ApplicationState } from '@activebrands/core-web/state/application/types';

const initialState: ApplicationState = {
    aliasId: 0,
    isInitialized: false,
    locale: '',
    path: '',
    sites: [],
    shopConfig: {
        checkoutPage: '',
        checkoutResponsePage: '',
        countries: undefined,
        currency: '',
        currencyDecimals: 0,
        locked: false,
        marketId: '',
        pricelistId: '',
        userCountry: '',
    },
};

export default (state = initialState, action: ApplicationActionTypes) => {
    switch (action.type) {
        case 'SET_APPLICATION':
            return {
                ...state,
                aliasId: action.payload.aliasId,
                isInitialized: true,
                locale: action.payload.locale,
                path: action.payload.path,
                sites: action.payload.sites,
                shopConfig: action.payload.shopConfig,
            };
        case 'SET_APPLICATION_MARKET':
            return {
                ...state,
                shopConfig: {
                    ...state.shopConfig,
                    marketId: action.payload.marketId || state.shopConfig.marketId,
                },
            };
        default:
            return state;
    }
};
