interface IEDocument extends Document {
    documentMode: any;
}

export const inServer = typeof window === 'undefined';

export const inBrowser = typeof window !== 'undefined';

export const isMobile = inBrowser && window.orientation > -1;

// @todo: Check that !!(document as IEDocument).documentMode) succsesfully finds IE browsers
export const isIE = /*@cc_on!@*/ false || (!inServer && !!(document as IEDocument).documentMode);

export const isProduction = process.env.NODE_ENV === 'production';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const isIOS = inBrowser && window.CSS && CSS.supports('-webkit-overflow-scrolling: touch');

export const isSupportingLazy = typeof HTMLImageElement !== 'undefined' && 'loading' in HTMLImageElement.prototype;

export const isStoryblokPreview = typeof window !== 'undefined' && window.location.pathname.includes('/preview/');

export const ecommerceEnabled = process.env.REACT_APP_DISABLE_ECOMMERCE !== 'true';
