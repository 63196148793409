import ColorsCssVariables from '@activebrands/core-web/interfaces/colors';
import { CssVariables, HEX, RGB, RGBA } from '@activebrands/core-web/types/css-types';

export interface ColorCssVariables extends CssVariables<RGBA | RGB | HEX> {
    '--color-base-brand-beige': RGBA | RGB | HEX;
    '--color-base-brand-black': RGBA | RGB | HEX;
    '--color-base-brand-white': RGBA | RGB | HEX;
    '--color-base-error': RGBA | RGB | HEX;
    '--color-base-success': RGBA | RGB | HEX;
    '--color-palette-black': RGBA | RGB | HEX;
    '--color-palette-brand-brown-25': RGBA | RGB | HEX;
    '--color-palette-brand-white': RGBA | RGB | HEX;
    '--color-palette-brown': RGBA | RGB | HEX;
    '--color-palette-default-grey': RGBA | RGB | HEX;
    '--color-palette-grey': RGBA | RGB | HEX;
    '--color-palette-light-grey': RGBA | RGB | HEX;
    '--color-palette-neutral-200': RGBA | RGB | HEX;
    '--color-palette-neutral-300': RGBA | RGB | HEX;
    '--color-palette-neutral-400': RGBA | RGB | HEX;
    '--color-palette-neutral-500': RGBA | RGB | HEX;
    '--color-palette-neutral-600': RGBA | RGB | HEX;
    '--color-palette-neutral-700': RGBA | RGB | HEX;
    '--color-palette-off-black': RGBA | RGB | HEX;
    '--color-palette-off-white': RGBA | RGB | HEX;
    '--color-palette-white': RGBA | RGB | HEX;
}

export const colorsCssVariables: ColorsCssVariables = {
    // Default colors
    '--color-palette-black': '#000000',
    '--color-palette-brown': '#5B544D',
    '--color-palette-white': '#FFFFFF',
    '--color-palette-light-grey': '#E5E5E5',

    // Brand colors
    '--color-base-brand-beige': '#F6F5F2',
    '--color-base-brand-black': '#111111',
    '--color-base-brand-white': '#FFFFFF',
    '--color-palette-off-black': '#111111',
    '--color-palette-off-white': '#F6F5F2',

    // Act Rewards
    '--color-palette-grey': '#F6F5F2',
    '--color-palette-default-grey': '#595959',

    //Transparancy colors
    '--color-palette-brand-brown-25': 'rgba(40, 27, 18, 0.25);',
    '--color-palette-brand-white': 'rgba(246, 245, 242, 0);',

    // Neutrals
    '--color-palette-neutral-200': '#EEEAE5',
    '--color-palette-neutral-300': '#E5E0D9',
    '--color-palette-neutral-400': '#DDD6CC',
    '--color-palette-neutral-500': '#D2C8BE',
    '--color-palette-neutral-600': '#B5A597',
    '--color-palette-neutral-700': '#F6F5F280',

    // Informational
    '--color-base-error': '#CC0000',
    '--color-base-success': '#2CBF4C',
};
