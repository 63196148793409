import { Popup, RawPopup } from '@activebrands/core-web/libs/storyblok/storyblok';

export const transformPopup = ({
    _uid,
    closeButtonColor,
    content,
    cookieLifespan,
    timeOut,
    visibilitySettings,
}: RawPopup): Popup => ({
    closeButtonColor,
    content,
    cookieLifespan: cookieLifespan ? Number(cookieLifespan) : undefined,
    id: _uid,
    show: false,
    timeOut: timeOut ? Number(timeOut) : undefined,
    visibilitySettings,
});

export const transformPopups = (popups: Array<RawPopup>): Array<Popup> => popups?.map(popup => transformPopup(popup));
