import PropTypes from 'prop-types';
import Backdrop from '@activebrands/core-web/components/Backdrop';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';

const BackdropOverlay = ({ $style, handleOnClick, isVisible, zIndex, children }) => {
    const overlays = useOverlaySubscriber();
    const lastOpened = overlays[overlays.length - 1];

    return (
        <Backdrop
            $style={{ top: 0, ...$style }}
            isVisible={isVisible || overlays.filter(overlay => overlay.indexOf('notification-') === -1).length > 0}
            zIndex={zIndex || 'calc(var(--zindex-content-overlay-backdrop) - 1)'}
            onClick={handleOnClick ? () => handleOnClick() : lastOpened ? () => overlay.close(lastOpened) : undefined}
        >
            {children}
        </Backdrop>
    );
};

BackdropOverlay.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.node,
    handleOnClick: PropTypes.func,
    isVisible: PropTypes.bool,
    zIndex: PropTypes.string,
};

export default BackdropOverlay;
