import { useEffect, useState } from 'react';
import { styled } from '@activebrands/core-web/libs/styletron';

const GridCheckWrapper = styled('div', ({ columnGutter }) => ({
    inset: '0px',
    height: '100%',
    width: 'calc(100% - 2 * var(--margin-content))',
    display: 'flex',
    position: 'fixed',
    borderLeft: '1px solid rgba(74, 222, 128, 0.8)',
    borderRight: '1px solid rgba(74, 222, 128, 0.8)',
    zIndex: 999,
    marginLeft: 'auto',
    marginRight: 'auto',
    pointerEvents: 'none',
    gap: columnGutter,
}));

const Column = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(220, 38, 38, 0.8)',
    height: '100%',
    width: '100%',
    borderColor: 'rgba(220, 38, 38, 0.8)',
    borderLeft: '1px solid rgba(220, 38, 38, 0.8)',
    borderRight: '1px solid rgba(220, 38, 38, 0.8)',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
});

const ColumnNumber = styled('span', {
    position: 'absolute',
    placeContent: 'center',
});

const generateColumnsArray = (number: number) => {
    const columnsArray: { number: number }[] = [];

    for (let i = 1; i <= number; i++) {
        columnsArray.push({ number: i });
    }

    return columnsArray;
};

interface GridCheckProps {
    $style?: any; // @todo: Add correct type
    $columnStyle?: any; // @todo: Add correct type
    $columnNumberStyle?: any; // @todo: Add correct type
    columns?: number;
    columnGutter?: string | string[];
}

/**
 * @todo: Make this into a Grebban package so it can be used across all projects
 * @todo: Introduce a CSS variable for each project to handle columnGutter
 */
export const GridCheck = ({
    $style = {},
    $columnStyle = {},
    $columnNumberStyle = {},
    columns = 12,
    columnGutter,
}: GridCheckProps) => {
    const gutter = columnGutter ?? ['12px', null, null, '16px', null, '24px'];

    const [columnsCount, setColumnsCount] = useState(columns);

    const columnsArray = generateColumnsArray(columnsCount);

    const [isShiftGPressed, setIsShiftGPressed] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.shiftKey && event.key === 'G') {
                setIsShiftGPressed(!isShiftGPressed);
            } else if (isShiftGPressed) {
                if (event.key === '+') {
                    setColumnsCount(prev => Math.min(18, prev + 1));
                } else if (event.key === '-') {
                    setColumnsCount(prev => Math.max(1, prev - 1));
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isShiftGPressed]);

    return (
        isShiftGPressed && (
            <GridCheckWrapper $style={$style} columnGutter={gutter}>
                {columnsArray.map(col => {
                    return (
                        <Column key={col.number} $style={$columnStyle}>
                            <ColumnNumber $style={$columnNumberStyle}>{col.number}</ColumnNumber>
                        </Column>
                    );
                })}
            </GridCheckWrapper>
        )
    );
};
