import { acquireMutex } from '@activebrands/core-web/state/utils';
import { BasketItemModel } from '@activebrands/core-web/types/basket';
import getCookiePath from '@activebrands/core-web/utils/get-cookie-path';
import Cookies from 'js-cookie';

const BASKET_ID_COOKIE = process.env.REACT_APP_BASKET_ID_COOKIE as string;

export const clearBasketIdCookie = () => {
    const path = getCookiePath();
    Cookies.remove(BASKET_ID_COOKIE, { path });
};

export const setBasketIdCookie = (id: string, expires = 28) => {
    const path = getCookiePath();
    Cookies.set(BASKET_ID_COOKIE, id, { expires, path });
};

export const getBasketIdCookie = () => {
    const value = Cookies.get(BASKET_ID_COOKIE);

    return !value || value === 'undefined' ? undefined : value;
};

export const refreshCheckout = async (promise: Promise<any>) => {
    const mutexLock = await acquireMutex('basket');
    const centraCheckout = (window as any).CentraCheckout;

    if (centraCheckout !== undefined) {
        try {
            centraCheckout.suspend();

            await promise;

            centraCheckout.resume();
            mutexLock();
        } catch (error) {
            centraCheckout.resume();
            mutexLock();

            throw error;
        }
    } else {
        mutexLock();
    }

    return promise;
};

export const getBasketItemByLine = (items: BasketItemModel[], line: string): BasketItemModel => {
    let item = { quantity: 0 };

    for (const i in items) {
        if (items[i].line === line) {
            item = items[i];
            break;
        }
    }

    return { ...item } as BasketItemModel;
};

export const getBasketItemById = (items: BasketItemModel[], id: string): BasketItemModel => {
    let item = { quantity: 0 };
    for (const i in items) {
        if (items[i].id === id) {
            item = items[i];
            break;
        }
    }
    return { ...item } as BasketItemModel;
};
