import Gtm from '@grebban/gtm';

export default ({ shippingInfo, items }) => {
    const valueWithoutTax = items.reduce((prev, item) => {
        return prev + (item.priceEachWithoutTaxAsNumber * item.quantity);
    }, 0);

    Gtm.addDataLayerEvent('add_shipping_info', {
        currency: shippingInfo?.currency,
        shipping_tier: shippingInfo?.shipping_tier,
        ecommerce: {
            value: valueWithoutTax,
            items: items
        }
    });
}