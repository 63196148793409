import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Popups from '@activebrands/core-web/components/Popups';
import Seo from '@activebrands/core-web/components/seo-meta/structured-data/Seo';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';
import useScrollPosition from '@activebrands/core-web/hooks/useScrollPosition';
import Events from '@activebrands/core-web/libs/Events';
import debounce from '@grebban/utils/function/debounce';
import loadable from '@loadable/component';

const availableLayouts = {
    default: loadable(() => import('./DefaultLayout')),
    checkout: loadable(() => import('./CheckoutLayout')),
    account: loadable(() => import('./AccountLayout')),
    preview: loadable(() => import('./PreviewLayout')),
    siteselector: loadable(() => import('./SiteSelectorLayout')),
};

const PageLayout = ({ children, layout, location, pageContext }) => {
    const { page } = pageContext;

    const Component = availableLayouts[layout || page?.layout] || availableLayouts.default;

    const listener = useEventListener('window');

    // Tracks viewport height. Useful for iOS where vh changes when bottom bar is visible but CSS vh value doesn't change.
    useEffect(() => {
        let lastHeight = `${window.innerHeight}px`;
        document.body.style.setProperty('--vh', lastHeight);

        const handler = listener.subscribe(
            'resize',
            debounce(() => {
                const newHeight = `${window.innerHeight}px`;

                if (lastHeight !== newHeight) {
                    lastHeight = newHeight;
                    document.body.style.setProperty('--vh', newHeight);
                }
            }, 250)
        );

        return () => handler && listener.unsubscribe('resize', handler);
    }, []);

    // Global scroll up / down event listener.
    // Retriggers between 0-200 to make sure transparent/white animation works on header and every 15th for performance.
    useScrollPosition(
        ({ direction, position }) => {
            if (direction.y) {
                Events.trigger(`BODY.SCROLL_${direction.y}`, position);
            }
        },
        ({ position }) => position.y < 200 || position.y % 10 === 0
    );

    return (
        <>
            <Seo data={page?.data || {}} location={location} />
            <Popups />
            <Component location={location} page={page || {}}>
                {children}
            </Component>
        </>
    );
};

PageLayout.propTypes = {
    children: PropTypes.node,
    layout: PropTypes.string,
    location: PropTypes.object,
    pageContext: PropTypes.shape({
        page: PropTypes.shape({
            layout: PropTypes.oneOf(['default', 'checkout', 'preview']),
            data: PropTypes.object,
            slug: PropTypes.string,
        }),
    }).isRequired,
};

export default PageLayout;
