import { StoreAction } from '@activebrands/core-web/state/store';
import { Site } from '@activebrands/core-web/types/application';
import { getUser, getUserCountryCode } from '@activebrands/core-web/utils/grebcommerce';
import { getSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';

export const resolveUser =
    (userCampaignCode: string): StoreAction =>
    async (dispatch, getState) => {
        const sites = getState().application.sites;
        const userCookieSelectedSitePath = getSiteSelectorCookie();
        const userCountryCode = await getUserCountryCode();
        const userSelectedCountry = sites?.find((site: Site) => site.path === userCookieSelectedSitePath);
        let userSelectedCountryCode: string;

        const parameters = {
            user_country_code: userCountryCode,
        };

        if (userSelectedCountry?.countryCode) {
            // Temporary fix country code for EU
            // EU is not a valid country code so we need to use AT instead to get back correct selectedSite from the API.
            userSelectedCountryCode = userSelectedCountry.countryCode == 'EU' ? 'AT' : userSelectedCountry.countryCode;

            parameters['user_selected_country_code'] = userSelectedCountryCode;
        }

        if (userCampaignCode) {
            parameters['user_campaign_code'] = userCampaignCode;
        }

        const response = await getUser(parameters);

        try {
            if (response.status === 200) {
                // Set User Redux
                dispatch({
                    type: 'RESOLVE_USER_SUCCESS',
                    payload: response.data.user,
                });

                // Set Ecommerce Redux
                dispatch({
                    type: 'RESOLVE_ECOMMERCE_SUCCESS',
                    payload: response.data.ecommerce,
                });
            } else if (!response.data) {
                dispatch({ type: 'RESOLVE_USER_ERROR', error: `No response, response: ${response}` });
                dispatch({ type: 'RESOLVE_ECOMMERCE_ERROR', error: `No response, response: ${response}` });
            } else {
                dispatch({ type: 'RESOLVE_USER_ERROR', error: `Something went wrong, response: ${response}` });
                dispatch({ type: 'RESOLVE_ECOMMERCE_ERROR', error: `Something went wrong, response: ${response}` });
            }
        } catch (e) {
            dispatch({ type: 'RESOLVE_USER_ERROR', error: e });
            throw e;
        }
    };
