import { Helmet } from 'react-helmet';
import useSiteMetaQuery from 'queries/useSiteMetaQuery';
import { getLocalePrefix } from '@activebrands/core-web/utils/add-locale-prefix';
import removeObjectPropertiesMatchingValue from '@grebban/utils/object/removePropertiesMatchingValue';

const OrganizationStructuredData = () => {
    const {
        companyAddressCountry,
        companyAddressLocality,
        companyEmail,
        companyLogo,
        companyPostalCode,
        companySocialMedia,
        companyStreetAddress,
        legalName,
        siteURL,
        title,
    } = useSiteMetaQuery();

    // Organization
    const socialMediaLinks = companySocialMedia?.length > 0 ? companySocialMedia.map(v => v.link?.url || null) : null;

    const organization = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        'email': companyEmail || null,
        'legalName': legalName || null,
        'logo': companyLogo?.filename || null,
        'name': title || null,
        'sameAs': socialMediaLinks,
        'url': `${siteURL}${getLocalePrefix()}/`,
        'address':
            companyStreetAddress || companyAddressLocality || companyPostalCode || companyAddressCountry
                ? {
                      'streetAddress': companyStreetAddress || null,
                      'addressLocality': companyAddressLocality || null,
                      'postalCode': companyPostalCode || null,
                      'addressCountry': companyAddressCountry || null,
                  }
                : null,
    };

    // Remove null values from organization and breadcrumbList
    removeObjectPropertiesMatchingValue(organization, null);

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(organization, null, 0)}</script>
        </Helmet>
    );
};

export default OrganizationStructuredData;
