import Cookies from 'js-cookie';

const SELECTED_SITE_COOKIE = process.env.REACT_APP_SELECTED_SITE_COOKIE as string;

// need to always put this in the root due to cookies being set in .com site selector
const selectedCookiePath = '/';

export const setSiteSelectorCookie = (selectedSitePath: string) => {
    Cookies.set(SELECTED_SITE_COOKIE, `${selectedSitePath}`, {
        expires: 30,
        path: selectedCookiePath,
    });
};

export const getSiteSelectorCookie = () => Cookies.get(SELECTED_SITE_COOKIE);

export const clearSelectedSiteCookie = () => {
    Cookies.remove(SELECTED_SITE_COOKIE, { path: selectedCookiePath });
};
