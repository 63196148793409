import { minBreakpoints as breakpoints } from '@activebrands/core-web/config/breakpoints';
import { inServer } from '@activebrands/core-web/utils/constants';

export const matchesMedia = (media: string) =>
    !inServer && window.matchMedia(media.replace('@media screen and ', '')).matches;

export const getBreakpointIndex = () => {
    if (inServer) {
        return -1;
    }

    const width = window.innerWidth;

    if (width <= breakpoints[0]) {
        return 0;
    }

    return breakpoints.findIndex((b, i) => {
        const next = breakpoints[i + 1];
        return width >= b && (next ? width < breakpoints[i + 1] : true);
    });
};
